import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useFormik } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { UserProfile } from '../../../store/Accounts/profile';
import { UserUpdateProfile } from '../../../store/Accounts/editProfile';

function ChangeEmail() {
    const dispatch = useDispatch();
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const { profile } = useSelector(state => state);
    const { editProfile } = useSelector(state => state);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [checked, setChecked] = React.useState([0, 1,]);

    useEffect(() => {
        getProfile();
    }, []);


    const getProfile = () => {
        let body = {
            id: currentUser.id
        }
        dispatch(UserProfile(body))
    }

    const formik = useFormik({
        initialValues: {
            email: profile.userProfile?.email
        },
        onSubmit: values => {
            console.log(values)
            setLoading(true)
        }
    });
    return (
        <>
            <div className='padd'>
                <Box sx={{ minWidth: 275 }} className='mt-2 '>
                    <Card variant="outlined" className="padds">
                        <div>
                            <h2>CHANGE EMAIL</h2>
                        </div><hr />
                        <div>
                            <form className="theme-form">

                                <div className="form-group">
                                    <label >Email Address</label>
                                    <input readOnly type="email" name="email" className="form-control" placeholder="Email Address"
                                        value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    <div className='card-titles'>
                                        <p>E-mail {profile.userProfile?.email} is verified</p>
                                    </div>
                                    {formik.touched.email && formik.errors.email ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.email}</span> : null}
                                </div>
                                <div className="row pt-2 pb-3">
                                    <div className="col-md-3" />
                                    <div className="col-md-6">

                                    </div>
                                    <div className="col-md-3" >
                                        <div style={{ textAlign: 'center' }}>
                                            <Stack >
                                                <Button disabled variant="contained" className='br' onClick={formik.handleSubmit}>
                                                    {loading ? <span><span className="spinner-border spinner-border-sm"></span> Please wait...</span> : 'Update Email'}</Button>

                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Card>
                </Box>
            </div>
        </>
    );
}

export default ChangeEmail;