import { createSlice } from '@reduxjs/toolkit'
import api from "../../Api";
import Swal from "sweetalert2";

// Slice
const slice = createSlice({
    name: 'seen',
    initialState: {
        seen: [],
    },
    reducers: {
        SeenSuccess:(res, action) => {
            res.seen = action.payload;
      },
    },
  });
  
  export default slice.reducer
  // Actions
  const { SeenSuccess } = slice.actions;
  
  export const SeenMsg = (id) => async dispatch => {
    try {
      const res = await api.get(`user/msg/seen/Status/${id}`);
      if(res.data.Status == false){
        Swal.fire({  
          title: 'Warning',  
          text: res.data.Message,  
          icon: 'warning',   
          confirmButtonColor: '#3085d6',  
          confirmButtonText: 'Ok',
          timer: 1500  
        });
      }else{
        // Swal.fire({  
        //     title: 'Success',  
        //     text: res.data.Message,  
        //     icon: 'success',   
        //     confirmButtonColor: '#3085d6',  
        //     confirmButtonText: 'Ok',
        //     timer: 1500  
        //   });
        dispatch(SeenSuccess(res.data));
      }
  
    } catch (e) {
      return console.error(e.message);
    }
  }