import * as React from 'react';
import { useEffect, useRef, useState } from "react";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Dropdown from 'react-bootstrap/Dropdown';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getInbox, getMSGcOUNT } from '../store/message/inbox';
import { SeenMsg } from '../store/message/magSeen';
import socket from "../utils/socketNotification";

function HeaderMessages() {
    const componentMounted = useRef(true);
    const dispatch = useDispatch();
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const { inbox } = useSelector(data => data)
    const history = useHistory();

    useEffect(() => {
        if (componentMounted.current) {
            socket.on('getMessage', (res) => {
                setTimeout(() => {
                    dispatch(getInbox(currentUser.id));
                    dispatch(getMSGcOUNT(currentUser.id))
                }, 500);
            })
            dispatch(getInbox(currentUser.id));
            dispatch(getMSGcOUNT(currentUser.id))
        }
        return () => {
            componentMounted.current = false;
        }
    }, []);

    const gotoinbox = () => {
        history.push(`/inbox/${currentUser.id}`)
    }

    const gotomessage = (id, to_user_id) => {
        history.push(`/inbox/${id}`);
        dispatch(SeenMsg(to_user_id));
        dispatch(getMSGcOUNT(currentUser.id));
    }

    return (
        <div>
            <Dropdown style={{ width: '100%', minWidth: '100%' }}>
                <Dropdown.Toggle style={{ outline: 'none', background: 'none', border: 'none', padding: '0px' }}>
                    <Stack spacing={1} direction="row" sx={{ color: 'action.active' }}>
                        {inbox.unread.map((data, i) => <Badge key={i} color="primary" badgeContent={data.count}>
                            <MailOutlineIcon style={{ color: 'white' }} />
                        </Badge>)}
                    </Stack>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <div className="dropdown-header">
                        <span>messages</span>
                    </div>
                    {inbox.inbox.slice(0, 5).map((data, i) => <div  key={i}>
                        <Dropdown.Item>
                            <a onClick={() => gotomessage(data.commenID, data.to_user_id)}>
                                <div className="media">
                                    <Stack direction="row" spacing={2}>
                                        <Avatar alt={data?.firstname} src={data?.profile_image} />
                                    </Stack>
                                    <div className="media-body" style={{ paddingLeft: '4px' }}>
                                        <div>
                                            <h5 className="mt-1">{data?.firstname} {data?.lastname} <span className="inbox available-stats online " style={{ marginLeft: '5px' }}></span></h5>
                                            <h6>{data?.message}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="active-status">
                                    <span className="active"></span>
                                </div>
                            </a>
                        </Dropdown.Item>
                        <Dropdown.Divider style={{ color: '#f5eaea' }} />
                    </div>)}
                    <Dropdown.Item >
                        <p style={{ textAlign: 'right', paddingRight: '5px', cursor: "grab" }} className="pb-1" onClick={gotoinbox}>See All</p>
                    </Dropdown.Item>

                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export default HeaderMessages;