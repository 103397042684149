import * as React from 'react';
import { useState, useEffect, useRef } from "react";
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import { Chip } from '@mui/material';
import { useHistory } from 'react-router';
import api from "../../Api";
import { Link} from 'react-router-dom';

const MySubscriptions = () => {
    const componentMounted = useRef(true);
    const history = useHistory();
    const handleClick = () => {};
    const [userSeggession, setUserSeggession] = useState([])
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));



    useEffect(() => {
        if (componentMounted.current) {
            getMySubscriptions(currentUser.id);
        }
        return () => {
            componentMounted.current = false;
        }
       
    }, []);

    const getMySubscriptions = (id) => {
        api.get(`/user/subscription/fetch/${id}`).then(data => {
            if(data.data){
            setUserSeggession(data.data[0]);
            }
        })
    }
    const gotoDataUser = (ID) => {
        history.push(`/subscriptions/${ID}`);
    }
    const gotoBack = () =>{
        window.history.go(-1)
    }
    return (
        <>


            <Box sx={{ minWidth: '100%' }} >
                <Card variant="outlined" className="padds">
                    <h2>
                    <svg  onClick={()=>gotoBack()} width={25} height={10} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-left fa-w-14 fa-2x"><path fill="currentColor" d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" class=""></path>
                    </svg>My Subscriptions</h2>
                    <hr />
                    <div>
                        <Stack direction="row" spacing={1}>
                            <Link to='/Mysubscriptions'>
                            <Chip label="All" onClick={handleClick} style={{ background: 'blue', color: 'white' }} /> </Link>
                            <Link to='/following'>
                            <Chip label="Following" onClick={handleClick} /> </Link>
                            <Link to='/follower'>
                            <Chip label="Followers" onClick={handleClick} /></Link>

                        </Stack>
                    </div>
                        <div className='row MySubscriptions'>
                            {userSeggession.map(data =>
                                <div className='col-md-6 col-sm-12 my-4'>
                                    <div className="story-box">
                                        <div className="story-bg" style={{ maxHeight: '130px' }}>
                                            <img style={{ borderRadius: '10px', width: '100%',maxHeight: '130px', height: '100%', objectFit:'cover' }} src={data?.cover_image}
                                                onError={(event) => event.target.removeAttribute('src')}
                                                onError={(event) => event.target.src = '../assets/images/avtar-image.jpg'}
                                                className="img-fluid bg-img" data-adaptive-background={1} alt='true' />
                                        </div>
                                        <div className="profile-bg" style={{left: "12px",right: "12px"}}>
                                        <Stack direction="row" spacing={2} style={{ position: 'absolute', bottom: '4px', left: '14px', border: 'solid white 2px', borderRadius: '50px' }}>
                                            <Avatar className="img-fluid-post blur-up lazyload bg-img imgrad" alt={data?.firstname} src={data?.profile_image}  style={{ width:'50px', height:'50px'}}/>
                                        </Stack>
                                        <div className="story-content" onClick={() => gotoDataUser(data.to_user_id)} 
                                           style={{position:'absolute', bottom:'12px', left:'26px',cursor:'grab', color:"white"}}>
                                            <h6 style={{ fontSize: "16px", cursor:'grab' }}>{data?.firstname} {data?.lastname}</h6>
                                            <span>@{data?.user_name}</span>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                            )}
                        </div>
                </Card>
            </Box>
        </>
    )
}

export default MySubscriptions
