import { createSlice } from '@reduxjs/toolkit'
import api from "../Api";
import Swal from "sweetalert2";

// Slice
const slice = createSlice({
  name: 'UserEmailVerify',
  initialState: {
    UserEmailVerify: null,
  },
  reducers: {
    EmailVerfySuccess:(state, action) => {
        state.UserEmailVerify = action.payload;
        console.log(state.UserEmailVerify)
    },
  },
});

export default slice.reducer
// Actions
const { EmailVerfySuccess } = slice.actions;

export const EmailVerfy = (body) => async dispatch => {
  try {
    const res = await api.post('user/verifyemail', body);
    if(res.data.Status == false){
      Swal.fire({  
        title: 'Warning',  
        text: res.data.Message,  
        icon: 'warning',   
        confirmButtonColor: '#3085d6',  
        confirmButtonText: 'Ok',
        timer: 1500  
      });
    }else{
      Swal.fire({  
        title: 'Successs',  
        text: res.data.Message,  
        icon: 'success',   
        confirmButtonColor: '#3085d6',  
        confirmButtonText: 'Yes!',
        timer: 1500  
      });
      dispatch(EmailVerfySuccess(res.data));
    }

  } catch (e) {
    return console.error(e.message);
  }
}
