import { createSlice } from '@reduxjs/toolkit'
import api from "../../Api";
import Swal from "sweetalert2";

// Slice
const slice = createSlice({
    name: 'postDetails',
    initialState: {
        postDetails: [],
    },
    reducers: {
        UserPostSuccess:(state, action) => {
          state.postDetails = action.payload;
      },
    },
  });
  
  export default slice.reducer
  // Actions
  const { UserPostSuccess } = slice.actions;
  
  export const UserPost = (id,uid) => async dispatch => {
    try {
      const res = await api.get(`/get-user-subscribar-post/${id}/${uid}`);
      if(res.data.Status == false){
        // Swal.fire({  
        //   title: 'Warning',  
        //   text: res.data.Message,  
        //   icon: 'warning',   
        //   confirmButtonColor: '#3085d6',  
        //   confirmButtonText: 'Ok',
        //   timer: 1500  
        // });
      }else{
        dispatch(UserPostSuccess(res.data));
      }
  
    } catch (e) {
      return console.error(e.message);
    }
  }