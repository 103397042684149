import React, { useState, useEffect, useRef } from "react";
import PostsCreate from './post/createpost'
import PostLikes from "./post/likes/likes";
import PostComment from "./post/likes/comments";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import PaidPost from "../../lightbox/postPaid";
import { ReactVideo } from "reactjs-media";
import api from "../../Api";
import FriendSuggestion from "../right/components/FriendSuggestion";
import socket from "../../utils/socketNotification";
import SendDialog from "../../Dialog/sendTips";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Tooltip from '@material-ui/core/Tooltip';
import Gallery from "react-photo-gallery";

const Center = () => {
    const componentMounted = useRef(true);
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const [animation, setAnimation] = useState(true);
    const [postData, setPostData] = useState([]);
    const [commentPanel, setCommentPanel] = useState([false]);
    const [clas, setClas] = React.useState([false]);
    const history = useHistory();
    const [tips, setTips] = useState([false]);

    useEffect(() => {
        if (componentMounted.current) {
            getPost(currentUser.id);
            let Typingbody = {
                id: currentUser.id,
                last_seen: 'online'
            }
            socket.emit('realTimeTyping', Typingbody)
        }
        return () => {
            componentMounted.current = false;
        }
    }, []);



    const getPost = (id) => {
        // setAnimation(true);
        api.get(`/get-user-subscribar-post/${id}/${currentUser.id}`).then(res => {
            if (res.data.Status != false) {
                res.data.forEach(element => {
                    element.to_user_id = element.user_id;
                    element.pay_on_type = 'post'
                });
                setPostData(res.data);
                // setAnimation(false);
            }else{
                // setAnimation(false);
                return;  
            }

        }).catch(err=>{});
        socket.on('getNotification', (res) => {
            api.get(`/get-user-subscribar-post/${id}/${currentUser.id}`).then(res => {
                if (res.data.Status != false) {
                    res.data.forEach(element => {
                        element.to_user_id = element.user_id;
                        element.pay_on_type = 'post'
                    });
                    setPostData(res.data);
                   
                }else{
                    // setAnimation(false);
                    return;
                }
            }).catch(err=>{ });
        });
    }

    const commentsPost = (id) => {
        let dataToggle = {}
        if (!commentPanel[id]) {
            dataToggle[id] = true
            setCommentPanel(dataToggle);
        } else {
            dataToggle[id] = false
            setCommentPanel(dataToggle);
        }
    }

    const contantToggle = (id) => {
        let ClassToggle = {}
        if (!clas[id]) {
            ClassToggle[id] = true
            setClas(ClassToggle);
        } else {
            ClassToggle[id] = false
            setClas(ClassToggle);
        }
    }

    const gotoUserProfile = (ID) => {
        if (ID != currentUser.id) {
            history.push(`/subscriptions/${ID}`);
        } else {
            history.push('/User/profile');
        }
    }

    const lightBoxHandlerBar = (data) => {
        history.push(`/media/${data}`);
    }

    const handleCallback = (childData) => {
        getPost(currentUser.id);
        setTips([false])
    }
    const handleCallbacks = (childData) => {
        getPost(currentUser.id);
        
    }

    let j = 1;

    const sentTpsData = (id) =>{
        let dataToggle = {}
        if (!tips[id]) {
            dataToggle[id] = true;
            setTips(dataToggle);
          
        } else {
            dataToggle[id] = false;
            setTips(dataToggle);
        }
    } 

    return (
        <>

            <div className="content-center">
                <PostsCreate prop={'s'} parentCallback={handleCallback} />
                {postData.length === 0 ? <div className="pre-loader">
                    <div className="content-left">
                        <div className="suggestion-box section-t-space">
                            <div className="suggestion-content ratio_115">
                                <div>
                                    <div className="create-post">
                                        <div className="static-section">
                                            <div className="card-title">
                                                <h3 />
                                            </div>
                                            <div className="search-input input-style icon-right">
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="create-bg">
                                            <div className="bg-post">
                                                <div className="input-sec">
                                                    <input type="text" className="form-control enable" placeholder="write something here.." />
                                                    <div className="close-icon">
                                                        <a href="javascript:void(0)">
                                                            <i className="iw-20 ih-20" data-feather="x" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="gradient-bg">
                                            </div>
                                        </div>
                                        <ul className="create-btm-option">
                                            <li />
                                            <li />
                                            <li />
                                            <li />
                                        </ul>
                                        <div className="post-btn">
                                            <button disabled="disabled" className="Disable">post</button>
                                        </div>
                                    </div>

                                    <div className="overlay-bg" />
                                    <div className="post-panel section-t-space">
                                        <div className="post-wrapper col-grid-box">
                                            <div className="post-title">
                                                <div className="profile">
                                                    <div className="media">
                                                        <div className="user-img">
                                                        </div>
                                                        <div className="media-body">
                                                            <h5 />
                                                            <h6 />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="post-details ratio2_1">
                                                <div className="img-wrapper bg-size">
                                                </div>
                                                <div className="detail-box">
                                                    <div className="ldr-p">
                                                        <p />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="post-wrapper col-grid-box section-t-space">
                                            <div className="post-title">
                                                <div className="profile">
                                                    <div className="media">
                                                        <div className="user-img">
                                                        </div>
                                                        <div className="media-body">
                                                            <h5 />
                                                            <h6 />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="post-details ratio2_1">
                                                <div className="img-wrapper bg-size">
                                                </div>
                                                <div className="detail-box">
                                                    <div className="ldr-p">
                                                        <p />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> :
                    <div>
                        {/* <div className="overlay-bg" /> */}
                        <div className="post-panel  section-t-space">
                          {postData.length !== 0 ?  <div>
                                {postData.map((data, i) =>
                                    <div key={i} >
                                        <div className="post-wrapper col-grid-box">
                                            <div className="post-title" key={j++}>
                                                <div className="profile">
                                                    <div className="media">
                                                        <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right" data-name="sufiya eliza" data-img="../assets/images/user-sm/1.jpg" onClick={() => gotoUserProfile(data.user_id)}>
                                                            <Stack direction="row" spacing={0}>
                                                                <Avatar style={{ width: '50px', height: '50px' }} className="img-fluid-post blur-up lazyload bg-img imgrad" alt={data?.firstname} src={data?.s3profileurl} />
                                                            </Stack>
                                                        </a>
                                                        <div className="media-body">
                                                            <a style={{ textAlign: 'left', }}>
                                                                <h5 style={{ marginBottom: '4px', color: '#0389c9', fontSize: '14px' }} onClick={() => gotoUserProfile(data.user_id)}>{data?.firstname} {data?.lastname}</h5>
                                                                <h6 style={{ fontSize: '10px', }}>{moment(data?.created_at).format('lll')}</h6>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="post-details">
                                                <div className="detail-box">
                                                    {clas[data.ID] ? <p style={{ fontSize: '14px', color:'#51595e !important' }}>{data?.content} </p> : <p className='blogDes' style={{ fontSize: '14px' }}>{data?.content} </p>}
                                                    {data?.content.match(/is/g) || [].length === 20 ? <p onClick={() => contantToggle(data.ID)} style={{ fontSize: '10px', textAlign: 'right', marginTop: '0px', cursor: 'grab', color: '#0389c9' }}>{clas[data.ID] ? <span>Less...</span> : <span>More...</span>}</p> : null}
                                                </div>
                                            </div>

                                            <div className="img-wrapper">
                                                <div className="gallery-section">
                                                    <div className="portfolio-section ratio_square">
                                                        {data?.post_for_free === "free" ||  data?.post_pay_status === '1'  ? 
                                                        <div className="container-fluid p-0">
                                                            {data.media_data.length === 1 ? <div className="row">
                                                                {data.media_data.map((media, i) =>
                                                                    <div className="col-12 m-0" key={i + 1}>
                                                                        <div className="overlay">
                                                                            <div className="portfolio-image">
                                                                                {media?.media_type === 'image' ?
                                                                                    <div className='imagecontainer1'>
                                                                                        <img src={media?.s3postmediaurl} style={{ width: '100%', }} className="img-fluid blur-up lazyload" alt='true'
                                                                                            onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                            onError={(event) => event.target.removeAttribute('src')}
                                                                                            onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                                        />

                                                                                    </div>
                                                                                    : null}

                                                                                {media?.media_type === 'video' ?
                                                                                    <div className='videocontainer2'>
                                                                                        <ReactVideo
                                                                                            poster='../assets/images/icon/logo.png'
                                                                                            src={media?.s3postmediaurl}
                                                                                            width='100%'
                                                                                            height='250px !important'
                                                                                            controls playsinline />
                                                                                    </div>
                                                                                    : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>)}
                                                            </div> : null}

                                                            {data.media_data.length === 2 ? <div className="row">
                                                                {data.media_data.map((media, i) =>
                                                                    <div className="col-6 m-0" key={i + 2}>
                                                                        <div className="overlay">
                                                                            <div className="portfolio-image">
                                                                                {media?.media_type === 'image' ?
                                                                                    <div className='imagecontainer2'>
                                                                                        <img src={media?.s3postmediaurl} className="containerImage2" alt='true'
                                                                                            onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                            onError={(event) => event.target.removeAttribute('src')}
                                                                                            onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                                        />
                                                                                    </div>
                                                                                    : null}

                                                                                {media?.media_type === 'video' ?
                                                                                    <div className='videocontainer2'>
                                                                                        <ReactVideo
                                                                                            poster='../assets/images/icon/logo.png'
                                                                                            src={media?.s3postmediaurl}
                                                                                            width='100%'
                                                                                            height='250px !important'
                                                                                            controls playsinline />
                                                                                    </div>
                                                                                    : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>)}
                                                            </div> : null}
                                                            {data.media_data.length === 3 ? 
                                                            <div className="row">
                                                                {data.media_data.map((media, i) =>
                                                                    i <= 1 ? <div className="col-6 m-0" key={i + 3}>
                                                                        <div className="overlay">
                                                                            <div className="portfolio-image">
                                                                                {media?.media_type === 'image' ?
                                                                                    <div className='imagecontainer3'>
                                                                                        <img src={media?.s3postmediaurl} className="containerImage3" style={{ width: '100%', objectFit: 'cover' }}
                                                                                            onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                            onError={(event) => event.target.removeAttribute('src')}
                                                                                            onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                                        />
                                                                                    </div>
                                                                                    : null}

                                                                                {media?.media_type === 'video' ?
                                                                                    <div className='videocontainer2 mb-1'>
                                                                                        <ReactVideo
                                                                                            poster='../assets/images/icon/logo.png'
                                                                                            src={media?.s3postmediaurl}
                                                                                            width='100%'
                                                                                            height='250px !important'
                                                                                            controls playsinline />
                                                                                    </div>
                                                                                    : null}
                                                                            </div>
                                                                        </div>
                                                                    </div> : <div className="col-12 m-0">
                                                                        <div className="overlay">
                                                                            <div className="portfolio-image">
                                                                                {media?.media_type === 'image' ?
                                                                                    <div className=''>
                                                                                        <img src={media?.s3postmediaurl} style={{ width: '100%', objectFit: 'cover' }} className="" alt='true'
                                                                                            onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                            onError={(event) => event.target.removeAttribute('src')}
                                                                                            onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                                        />
                                                                                    </div>
                                                                                    : null}

                                                                                {media?.media_type === 'video' ?
                                                                                    <div className='videocontainer2 mb-1'>
                                                                                        <ReactVideo
                                                                                            poster='../assets/images/icon/logo.png'
                                                                                            src={media?.s3postmediaurl}
                                                                                            width='100%'
                                                                                            height='250px !important'
                                                                                            controls playsinline />
                                                                                    </div>
                                                                                    : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>)}
                                                            </div> : null}

                                                            {data.media_data.length === 4 ? <div className="row">
                                                                {data.media_data.map((media, i) =>
                                                                    <div className="col-6 m-0" key={i + 4}>
                                                                        <div className="overlay">
                                                                            <div className="portfolio-image">
                                                                                {media?.media_type === 'image' ?
                                                                                    <div className='imagecontainer3'>
                                                                                        <img src={media?.s3postmediaurl} style={{ width: '100%', objectFit: 'cover' }} className="containerImage3" alt='true'
                                                                                            onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                            onError={(event) => event.target.removeAttribute('src')}
                                                                                            onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                                        />
                                                                                    </div>
                                                                                    : null}

                                                                                {media?.media_type === 'video' ?
                                                                                    <div className='videocontainer2 mb-1'>
                                                                                        <ReactVideo
                                                                                            poster='../assets/images/icon/logo.png'
                                                                                            src={media?.s3postmediaurl}
                                                                                            width='100%'
                                                                                            height='250px !important'
                                                                                            controls playsinline />
                                                                                    </div>
                                                                                    : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>)}
                                                            </div> : null}

                                                            {data.media_data.length > 4 ? 
                                                            <div className="row">
                                                                {data.media_data.map((media, i) =>
                                                                    i <= 2 ? <div className="col-6 m-0" key={i + 5}>
                                                                        <div className="overlay">
                                                                            <div className="portfolio-image">
                                                                                {media?.media_type === 'image' ?
                                                                                    <div className='imagecontainer3'>
                                                                                        <img src={media?.s3postmediaurl} style={{ width: '100%', objectFit: 'cover' }} className="containerImage3" alt='true'
                                                                                            onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                            onError={(event) => event.target.removeAttribute('src')}
                                                                                            onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                                        />
                                                                                    </div>
                                                                                    : null}

                                                                                {media?.media_type === 'video' ?
                                                                                    <div className='videocontainer2 mb-1'>
                                                                                        <ReactVideo
                                                                                            poster='../assets/images/icon/logo.png'
                                                                                            src={media?.s3postmediaurl}
                                                                                            width='100%'
                                                                                            height='250px !important'
                                                                                            controls playsinline />
                                                                                    </div>
                                                                                    : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                        :
                                                                        <div className="col-6 m-0">
                                                                            {i === 3 ? <div>
                                                                                <div className='imagecontainer3'>
                                                                                    <div className="portfolio-image">
                                                                                        <img src={media?.s3postmediaurl} alt='true' className="containerImage3" />
                                                                                    </div>
                                                                                </div>
                                                                                {i === 4 ?
                                                                                    <div className="imagecontainer3" style={{ position: 'absolute', top: '0px', }}>
                                                                                        <div className='portfolio-image'>
                                                                                            <a data-bs-toggle="modal" data-bs-target="#imageModel" >
                                                                                                <img src={media?.s3postmediaurl} alt='true' className="containerImage3" />
                                                                                            </a>
                                                                                        </div>
                                                                                    </div> : <div className="imagecontainer3" style={{ position: 'absolute', top: '0px', }}>
                                                                                        <div className="portfolio-image">
                                                                                            <a data-bs-toggle="modal" data-bs-target="#imageModel" >
                                                                                                <h1 className='imageCount'>{data.media_data.length - 4}+</h1>
                                                                                                <img src={media?.s3postmediaurl} alt='true' className="containerImage3" style={{ filter: 'brightness(0.5)' }} />
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>}
                                                                            </div> : null}
                                                                        </div>
                                                                )}
                                                                <div>
                                                                </div>
                                                            </div> : null}

                                                            {/* <PostLikes props={data} /> */}
                                                            <div className="post-react">
                                                                <ul style={{ justifyContent: 'unset' }}>
                                                                    <li className="comment-click">
                                                                        <PostLikes props={data} parentCallback={handleCallback} />
                                                                    </li>
                                                                    <li >
                                                                <a onClick={() => sentTpsData(data.ID)}><Tooltip title="Sent Tip" ><MonetizationOnIcon style={{ fontSize: '18px' }} /></Tooltip></a>
                                                            </li>
                                                                    <li style={{ position: 'absolute', right: '20px' }}>
                                                                        <a onClick={() => commentsPost(data.ID)}><ChatBubbleOutlineIcon style={{ fontSize: '16px' }} /><span>{data?.total_comments}</span></a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            {commentPanel[data.ID] ? <PostComment props={data} parentCallback={handleCallback} /> : null}
                                                        </div> :
                                                        <div>
                                                            <PaidPost prop={data} parentCallbacks={handleCallbacks} />
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {tips[data.ID] ? <SendDialog props={data} parentCallback={handleCallback} /> : null}
                                        <div className="my-2 hide_desktop">
                                            {j % 3 === 0 ? <FriendSuggestion /> : null}
                                        </div>
                                       
                                    </div>
                                )}
                            </div>:
                            <div style={{textAlign:'center'}}>No post available.</div>}
                        </div>
                       </div>}
            </div>
        </>)

}

export default Center
