import * as React from 'react';
import { useEffect, useState, useRef } from "react";
import Card from '@mui/material/Card';
import api from "../../Api";
import Loader from "react-loader-spinner";
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
// import StarBorderIcon from '@material-ui/icons/StarBorder';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    imageList: {
      width: '100%',
      height: '100%',
      transform: 'translateZ(0)',
    },
    titleBar: {
      background:
        'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
        'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
      color: 'white',
    },
  }));

function MyCoverImage() {
    const componentMounted = useRef(true);
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const [photo, setPhoto] = React.useState([]);
    const [loadings, setLoadings] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        if (componentMounted.current) {
            getCover();
        }
        return () => {
            componentMounted.current = false;
        }
    }, []);

    const getCover = () => {
        api.get(`/get-user-allcover-images/${currentUser.id}`).then(data => {
            console.log(data.data)
            setPhoto(data.data);
        });
    }

 

    const gotoBack = () => {
        window.history.go(-1)
    }

    const imageDelete = (ID) =>{
        console.log(ID)
        // api.get(`/get-user-allcover-images/${currentUser.id}`).then(data => {
        //     console.log(data.data)
        // });
    }

    return (
        <><div className='padd'>
            <Card variant="outlined" className="padds">
                <div>
                    <h2>
                        <svg onClick={() => gotoBack()} width={15} height={15} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-left fa-w-14 fa-2x"><path fill="currentColor" d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" class=""></path>
                        </svg> My intrest</h2>
                </div><hr />
                {loadings ?
                    <div style={{ textAlign: 'center' }} className='mt-4 mb-4'>
                        <Loader type="Bars" color="#3e89c9" height={100} width={100} />
                    </div>
                    :

                    <div className={classes.root}>
                            <ImageList rowHeight={200} gap={1} className={classes.imageList}>
                                {photo.map((item, i) => (
                                     <ImageListItem key={i} cols={item.featured ? 3 : 1} rows={item.featured ? 3 : 1}>
                                     <img src={item.s3coverurl} alt='{item.title}' style={{width:'100%', height:'100%'}}/>
                                     <ImageListItemBar
                                     onClick={()=>imageDelete(item.ID)}
                                       position="top"
                                       actionIcon={
                                        <svg width={25} height={15} style={{color:'ActiveBorder'}} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="svg-inline--fa fa-times fa-w-11 fa-2x">
                                            <path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" class=""></path>
                                        </svg>
                                       }
                                       actionPosition="left"
                                       className={classes.titleBar}
                                     />
                                   </ImageListItem>
                                ))}
                            </ImageList>
                    </div>}
            </Card>
        </div>
        </>
    );
}

export default MyCoverImage;