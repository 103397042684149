import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './assets/css/style.css';
import reportWebVitals from './reportWebVitals';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-image-gallery/styles/css/image-gallery.css";
// import { stopReportingRuntimeErrors } from "react-error-overlay";
// stopReportingRuntimeErrors(); // disables error overlays
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
