import { createSlice } from '@reduxjs/toolkit'
import api from "../../Api";
import Swal from "sweetalert2";

// Slice
const slice = createSlice({
    name: 'userCoverProfile',
    name: 'userCoverImg',
    initialState: {
        userupdateProfile: null,
        userCoverImg:[],
    },
    reducers: {
        UserCoverProfileSuccess:(state, action) => {
          state.userCoverProfile = action.payload;
      },

      UserCoverGetProfileSuccess:(data, action) => {
        data.userCoverImg = action.payload;
    },
    },
  });
  
  export default slice.reducer
  // Actions
  const { 
    UserCoverProfileSuccess,
     UserCoverGetProfileSuccess } = slice.actions;
  
  export const UseCoverInsertProfile = (body) => async dispatch => {
    try {
      const res = await api.post('user/UserCoverImages', body);
      if(res.data.Status == false){
        Swal.fire({  
          title: 'Warning',  
          text: res.data.message,  
          icon: 'warning',   
          confirmButtonColor: '#3085d6',  
          confirmButtonText: 'Ok',
          timer: 1500  
        });
      }else{
        Swal.fire({  
            title: 'Successs',  
            text: res.data.message,  
            icon: 'success',   
            confirmButtonColor: '#3085d6',  
            confirmButtonText: 'Yes!',
            timer: 1500  
          });
        dispatch(UserCoverProfileSuccess(res.data));
      }
  
    } catch (e) {
      return console.error(e.message);
    }
  }

  export const UseCoverGetProfile = (body) => async dispatch => {
    try {
      const res = await api.get(`user/UserCoverImages/${body}`);
        dispatch(UserCoverGetProfileSuccess(res.data));
    } catch (e) {
      return console.error(e.message);
    }
  }