import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useFormik } from 'formik';

import { useDispatch, useSelector } from 'react-redux';
import { UserRestPassword } from '../../../store/Accounts/restPass';

const validateUser = forgotPass => {
    const errors = {};
    const pattern = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');
    if (!forgotPass.password) {
        errors.password = '*Please Enter Password';
    } else if (!pattern.test(forgotPass.password)) {
        errors.password = `*Password Minimum eight characters, at
        least one uppercase letter, one lowercase letter, one number and one
        special character`;
    }

    if (!forgotPass.confirm_password) {
        errors.confirm_password = 'Please Enter Confirm Password';
    } else if (!pattern.test(forgotPass.confirm_password)) {
        errors.confirm_password = `*Password Minimum eight characters, at
        least one uppercase letter, one lowercase letter, one number and one
        special character`;
    }

    if(!pattern.test(forgotPass.password) != !pattern.test(forgotPass.confirm_password)){
        errors.confirm_password = `*Password does not match`;
    }

    return errors;
};

function ChangePassword() {
    const dispatch = useDispatch();
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const { restPass } = useSelector(state => state);
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isRevealPwd2, setIsRevealPwd2] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const formik = useFormik({
        initialValues: {
            id: currentUser.id,
            password: '',
            confirm_password: ''
        },
        validate: validateUser,
        onSubmit: values => {
           if(values.password === values.confirm_password){
            let body = {
                id: currentUser.id,
                password: values.password,  
            }
            dispatch(UserRestPassword(body))
            setLoading(true);
            window.history.go(-1);
           }else{

           }
        }
    });

    return (
        <>
            <div className='padd'>
                <Box sx={{ minWidth: 275 }} className='mt-2'>
                    <Card variant="outlined" className="padds">
                        <div>
                            <h2>CHANGE PASSWORD</h2>
                        </div><hr />
                        <div>
                            
                            <form className="theme-form">

                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="exampleInputPassword1">Password</label>
                                        <input type="password" type={isRevealPwd ? "text" : "password"} name="password" className="form-control" id="password" value={formik.values.password}
                                            onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Password" />
                                        <div className="passIcon">
                                            {isRevealPwd ? <VisibilityOffIcon onClick={() => setIsRevealPwd(prevState => !prevState)} /> :
                                                <VisibilityIcon onClick={() => setIsRevealPwd(prevState => !prevState)} />
                                            }
                                        </div>
                                        {formik.touched.password && formik.errors.password ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.password}</span> : null}
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="exampleInputPassword1">Confirm Password</label>
                                        <input type={isRevealPwd2 ? "text" : "password"}  name="confirm_password" className="form-control" value={formik.values.confirm_password}
                                            onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Confirm Password" />
                                        <span className="passIcon">
                                            {isRevealPwd2 ? <VisibilityOffIcon onClick={() => setIsRevealPwd2(prevState => !prevState)} /> :
                                                <VisibilityIcon onClick={() => setIsRevealPwd2(prevState => !prevState)} />
                                            }
                                        </span>
                                        {formik.touched.confirm_password && formik.errors.confirm_password ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.confirm_password}</span> : null}
                                    </div>
                                </div>

                                <div className="row pt-2 pb-3 mt-4">
                                    <div className="col-md-3" />
                                    <div className="col-md-6">
                                        
                                    </div>
                                    <div className="col-md-3">
                                    <div style={{ textAlign: 'center' }}>
                                            <Stack >
                                                <Button variant="contained" className='br' onClick={formik.handleSubmit}>
                                                    {loading ? <span><span className="spinner-border spinner-border-sm"></span> Please wait...</span> : 'Submit'}
                                                </Button>
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </Card>
                </Box>
            </div>
        </>
    );
}

export default ChangePassword;