import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

function Lists() {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const handleClick = () => {
        console.info('You clicked the Chip.');
    };

    const gotoBack = () => {
        history.push('/home')
    }

    return (
        <>
            <div>
                <Box sx={{ minWidth: '100%' }} >
                    <Card variant="outlined" className="padds">
                        <div>
                            <h2> <Stack direction="row" spacing={1}>
                                <IconButton aria-label="fingerprint" color="secondary" onClick={gotoBack}>
                                    <ArrowBackIcon />
                                </IconButton>
                                <IconButton disabled aria-label="fingerprint" color="success">
                                    List
                                </IconButton>

                            </Stack></h2>
                        </div><hr />
                        <div>
                            <h3>CUSTOM ORDER</h3>
                        </div>
                        <List sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                    primary="Closed Friends"
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                1 Person
                                            </Typography>{" . 23 post"}
                                        </React.Fragment>
                                    }
                                />
                                <ListItemAvatar>
                                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                                </ListItemAvatar>
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem alignItems="flex-start">

                                <ListItemText
                                    primary="Following"
                                    secondary={
                                        <React.Fragment>
                                            <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary"
                                            >
                                                1 Person
                                            </Typography> {" . 23 post"}
                                        </React.Fragment>
                                    }
                                />
                                <ListItemAvatar>
                                    <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                                </ListItemAvatar>
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem alignItems="flex-start">

                                <ListItemText
                                    primary="Blocked"
                                    secondary={
                                        <React.Fragment>
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                               1 Person
                                            </Typography>{'" . 23 post"'}
                                        </React.Fragment>
                                    }
                                />
                                <ListItemAvatar>
                                    <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
                                </ListItemAvatar>
                            </ListItem>
                        </List>

                        <div className="card-titles mt-4 mb-4" >
                            <h3 style={{ textAlign: 'center' }}>No Lists currently!</h3>
                        </div>
                    </Card>
                </Box>
            </div>
        </>
    );
}

export default Lists;