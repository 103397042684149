import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import PostLikes from "../dasboard/center/post/likes/likes";
import PostComment from "../dasboard/center/post/likes/comments";
import Avatar from '@mui/material/Avatar';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import PaidPost from "../lightbox/postPaid";
import { ReactVideo } from "reactjs-media";
import { useParams } from "react-router";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Loader from "react-loader-spinner";
import api from "../Api";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SendDialog from "../Dialog/sendTips";

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

function NotificationData() {
    const ids = useParams().path;
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const [name, setName] = useState('');
    const [postData, setPostData] = useState([]);
    const [clas, setClas] = React.useState([false]);
    const [loadings, setLoadings] = useState(false);
    const [tips, setTips] = useState([false]);
    const history = useHistory();


    useEffect(() => { getPost()}, [ids]);

    const getPost = () => {
        setLoadings(true)
        api.get(`/get-user-single-post/${ids}/${currentUser.id}`).then(res => {
            if (res.data.Status != false) {
                setLoadings(false)
                setPostData(res.data);
                let fullname = ''
                res.data.forEach(element => {
                    fullname = `${element.firstname} ${element.lastname}`;
                    setName(fullname);
                    setLoadings(false)
                });
            }
        })
    }

    const contantToggle = (id) => {
        let ClassToggle = {}
        if (!clas[id]) {
            ClassToggle[id] = true
            setClas(ClassToggle);
        } else {
            ClassToggle[id] = false
            setClas(ClassToggle);
        }
    }

    const gotoUserProfile = (ID) => {
        if (ID != currentUser.id) {
            history.push(`/subscriptions/${ID}`);
        } else {
            history.push('/User/profile');
        }
    }

    const lightBoxHandlerBar = (data) => {
        history.push(`/media/${data}`);
    }

    const handleCallback = (childData) => {
        api.get(`/get-user-single-post/${ids}/${currentUser.id}`).then(res => {
            if (res.data.Status != false) {
                setPostData(res.data);
                let fullname = ''
                res.data.forEach(element => {
                    fullname = `${element.firstname} ${element.lastname}`;
                    setName(fullname);
                    setLoadings(false)
                });
            }
        })
    }
    const gotoBack = () => {
        window.history.go(-1)
    }

    const sentTpsData = (id) => {
        let dataToggle = {}
        if (!tips[id]) {
            dataToggle[id] = true;
            setTips(dataToggle);

        } else {
            dataToggle[id] = false;
            setTips(dataToggle);
        }
    }


    return (
        <>
            <Box sx={{ minWidth: '100%', height: '100%' }} >
                <Card variant="outlined" className="padds">
                    <div>
                        <h3><ArrowBackIcon onClick={() => gotoBack()} fontSize="inherit" /> {name}</h3>
                    </div><hr />
                    {loadings ?
                        <div style={{ textAlign: 'center' }} className='mt-4 mb-4'>
                            <Loader type="Bars" color="#3e89c9" height={30} width={30} />
                        </div>
                        :
                        <div className="post-panel  section-t-space">
                            <div>
                                {postData.map((data, i) => <div key={i} className="post-wrapper col-grid-box">
                                    <div className="post-title">
                                        <div className="profile">
                                            <div className="media">
                                                <a className="popover-cls user-img" onClick={() => gotoUserProfile(data?.ID)}>
                                                    <Avatar style={{ width: '50px', height: '50px' }} className="img-fluid-post blur-up lazyload bg-img imgrad" alt={data?.firstname} src={data?.s3profileurl} />
                                                </a>
                                                <div className="media-body">
                                                    <a style={{ textAlign: 'left', }}>
                                                        <h5 style={{ marginBottom: '4px', color: '#0389c9', fontSize: '14px' }} onClick={() => gotoUserProfile(data.user_id)}>{data?.firstname} {data?.lastname}</h5>
                                                        <h6 style={{ fontSize: '10px', }}>{moment(data?.created_at).format('lll')}</h6>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-details">
                                        <div className="detail-box">
                                            {clas[data.ID] ? <p style={{ fontSize: '14px' }}>{data?.content} </p> : <p className='blogDes' style={{ fontSize: '14px' }}>{data?.content} </p>}
                                            {data?.content.length == 350 ? <p onClick={() => contantToggle(data.ID)} style={{ fontSize: '10px', textAlign: 'right', marginTop: '0px', cursor: 'grab', color: '#0389c9' }}>{clas[data.ID] ? <span>Less...</span> : <span>More...</span>}</p> : null}
                                        </div>
                                    </div>

                                    <div className="img-wrapper">
                                        <div className="gallery-section">
                                            <div className="portfolio-section ratio_square">
                                                {data?.post_for_free != "paid" && data?.post_pay_status != 1 || data?.user_id == currentUser.id ? <div className="container-fluid p-0">
                                                    {data.media_data.length == 1 ? 
                                                    <div className="row">
                                                        {data.media_data.map((media, i) =>
                                                            <div className="col-12 m-0" key={i + 1}>
                                                                <div className="overlay">
                                                                    <div className="portfolio-image">
                                                                        {media?.media_type == 'image' ?
                                                                            <div className='imagecontainer1'>
                                                                                <img src={media?.s3postmediaurl} style={{ width: '100%', }} className="img-fluid blur-up lazyload" alt='true'
                                                                                    onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                    onError={(event) => event.target.removeAttribute('src')}
                                                                                    onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                                />
                                                                            </div>
                                                                            :
                                                                            <div className='videocontainer2'>
                                                                                <ReactVideo
                                                                                    poster='../assets/images/icon/logo.png'
                                                                                    src={media?.s3postmediaurl}
                                                                                    width='100%'
                                                                                    height='250px !important'
                                                                                    controls playsinline
                                                                                    config={{ file: { attributes: { controlsList: 'nodownload' } } }} />
                                                                            </div>}
                                                                    </div>
                                                                </div>
                                                            </div>)}
                                                    </div> : null}

                                                    {data.media_data.length == 2 ?
                                                        <Box sx={{ width: "100%", height: "100%" }}>
                                                            <ImageList cols={2}>
                                                                {data.media_data.map((item, i) => (
                                                                    <ImageListItem key={item.s3postmediaurl}>
                                                                        <div className='imagecontainer2'>
                                                                            {item?.media_type == 'image' ?
                                                                                <div className='imagecontainer2'>
                                                                                    <img src={item?.s3postmediaurl} srcSet={item?.s3postmediaurl}
                                                                                        className="containerImage2" alt='true'
                                                                                        onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                        onError={(event) => event.target.removeAttribute('src')} loading="lazy"
                                                                                        onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                                    />
                                                                                </div>
                                                                                :
                                                                                <ReactVideo poster='../assets/images/icon/logo.png'
                                                                                    src={item?.s3postmediaurl}
                                                                                    width='100%'
                                                                                    height='250px !important'
                                                                                    controls playsinline
                                                                                    config={{ file: { attributes: { controlsList: 'nodownload' } } }} />}
                                                                        </div>
                                                                    </ImageListItem>
                                                                ))}
                                                            </ImageList>
                                                        </Box> : null}


                                                    {data.media_data.length == 3 ?
                                                        <div className="row">
                                                            {data.media_data.map((media, i) =>
                                                                i <= 1 ? <div className="col-6 m-0" key={i + 3}>
                                                                    <div className="overlay">
                                                                        <div className="portfolio-image">
                                                                            {media?.media_type == 'image' ?
                                                                                <div className='imagecontainer3'>
                                                                                    <img src={media?.s3postmediaurl} className="containerImage3" style={{ width: '100%', objectFit: 'cover' }}
                                                                                        onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                        onError={(event) => event.target.removeAttribute('src')} loading="lazy"
                                                                                        onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                                    />
                                                                                </div>
                                                                                : null}

                                                                            {media?.media_type == 'video' ?
                                                                                <div className='videocontainer2 mb-1'>
                                                                                    <ReactVideo
                                                                                     config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                                                                        poster='../assets/images/icon/logo.png'
                                                                                        src={media?.s3postmediaurl}
                                                                                        width='100%'
                                                                                        height='250px !important'
                                                                                        controls playsinline />
                                                                                </div>
                                                                                : null}
                                                                        </div>
                                                                    </div>
                                                                </div> : <div className="col-12 m-0">
                                                                    <div className="overlay">
                                                                        <div className="portfolio-image">
                                                                            {media?.media_type == 'image' ?
                                                                                <div className=''>
                                                                                    <img src={media?.s3postmediaurl} style={{ width: '100%', objectFit: 'cover' }} className="" alt='true'
                                                                                        onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                        onError={(event) => event.target.removeAttribute('src')} loading="lazy"
                                                                                        onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                                    />
                                                                                </div>
                                                                                : null}

                                                                            {media?.media_type == 'video' ?
                                                                                <div className='videocontainer2 mb-1'>
                                                                                    <ReactVideo
                                                                                       config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                                                                        poster='../assets/images/icon/logo.png'
                                                                                        src={media?.s3postmediaurl}
                                                                                        width='100%'
                                                                                        height='250px !important'
                                                                                        controls playsinline />
                                                                                </div>
                                                                                : null}
                                                                        </div>
                                                                    </div>
                                                                </div>)}
                                                        </div>
                                                        : null}

                                                    {data.media_data.length == 4 ?
                                                        <Box sx={{ width: "100%", height: "100%" }}>
                                                            <ImageList cols={2}>
                                                                {data.media_data.map((item, i) => (
                                                                    <ImageListItem key={item.s3postmediaurl}>
                                                                        <div className='imagecontainer2'>
                                                                            {item?.media_type == 'image' ?
                                                                                <div className='imagecontainer2'>
                                                                                    <img src={item?.s3postmediaurl} srcSet={item?.s3postmediaurl}
                                                                                        className="containerImage2" alt='true'
                                                                                        onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                        onError={(event) => event.target.removeAttribute('src')} loading="lazy"
                                                                                        onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                                    />
                                                                                </div>
                                                                                :
                                                                                <div className='videocontainer2 mb-1'>
                                                                                <ReactVideo poster='../assets/images/icon/logo.png'
                                                                                    src={item?.s3postmediaurl}
                                                                                    width='100%'
                                                                                    height='250px !important'
                                                                                    controlsList="nodownload"
                                                                                     />
                                                                                    </div>}
                                                                        </div>
                                                                    </ImageListItem>
                                                                ))}
                                                            </ImageList>
                                                        </Box> : null}

                                                    {data.media_data.length > 4 ?
                                                        <div className="row">
                                                            {data.media_data.map((media, i) =>
                                                                i <= 2 ? <div className="col-6 m-0" key={i + 5}>
                                                                    <div className="overlay">
                                                                        <div className="portfolio-image">
                                                                            {media?.media_type == 'image' ?
                                                                                <div className='imagecontainer3'>
                                                                                    <img src={media?.s3postmediaurl} style={{ width: '100%', objectFit: 'cover' }} className="containerImage3" alt='true'
                                                                                        onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                        onError={(event) => event.target.removeAttribute('src')} loading="lazy"
                                                                                        onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                                    />
                                                                                </div>
                                                                                : null}

                                                                            {media?.media_type == 'video' ?
                                                                                <div className='videocontainer2 mb-1'>
                                                                                    <ReactVideo
                                                                                        poster='../assets/images/icon/logo.png'
                                                                                        src={media?.s3postmediaurl}
                                                                                        width='100%'
                                                                                        height='250px !important'
                                                                                        controls playsinline />
                                                                                </div>
                                                                                : null}
                                                                        </div>
                                                                    </div>
                                                                </div> :
                                                                <div className="col-6 m-0">
                                                                    {i == 3 ? 
                                                                    <div>
                                                                        <div className='imagecontainer3'>
                                                                            <div className="portfolio-image">
                                                                                <img src={media?.s3postmediaurl} alt='true' className="containerImage3" />
                                                                            </div>
                                                                        </div>
                                                                    {i == 4 ?
                                                                        <div className="imagecontainer3" style={{ position: 'absolute', top: '0px', }}>
                                                                            <div className='portfolio-image'>
                                                                                <a data-bs-toggle="modal" data-bs-target="#imageModel" >
                                                                                    <img src={media?.s3postmediaurl} alt='true' className="containerImage3" />
                                                                                </a>
                                                                        </div>
                                                                        </div> : 
                                                                        <div className="imagecontainer3" style={{ position: 'absolute', top: '0px', }}>
                                                                            <div className="portfolio-image">
                                                                                <a data-bs-toggle="modal" data-bs-target="#imageModel" >
                                                                                   <h1 className='imageCount'>{data.media_data.length - 4}+</h1>
                                                                                   <img src={media?.s3postmediaurl} alt='true' className="containerImage3" style={{ filter: 'brightness(0.5)' }} />
                                                                                </a>
                                                                            </div>
                                                                        </div>}
                                                                    </div>
                                                                    :
                                                                    null}
                                                                </div>
                                                            )}
                                                            <div>
                                                            </div>
                                                        </div> : null}

                                                    <div className="post-react">
                                                        <ul style={{ justifyContent: 'unset' }}>
                                                            <li className="comment-click">
                                                                <PostLikes props={data} parentCallback={handleCallback} />
                                                            </li>
                                                            <li >
                                                                <a onClick={() => sentTpsData(data.ID)}><MonetizationOnIcon style={{ fontSize: '18px' }} /><span>Send Tip</span></a>
                                                            </li>
                                                            <li style={{ position: 'absolute', right: '20px' }}>
                                                                <a>Comment <span>&nbsp; {data?.total_comments}</span></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <PostComment props={data} parentCallback={handleCallback} />
                                                </div> :
                                                    <div><PaidPost prop={data} /></div>}
                                            </div>
                                        </div>
                                    </div>
                                    {tips[data.ID] ? <SendDialog props={data} /> : null}
                                </div>)}
                            </div>
                        </div>}
                </Card>
            </Box>
        </>
    );
}

export default NotificationData;
