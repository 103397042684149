import React, { useState, useEffect, useRef } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Loader from "react-loader-spinner";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import api from "../Api";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

function MyWallets() {
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const [wallets, setWallets] = useState([]);
    const [walletsall, setWalletsall] = useState([]);
    const componentMounted = useRef(true);
    const [loadings, setLoadings] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [remaining, setRemaining] = useState('100');
    const history = useHistory();
    const handleClick = () => {
        console.info('You clicked the Chip.');
    };

    useEffect(() => {
        if (componentMounted.current) {
            getwallets();
        }
        return () => {
            componentMounted.current = false;
        }
    }, []);

    const getwallets = () => {
        let arr = [];
        setLoadings(true);
        api.get(`/wallet-amount/${currentUser.id}`).then(res => {
            if (res.data.Status != false) {
                for (let index = 0; index < res.data.length; index++) {
                    const ele = res.data[0];
                    if (ele.remainingamount !== 0) {
                        setRemaining(ele.remainingamount)
                        setDisabled(false)
                    }
                }
                if (res.data[1].length != 0) {
                    res.data[1].forEach(element => {
                        element.amount = '$' + element.total_amount
                        if (element.payment_on === 'post') {
                            element.icons = 'ImageIcon'
                        } else if (element.payment_on === 'profile') {
                            element.icons = 'AccountCircleIcon'
                        } else {
                            element.icons = 'EmailIcon'
                        }
                        arr.push(element)
                    });
                    setTimeout(() => {
                        setWalletsall(arr)
                    }, 1000);
                }
                setWallets(res.data)
            }
            setLoadings(false);
        }).catch(err => { });
    }

    const gotoBack = () => {
        window.history.go(-1);
    }

    const wthdraw = () => {
        api.get(`/cardsDetails/fetch/${currentUser.id}`).then(res => {
            console.log(res)
         if(res.data.length !== 0){
            if (remaining > 10) {
                let amount = remaining / 2;
                let body = {
                    user_id: currentUser.id,
                    withdrawal_amount: amount
                }
                console.log(body)
                api.post('/withdrawal-request', body).then(res => {
                    console.log(res.data)
                })
            } else {
                Swal.fire({
                    title: 'Warning',
                    text: 'Withdrawal Amount at least $10.',
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok',
                });
            }
         }else{
             history.push('/profile/card_add');
         }

    })
    }

    return (
        <>
            <Box sx={{ minWidth: '100%', height: '100%' }}>
                <Card variant="outlined" className="padds">
                    <div>
                        <h3><ArrowBackIcon onClick={() => gotoBack()} fontSize="inherit" /> My Wallet </h3>
                    </div><hr></hr>
                    <div>
                        <Stack direction="row" spacing={1}>
                            <Chip label="All" onClick={handleClick} style={{ background: 'blue', color: 'white' }} />
                            <Chip label="Post" onClick={handleClick} />
                            <Chip label="Profile" onClick={handleClick} />
                            <Chip label="Tip" onClick={handleClick} />
                        </Stack>
                    </div>

                    {loadings ?
                        <div style={{ textAlign: 'center' }} className='mt-4 mb-4'>
                            <Loader type="Bars" color="#3e89c9" height={30} width={30} />
                        </div>
                        :
                        <div className="post-panel  section-t-space">
                            <div><hr></hr>
                                {wallets.map((data, i) => <List key={i} >
                                    <span>
                                        {i === 0 ? <ListItem>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <AccountBalanceWalletIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText 
                                            primary={"Total $ " + data?.totalamount} 
                                            secondary={'Withdrawal $ ' + data.witdrowlamount === null ? data.witdrowlamount : 'Withdrawal $ 0' }/>
                                            <ListItemSecondaryAction>
                                                {'Balance $ ' + data?.remainingamount}
                                                <Stack direction="row">
                                                    <Chip label="Withdrawal" disabled={disabled} onClick={() => wthdraw()} style={{ background: '#3e89c9', color: 'white', padding: '15px' }} />
                                                </Stack>
                                            </ListItemSecondaryAction>
                                        </ListItem> : null}
                                    </span>
                                </List>)}<hr></hr>
                            </div>
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                {walletsall.map((data, i) => <ListItem key={i}>
                                    <ListItemAvatar>
                                        <Avatar alt={data?.payment_on} src="">{data?.payment_on.charAt(0)}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={data?.payment_on} secondary={data?.amount} style={{ textTransform: 'capitalize', fontWeight: '500' }} />
                                </ListItem>)}

                                {walletsall.length === 0 ?
                                    <h2 style={{ textAlign: 'center' }}> 0 Earnings.</h2> : null}
                            </List><hr></hr>

                        </div>}
                </Card>
            </Box>

        </>
    );
}

export default MyWallets;