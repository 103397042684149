import * as React from 'react';
import { useRef, useEffect } from "react";
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';

import { useDispatch, useSelector } from 'react-redux';
import { UserProfile } from '../../store/Accounts/profile';

function Accounts() {
    const componentMounted = useRef(true);
    const dispatch = useDispatch();
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const { profile } = useSelector(state => state)
    const history = useHistory();

    useEffect(() => {
        if (componentMounted.current) {
            getProfile();
        }
        return () => {
            componentMounted.current = false;
        }
    }, []);

    const getProfile = () => {
        let body = {
            id: currentUser.id
        }
        dispatch(UserProfile(body))
    }

    const goTousername = () => {
        history.push('/profile/username')
    };
    const gotoemail = () => {
        history.push('/profile/email')
    }
    const gotoPhone = () => {
        history.push('/profile/phone')
    }
    const gotoPassword = () => {
        history.push('/profile/password')
    }
    const gotoLogin = () => {
        history.push('/profile/login')
    }
    const gotoDelete = () => {
        history.push('/profile/delete')
    }

    return (
        
        <div className="conatainer-fluid ">
            <div className="padd">
                <Box sx={{ minWidth: 275 }} className='mt-2 '>
                    <Card variant="outlined" className="padds">
                        <div>
                            <h2>ACCOUNT</h2>
                        </div><hr />
                        <div>
                            <div className='card-titles mb-3'>
                                <h3 className='mb-2'>Account info</h3>
                            </div><hr />
                        </div>
                        <List className='rightborder' sx={{ width: '100%', maxWidth: '100%' }}>
                            <ListItemButton secondaryAction={
                                <IconButton edge="end" aria-label="ArrowForwardIosIcon">
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            } onClick={goTousername}>
                                <ListItemText primary="Username" secondary={profile.userProfile?.user_name} />
                            </ListItemButton>
                            <Divider />
                            <ListItemButton onClick={gotoemail}>
                                <ListItemText primary="Email" secondary={profile.userProfile?.email} />
                            </ListItemButton>
                            <Divider />
                            <ListItemButton onClick={gotoPhone}>
                                <ListItemText primary="Phone number" secondary={profile.userProfile?.phone} />
                            </ListItemButton> <hr />
                            <div>
                                <div className='card-titles mb-3'>
                                    <h3 className='mb-2'>Security</h3>
                                </div><hr />
                            </div>
                            <ListItemButton  onClick={gotoPassword}>
                                <ListItemText primary="Password" />
                            </ListItemButton>
                            <Divider />
                            <ListItemButton onClick={gotoLogin}>
                                <ListItemText primary="Login session" />
                            </ListItemButton>
                            <hr />
                            <div>
                                <div className='card-titles mb-3'>
                                    <h3 className='mb-2'>Account management</h3>
                                </div><hr />
                            </div>
                            <ListItemButton onClick={gotoDelete}>
                                <ListItemText primary="Delete Account" />
                            </ListItemButton><hr />
                        </List>
                    </Card>
                </Box>

            </div>

        </div>
    );
}

export default Accounts;
