import { createSlice } from '@reduxjs/toolkit'
import api from "../../Api";
import Swal from "sweetalert2";

// Slice
const slice = createSlice({
    name: 'inbox',
    name:'unread',
    initialState: {
        inbox: [],
        unread: [],
    },
    reducers: {
      InboxSuccess:(data, action) => {
            data.inbox = action.payload;
      },
      MsgCountSuccess:(count, action) =>{
       count.unread = action.payload;
      }
    },
  });
  
  export default slice.reducer
  // Actions
  const { InboxSuccess, MsgCountSuccess } = slice.actions;
  
  export const getInbox = (id) => async dispatch => {
    try {
      const res = await api.get(`user/msg/inbox/${id}`);
      if(res.data.Status == false){
        // Swal.fire({  
        //   title: 'Warning',  
        //   text: res.data.Message,  
        //   icon: 'warning',   
        //   confirmButtonColor: '#3085d6',  
        //   confirmButtonText: 'Ok',
        //   timer: 1500  
        // });
      }else{
        dispatch(InboxSuccess(res.data));
      }
  
    } catch (e) {
      return console.error(e.message);
    }
  }

  export const getMSGcOUNT = (id) => async dispatch => {
    try {
      const res = await api.get(`user/message/count/${id}`);
      if(res.data.Status == false){
        Swal.fire({  
          title: 'Warning',  
          text: res.data.Message,  
          icon: 'warning',   
          confirmButtonColor: '#3085d6',  
          confirmButtonText: 'Ok',
          timer: 1500  
        });
      }else{
        dispatch(MsgCountSuccess(res.data));
        // console.log(res.data)
      }
  
    } catch (e) {
      return console.error(e.message);
    }
  }