import React, { useState, useEffect, useRef } from "react";
import api from "../../Api";
import ReplayIcon from '@mui/icons-material/Replay';
import Matches from "./components/matchs";
import { useHistory } from "react-router-dom";

const Left = () => {
    const componentMounted = useRef(true);
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const [userData, setUserData] = useState({})
    const [userFollow, setUserFollow] = useState({});
    const history = useHistory();

    useEffect(() => {
        if (componentMounted.current) {
            getProfile();
        }
        return () => {
            componentMounted.current = false;
        }
    }, []);

    const getProfile = () => {
        let body = {
            id: currentUser.id
        } 
        api.post('user/Profile', body).then(data=>{
           if(data.data){
            setUserData(data.data);
           }
        }).catch(err=>{
            console.log(err)
        });
        api.get(`/user/subscription/total/${currentUser.id}`).then(data=>{
            setUserFollow(data.data);
        }).catch(err=>{
            console.log(err)
        });
    }

    const gotoProfileEdits = () => {
        history.push('/User/profile')
    }

    const reloadProfile = () => {
        setUserData(null)
            getProfile();
    }

    const gotoFollowing = () =>{
        history.push('/following')
       
    }

    const gotoFollowers = () =>{
        history.push('/follower')
    }

    return (
        <>
            {userData == null ?<div className="pre-loader">
                <div className="content-left">
                    <div className="profile-box">
                        <div className="profile-content">
                            <a className="image-section">
                                <div className="profile-img">
                                    <div className="bg-loader" />
                                </div>
                            </a>
                            <div className="profile-detail">
                                <h2 />
                                <div className="description">
                                    <p />
                                </div>
                                <div className="counter-stats">
                                    <span />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>:
             <div className="content-left">
             <div className="profile-box">
                 <div className="profile-setting">
                     <div className="setting-btn refresh">
                         <a onClick={reloadProfile} className="d-flex">
                             <ReplayIcon className='uiicons' />
                         </a>
                     </div>
                 </div>
                 <div className="profile-content">
                     <a className="image-section">
                         <div className="profile-img">
                             <div>
                                 <img src={userData?.s3url}
                                    id="sologo" onError={(event) => event.target.removeAttribute('src')}
                                     onError={(event) => event.target.src = '../assets/images/avtar-image.jpg'} className="img-fluidsProfile blur-up lazyload bg-img" alt="profile" />
                             </div>
                             <span className="stats">
                                 <img src="../assets/images/icon/verified.png" className="img-fluid blur-up lazyload" alt="verified" />
                             </span>
                         </div>
                     </a>
                     <div className="profile-detail">
                         <a onClick={gotoProfileEdits}>
                             <h2>{userData?.firstname} {userData?.lastname} <span>❤</span></h2>
                         </a>
                         <div className="description">
                             <p className='blogDes'>{userData?.bio}</p>
                         </div>
                         <div className="counter-stats">
                                        <ul id="counter">
                                            <li style={{cursor:'pointer'}}  onClick={()=>gotoFollowing()}>
                                                <h3 className="counter-value" data-count="546">{userFollow.totalFollowing}</h3>
                                                <h5>following</h5>
                                            </li>
                                            <li style={{cursor:'pointer'}} onClick={()=>gotoFollowers()}>
                                                <h3 className="counter-value" data-count="6845" style={{cursor:'pointer'}}>{userFollow.totalfollower}</h3>
                                                <h5 style={{cursor:'pointer'}}>followers</h5>
                                            </li>
                                        </ul>
                                    </div>
                         <a onClick={gotoProfileEdits} className="btn btn-solid">view profile</a>
                     </div>
                 </div>
             </div>
            
            

         </div>}
         <Matches />

           
        </>)

}

export default Left