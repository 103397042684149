import React from "react";
import FriendSuggestion from "../right/components/FriendSuggestion";

const Right = () => {
    return (
        <>
            <FriendSuggestion />
            <div className="content-right pt-4">
                <div className="sticky-top">
                    {/* event */}
                    <div className="event-box ratio2_3">
                        <div className="image-section">
                            <img src="../assets/images/event/1.jpg" className="img-fluid blur-up lazyload bg-img" alt="event" />
                            <div className="card-title">
                                <h3>event</h3>
                                <div className="settings">
                                    <div className="setting-btn">
                                        <a href="#" className="d-flex">
                                            <i className="icon icon-dark stroke-width-3 iw-11 ih-11" data-feather="rotate-cw" />
                                        </a>
                                    </div>
                                    <div className="setting-btn ms-2 setting-dropdown">
                                        <div className="btn-group custom-dropdown arrow-none dropdown-sm">
                                            <a href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="icon-dark stroke-width-3 icon iw-11 ih-11" data-feather="sun" />
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right custom-dropdown">
                                                <ul>
                                                    <li>
                                                        <a href="#"><i className="icon-font-light iw-16 ih-16" data-feather="edit" />remind
                                                            me</a>
                                                    </li>
                                                    <li>
                                                        <a href="#"><i className="icon-font-light iw-16 ih-16" data-feather="settings" />setting</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="play-btn" data-bs-toggle="modal" data-bs-target="#videoPlayer">
                                <img src="../assets/images/icon/play.png" className="img-fluid blur-up lazyload" alt="play" />
                            </div>
                        </div>
                        <div className="event-content">
                            <h3>christmas 2021</h3>
                            <h6>26 january 2021</h6>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry
                                <span>15256 People Going</span></p>
                            <div className="bottom-part">
                                <a href="#" className="event-btn">going / not going</a>
                            </div>
                            <a href="#" className="share-btn">
                                <i className="icon-dark stroke-width-3 iw-14 ih-14" data-feather="corner-up-right" />
                            </a>
                        </div>
                    </div>

                </div>
            </div>
            {/*
            <div className="pt-4">
                <div className="sticky-top privacy_manu" style={{ display: 'flex' }}>
                    <ListItemButton>
                        <ListItemText primary="Privacy" />
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemText primary="Terms of Service"/>
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemText primary="Cookie Notice" />
                    </ListItemButton>
                </div>
            </div>*/}

        </>)

}

export default Right
