import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useFormik } from 'formik';

function PrivacyAndSafty() {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [checked, setChecked] = React.useState([0,1, ]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    }
    return (
        <>
            <div className='padd'>
                <Box sx={{ minWidth: 275 }} className='mt-2 '>
                    <Card variant="outlined" className="padds">
                        <div>
                            <h2>PRIVACY AND SAFETY</h2>
                        </div><hr />
                        <div>
                            <div className='card-titles mb-3'>
                                <h3 className='mb-2'>Profile</h3>
                            </div><hr />
                            <List dense sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper' }}>
                                {['Show activity status', 'Show subscription offers'].map((value) => {
                                    return (
                                        <ListItem key={value}
                                            secondaryAction={ <Checkbox edge="end" onChange={handleToggle(value)}/> }
                                            disablePadding
                                            checked={checked.indexOf(value) !== 1}>

                                            <ListItemButton>
                                                <ListItemText  primary={value} />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </div>


                    </Card>
                </Box>
            </div>
        </>
    );
}

export default PrivacyAndSafty;