import React, { useState, useRef, useEffect } from "react";
import PostLikes from "./likes/likes";
import PostComment from "./likes/comments";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Dropdown from 'react-bootstrap/Dropdown';
import { ReactVideo } from "reactjs-media";
import { useHistory } from "react-router-dom";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PostsUpdate from './updatePost';
import api from "../../Api";
import socket from "../../utils/socketNotification";

const Post = () => {
    const componentMounted = useRef(true);
    const history = useHistory();
    const [postData, setPostData] = useState([]);
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const [commentPanel, setCommentPanel] = useState([false]);
    const [animation, setAnimation] = useState(true);
    const [clas, setClas] = React.useState([false]);
    const [update, setUpdate] = React.useState(false);
    const [updatedata, setUpdatedata] = useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        if (componentMounted.current) {
            getPost();
            socket.on('getNotification', (res) => {
                console.log('workings')
                getPost();
            });
        }
        return () => {
            componentMounted.current = false;
        }
    }, []);


    const getPost = () => {
        api.get(`/get-user-post/${currentUser.id}/${currentUser.id}`).then(res => {
            if (res.data.Status != false) {
                setPostData(res.data);
                console.log(res.data)
                setAnimation(false)
            }
        })
    }

    const commentsPost = (id) => {
        let dataToggle = {}
        if (!commentPanel[id]) {
            dataToggle[id] = true
            setCommentPanel(dataToggle);
        } else {
            dataToggle[id] = false
            setCommentPanel(dataToggle);
        }
    }

    const contantToggle = (id) => {
        let ClassToggle = {}
        if (!clas[id]) {
            ClassToggle[id] = true
            setClas(ClassToggle);
        } else {
            ClassToggle[id] = false
            setClas(ClassToggle);
        }
    }

    const lightBoxHandlerBar = (data) => {
        history.push(`/media/${data}`);
    }

    const handleCallback = (childData) => {
        getPost();
    }

    const updatePosts = (ID) => {
        api.get(`/get-single-post/${ID}`).then(res => {
            if (res.data.Status != false) {
                res.data.forEach(element => {
                    setUpdatedata(element)
                });
                setUpdate(true)
            }
        }).catch(err => {
            console.log('err', err)
        })

    }
    const PostsDelete = (ID) => {
        api.delete(`/post-delete/${ID}`).then(res => {
            getPost();
        }).catch(err => {
            console.log('err', err)
        })

    }


    return (
        <>
            {animation ? <div className="pre-loader">
                <div className="content-left">
                    <div className="suggestion-box section-t-space">
                        <div className="suggestion-content ratio_115">
                            <div>
                                <div className="overlay-bg" />
                                <div className="post-panel section-t-space">
                                    <div className="post-wrapper col-grid-box">
                                        <div className="post-title">
                                            <div className="profile">
                                                <div className="media">
                                                    <div className="user-img">
                                                    </div>
                                                    <div className="media-body">
                                                        <h5 />
                                                        <h6 />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="post-details ratio2_1">
                                            <div className="img-wrapper bg-size">
                                            </div>
                                            <div className="detail-box">
                                                <div className="ldr-p">
                                                    <p />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-wrapper col-grid-box section-t-space">
                                        <div className="post-title">
                                            <div className="profile">
                                                <div className="media">
                                                    <div className="user-img">
                                                    </div>
                                                    <div className="media-body">
                                                        <h5 />
                                                        <h6 />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="post-details ratio2_1">
                                            <div className="img-wrapper bg-size">
                                            </div>
                                            <div className="detail-box">
                                                <div className="ldr-p">
                                                    <p />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> :

                <div className="content-center">
                    <div className="overlay-bg" />
                    <div className="post-panel  section-t-space">
                        <div className="post-panel  section-t-space">
                            {update ? <PostsUpdate className='mt-1' prop={updatedata} parentCallback={handleCallback} /> : null}
                            {postData.map((data, i) => <div className="post-wrapper col-grid-box" key={i}>
                                <div className="post-title">
                                    <div className="profile">
                                        <div className="media">
                                            <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right" data-name="sufiya eliza" data-img="../assets/images/user-sm/1.jpg">
                                                <Stack direction="row" spacing={0}>
                                                    <Avatar style={{ width: '50px', height: '50px' }} className="img-fluid-post blur-up lazyload bg-img imgrad" alt={data?.firstname} src={data?.s3profileurl} />
                                                </Stack>
                                            </a>
                                            <div className="media-body">
                                                <a style={{ textAlign: 'left' }}>
                                                    <h5 style={{ marginBottom: '4px', color: '#0389c9', fontSize: '14px' }} >{data?.firstname} {data?.lastname}</h5>
                                                    <h6 style={{ fontSize: '10px', }}>{moment(data?.created_at).format('lll')}</h6>
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Dropdown className='veticalIcon' style={{ zIndex: '18', position: "absolute" }}>
                                    <Dropdown.Toggle style={{ outline: 'none', background: 'none', border: 'none', padding: '0px' }}>
                                        <MoreVertIcon style={{ color: '#647589', paddingLeft: '5px' }} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ padding: '15px' }}>
                                        <ul>
                                            <li style={{ borderBottom: 'solid #6475898a 1px' }} className="pb-1" onClick={() => updatePosts(data.ID)}>
                                                <a style={{ color: '#6475898a', fontSize: ' calc(12px + (12 - 11) * ((100vw - 320px) / (1920 - 320)))' }}>
                                                <svg aria-hidden="true" width={15} height={15} focusable="false" data-prefix="fas" data-icon="edit" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-edit fa-w-18 fa-2x"><path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z" class=""></path></svg>
                                                 &nbsp;Edit</a>
                                            </li>
                                            <li className="pt-1" onClick={() => PostsDelete(data.ID)}>
                                                <a style={{ color: '#6475898a', fontSize: ' calc(12px + (12 - 11) * ((100vw - 320px) / (1920 - 320)))' }}>
                                                <svg aria-hidden="true"  width={15} height={15} focusable="false" data-prefix="fas" data-icon="trash-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-trash-alt fa-w-14 fa-2x"><path fill="currentColor" d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z" class=""></path></svg>
                                                    &nbsp;Delete</a>
                                            </li>
                                        </ul>

                                    </Dropdown.Menu>
                                </Dropdown>
                                <div className="post-details">
                                    <div className="detail-box">
                                        {clas[data.ID] ? <p style={{ fontSize: '14px' }}>{data?.content} </p> : <p className='blogDes' style={{ fontSize: '14px' }}>{data?.content} </p>}
                                        {data?.content.match(/is/g) || [].length == 20 ? <p onClick={() => contantToggle(data.ID)} style={{ fontSize: '10px', textAlign: 'right', marginTop: '0px', cursor: 'grab', color: '#0389c9' }}>{clas[data.ID] ? <span>Less...</span> : <span>More...</span>}</p> : null}
                                    </div>
                                </div>
                                <div className="img-wrapper">
                                    <div className="gallery-section">
                                        <div className="portfolio-section ratio_square">
                                            {data?.post_for_free != "paid" && data?.post_pay_status != 1 || data?.user_id == currentUser.id ? <div className="container-fluid p-0">
                                                {data.media_data.length == 1 ? <div className="row">
                                                    {data.media_data.map((media, i) =>
                                                        <div className="col-12 m-0">
                                                            <div className="overlay">
                                                                <div className="portfolio-image">
                                                                    {media?.media_type == 'image' ?
                                                                        <div className='imagecontainer1'>
                                                                            <img key={i + 1} src={media?.s3postmediaurl} style={{ width: '100%', }} className="img-fluid blur-up lazyload" alt='true'
                                                                                onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                onError={(event) => event.target.removeAttribute('src')}
                                                                                onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                            />
                                                                        </div>
                                                                        : null}

                                                                    {media?.media_type == 'video' ?
                                                                        <div className='videocontainer2'>
                                                                            <ReactVideo
                                                                                //    config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                                                                poster='../assets/images/icon/logo.png'
                                                                                src={media?.s3postmediaurl}
                                                                                width='100%'
                                                                                height='250px !important'
                                                                                controls={false}
                                                                            />
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                </div> : null}

                                                {data.media_data.length == 2 ? <div className="row">
                                                    {data.media_data.map((media, i) =>
                                                        <div className="col-6 m-0">
                                                            <div className="overlay">
                                                                <div className="portfolio-image">
                                                                    {media?.media_type == 'image' ?
                                                                        <div className='imagecontainer2'>
                                                                            <img src={media?.s3postmediaurl} className="containerImage2" alt='true'
                                                                                onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                onError={(event) => event.target.removeAttribute('src')}
                                                                                onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                            />
                                                                        </div>
                                                                        : null}

                                                                    {media?.media_type == 'video' ?
                                                                        <div className='videocontainer2'>
                                                                            <ReactVideo
                                                                                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                                                                poster='../assets/images/icon/logo.png'
                                                                                src={media?.s3postmediaurl}
                                                                                width='100%'
                                                                                height='250px !important'
                                                                                controls />
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                </div> : null}
                                                {data.media_data.length == 3 ? <div className="row">
                                                    {data.media_data.map((media, i) =>
                                                        i <= 1 ? <div className="col-6 m-0">
                                                            <div className="overlay">
                                                                <div className="portfolio-image">
                                                                    {media?.media_type == 'image' ?
                                                                        <div className='imagecontainer3'>
                                                                            <img src={media?.s3postmediaurl} className="containerImage3" style={{ width: '100%', objectFit: 'cover' }}
                                                                                onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                onError={(event) => event.target.removeAttribute('src')}
                                                                                onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                            />
                                                                        </div>
                                                                        : null}

                                                                    {media?.media_type == 'video' ?
                                                                        <div className='videocontainer2 mb-1'>
                                                                            <ReactVideo
                                                                                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                                                                poster='../assets/images/icon/logo.png'
                                                                                src={media?.s3postmediaurl}
                                                                                width='100%'
                                                                                height='250px !important'
                                                                                controls />
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div> : <div className="col-12 m-0">
                                                            <div className="overlay">
                                                                <div className="portfolio-image">
                                                                    {media?.media_type == 'image' ?
                                                                        <div className='imagecontainer3'>
                                                                            <img src={media?.s3postmediaurl} style={{ width: '100%', objectFit: 'cover' }} className="containerImage3" alt='true'
                                                                                onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                onError={(event) => event.target.removeAttribute('src')}
                                                                                onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                            />
                                                                        </div>
                                                                        : null}

                                                                    {media?.media_type == 'video' ?
                                                                        <div className='videocontainer2 mb-1'>
                                                                            <ReactVideo
                                                                                poster='../assets/images/icon/logo.png'
                                                                                src={media?.s3postmediaurl}
                                                                                width='100%'
                                                                                height='250px !important'
                                                                                controls playsinline />
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                </div> : null}

                                                {data.media_data.length == 4 ? <div className="row">
                                                    {data.media_data.map((media, i) =>
                                                        <div className="col-6 m-0">
                                                            <div className="overlay">
                                                                <div className="portfolio-image">
                                                                    {media?.media_type == 'image' ?
                                                                        <div className='imagecontainer3'>
                                                                            <img src={media?.s3postmediaurl} style={{ width: '100%', objectFit: 'cover' }} className="containerImage3" alt='true'
                                                                                onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                onError={(event) => event.target.removeAttribute('src')}
                                                                                onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                            />
                                                                        </div>
                                                                        : null}

                                                                    {media?.media_type == 'video' ?
                                                                        <div className='videocontainer2 mb-1'>
                                                                            <ReactVideo
                                                                                poster='../assets/images/icon/logo.png'
                                                                                src={media?.s3postmediaurl}
                                                                                width='100%'
                                                                                height='250px !important'
                                                                                controls playsinline />
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                </div> : null}

                                                {data.media_data.length > 4 ? <div className="row">
                                                    {data.media_data.map((media, i) =>
                                                        i <= 2 ? <div className="col-6 m-0">
                                                            <div className="overlay">
                                                                <div className="portfolio-image">
                                                                    {media?.media_type == 'image' ?
                                                                        <div className='imagecontainer3'>
                                                                            <img src={media?.s3postmediaurl} style={{ width: '100%', objectFit: 'cover' }} className="containerImage3" alt='true'
                                                                                onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                onError={(event) => event.target.removeAttribute('src')}
                                                                                onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                            />
                                                                        </div>
                                                                        : null}

                                                                    {media?.media_type == 'video' ?
                                                                        <div className='videocontainer2 mb-1'>
                                                                            <ReactVideo
                                                                                poster='../assets/images/icon/logo.png'
                                                                                src={media?.s3postmediaurl}
                                                                                width='100%'
                                                                                height='250px !important'
                                                                                controls playsinline />
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>

                                                            :
                                                            <div className="col-6 m-0">
                                                                {i == 3 ? <div>
                                                                    <div className='imagecontainer3'>
                                                                        {media?.media_type == 'video' ?
                                                                            <div className='videocontainer2 mb-1'>
                                                                                <ReactVideo
                                                                                    poster='../assets/images/icon/logo.png'
                                                                                    src={media?.s3postmediaurl}
                                                                                    width='100%'
                                                                                    height='250px !important'
                                                                                    controls playsinline />
                                                                            </div>
                                                                            : <div className="portfolio-image">
                                                                                <img src={media?.s3postmediaurl} alt='true' className="containerImage3" />
                                                                            </div>}

                                                                    </div>
                                                                    {i == 4 ?
                                                                        <div className="imagecontainer3" style={{ position: 'absolute', top: '0px', }}>
                                                                            <div className='portfolio-image'>
                                                                                <a data-bs-toggle="modal" data-bs-target="#imageModel" >
                                                                                    {media?.media_type == 'video' ?
                                                                                        <div className='videocontainer2 mb-1'>
                                                                                            <ReactVideo
                                                                                                poster='../assets/images/icon/logo.png'
                                                                                                src={media?.s3postmediaurl}
                                                                                                width='100%'
                                                                                                height='250px !important'
                                                                                                controls playsinline />
                                                                                        </div>
                                                                                        : <img src={media?.s3postmediaurl} alt='true' className="containerImage3" />}

                                                                                </a>
                                                                            </div>
                                                                        </div> : <div className="imagecontainer3" style={{ position: 'absolute', top: '0px', }}>
                                                                            <div className="portfolio-image">
                                                                                <a data-bs-toggle="modal" data-bs-target="#imageModel" >
                                                                                    <h1 className='imageCount'>{data.media_data.length - 4}+</h1>
                                                                                    {media?.media_type == 'video' ?
                                                                                        <div className='videocontainer2 mb-1'>
                                                                                            <ReactVideo
                                                                                                poster='../assets/images/icon/logo.png'
                                                                                                src={media?.s3postmediaurl}
                                                                                                width='100%'
                                                                                                height='250px !important'
                                                                                                controls playsinline />
                                                                                        </div>
                                                                                        : <img src={media?.s3postmediaurl} alt='true' className="containerImage3" style={{ filter: 'brightness(0.5)' }} />}


                                                                                </a>
                                                                            </div>
                                                                        </div>}
                                                                </div> : null}
                                                            </div>
                                                    )}

                                                    <div>

                                                    </div>
                                                </div> : null}

                                                <div className="post-react">
                                                    <ul style={{ justifyContent: 'unset' }}>
                                                        <li className="comment-click">
                                                            <PostLikes props={data} parentCallback={handleCallback} />
                                                        </li>
                                                        <li style={{ position: 'absolute', right: '20px' }}>
                                                            <a onClick={() => commentsPost(data.ID)}><ChatBubbleOutlineIcon style={{ fontSize: '16px' }} /><span>{data?.total_comments}</span></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {commentPanel[data.ID] ? <PostComment props={data} parentCallback={handleCallback} /> : null}
                                            </div> :
                                                <div></div>}
                                        </div>
                                    </div>
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>}
        </>)

}

export default Post
