import React, { useState, useEffect, useRef } from "react";
import ReplayIcon from '@mui/icons-material/Replay';
import { useHistory } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import api from "../../../Api";
import Loader from "react-loader-spinner";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import AddTaskIcon from '@mui/icons-material/AddTask';
import socket from "../../../utils/socketNotification";
import PaymentDetails from "../../../AddCards/patmentpage";

const FansSuggestion = () => {
    const componentMounted = useRef(true);
    const [userSeggession, setUserSeggession] = useState([]);
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const [selectUsers, setSelectUsers] = useState({});
    const [subPaid, setSubPaid] = useState();
    const history = useHistory();
    

    useEffect(() => {
    if (componentMounted.current) {
        getSeggestion(currentUser.id);
    }
    return () => {
        componentMounted.current = false;
    }
    }, []);

    const getSeggestion = (id) => {
        api.get(`get-profile-matcheas/${id}`).then(data => {
            if(data.data){
            setUserSeggession(data.data);
            }
        });
    }

    const gotoDataUser = (ID) => {
        history.push(`/subscriptions/${ID}`);
    }

    const gotoBack = () =>{
        window.history.go(-1)
    }

    const refresh = () => {
        setUserSeggession([])
        getSeggestion(currentUser.id);
    }

    const handleCallback = (childData) => {
        console.log(childData)
        setSubPaid(false);
        if(childData.Status === true){
         let body = {
             "user_id": currentUser.id,
             "to_user_id": selectUsers.id,
             "payment_status": childData.id,
             "join_date": new Date
         }
         api.post('/user/subscription/insert', body).then(data => {
            getSeggestion(currentUser.id);
             let body = {
                 type: 'subscribed',
                 media_id: selectUsers.id,
                 user_id: currentUser.id,
                 data: 'has subscribed you.',
                 to_user_id: selectUsers.id,
                 notification_type: 'subscription',
                 date_time: new Date
             }
             socket.emit('sendNotification', body);
         })
        }
        
     }

    const SubscribeProfile = (paid, ids, datas) => {
        if(paid != ''){
           let d = {data:paid , id:ids, pay_on_type:'profile', payment_type:'subscribe', to_user_id:ids}
            setSelectUsers(d)
            setTimeout(() => {
                setSubPaid(true); 
            }, 1500);
        }else{
        let body = {
            "user_id": currentUser.id,
            "to_user_id": ids,
            "payment_status": paid,
            "join_date": new Date
        }
        api.post('/user/subscription/insert', body).then(data => {
            getSeggestion(currentUser.id);
            let body = {
                type: 'subscribed',
                media_id: ids,
                user_id: currentUser.id,
                data: 'has subscribed you.',
                to_user_id: ids,
                notification_type: 'subscription',
                date_time: new Date
            }
            socket.emit('sendNotification', body);
        })
    }
    }

    return (
        <>
            <div className="suggestion-box d-flex flex-wrap">
                <div className="card-title">
                    <h3>
                    <svg  onClick={()=>gotoBack()} width={15} height={10} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-left fa-w-14 fa-2x"><path fill="currentColor" d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" class=""></path>
                    </svg>
                         Fans you can follow</h3>
                    <div className="settings">
                        <div className="setting-btn">
                            <a className="d-flex" onClick={refresh}>
                                <ReplayIcon className='uiicons' />
                            </a>
                        </div>
                    </div>
                </div>
                
                {userSeggession.map((data, i) => <>
                    <div className="col-sm-12 col-xs-12 col-md-6 fanssuggestion"key={i}>
                        <div  className="suggestion-content ratio_115" style={{ padding: '2px !important' }}>
                            <div className="slide-2 no-arrow default-space" >
                                <div className="story-box" style={{ width: '100%', height: '130px' }}>
                                    <div className="story-bg" >
                                        <img style={{ borderRadius: '10px', width: '100%', height: '100%', maxHeight: '130px', objectFit: 'cover' }} src={data?.s3coverurl}
                                            onError={(event) => event.target.removeAttribute('src')}
                                            onError={(event) => event.target.src = '../assets/images/avtar-image.jpg'}
                                            className="img-fluid bg-img" data-adaptive-background={1} alt='true' />
                                    </div>

                                    <div className="profile-bg">
                                        <Stack direction="row" onClick={() => gotoDataUser(data.ID)} spacing={2} style={{ position: 'absolute', bottom: '10px', left: '18px', border: 'solid white 2px', borderRadius: '50px',zIndex:'10', backgroundColor: 'gray' }}>
                                            <Avatar className="img-fluid-post blur-up lazyload bg-img imgrad" alt={data?.firstname} src={data?.s3url} />
                                        </Stack>
                                        <div className="story-content" >   <span>
                                            <h6 onClick={() => gotoDataUser(data.ID)} style={{ fontSize: "16px", fontWeight: 'bold', marginLeft: '30%' }}>{data?.firstname} {data?.lastname}</h6>
                                            <p style={{ textAlign:'center',fontSize: "12px", marginBottom:'0px' }}>@{data?.user_name} </p> 
                                            <p style={{ textAlign:'center',fontSize: "12px", marginBottom:'0px' }}>Interest match {data?.match_percentage.toFixed()} % </p> 
                                        </span>
                                        
                                        </div>
                                       
                                    </div>
                                    <div style={{ padding: '5px 20px', }}>
                                    <p className="subButton" onClick={()=>SubscribeProfile(data?.paid, data.ID, data)}>
                                               {data?.subscribe_status != 'Subscribied' ?<PersonAddAltIcon style={{color:'#fff',cursor: "pointer"}}/>:
                                               <AddTaskIcon   style={{color:'#fff',cursor: "pointer"}}/>}
                                             
                                    </p>
                                           
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </>
                )}
                {subPaid? <PaymentDetails props={selectUsers} parentCallback={handleCallback} />:null}
                {userSeggession.length == 0 ?
                   <div style={{textAlign:'center', width:'100%'}} className='mt-4 mb-4'>
                   <Loader type="Bars" color="#3e89c9" height={30} width={30}/>
                </div> : null}
            </div>
        </>
    );
}

export default FansSuggestion
