import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useRef } from "react";
import api from "../Api";
import Loader from "react-loader-spinner";
import moment from "moment";
function Notification() {
    const componentMounted = useRef(true);
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    var img = 'https://api.matchwithfans.com/upload/profile/';
    const [notification, setNotification] = useState([]);
    const [readNotify, setReadNotify] = useState({background:'#e5e5e5'});
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(false);

    const handleClick = () => {
        console.info('You clicked the Chip.');
    };
    useEffect(() => {
        if (componentMounted.current) {
        setLoading(true)
        api.get(`/get-user-notification/${currentUser.id}`).then(res=>{
            if(res.data.length != 0){
                console.log(res.data)
                setNotification(res.data)
            setLoading(false)
            }else{
                setLoading(false);
                setData(true); 
            }
        });
    }
    return () => {
        componentMounted.current = false;
    }
    }, []);

    const gotoPagesNotify = (data, type, id, user_id) =>{
        api.get(`/notifiction-seen-status-update/${data}`).then(res=>{
           if(type == 'post'){
            history.push(`/users-notifcation/${id}`)
           }
           else if(type == 'subscribed'){
            history.push(`/subscriptions/${user_id}`)  
           }
            api.get(`/get-user-notification/${currentUser.id}`).then(res=>{
                setNotification(res.data)
            });
        });
    }

    const gotoBack = () => {
        window.history.go(-1)
    }

    return (
        <>
            <Box sx={{ minWidth: '100%' }} >
                <Card variant="outlined" className="padds">
                    <div>
                    <h2><ArrowBackIcon onClick={() => gotoBack()} fontSize="inherit" /> NOTIFICATIONS </h2>
                    </div><hr />
                    {/* <div>
                        <Stack direction="row" spacing={1}>
                            <Chip label="All" onClick={handleClick} style={{ background: 'blue', color: 'white' }} />
                            <Chip label="Comments" onClick={handleClick} />
                            <Chip label="Mentions" onClick={handleClick} />
                            <Chip label="Subscriptions" onClick={handleClick} />
                            <Chip label="Promotions" onClick={handleClick} />
                        </Stack>
                    </div> */}
                    {loading? <div style={{textAlign:'center'}} className='mt-4 mb-4'>
                       <Loader type="Bars" color="#00BFFF" height={30} width={30} />
                    </div>
                    :
                    <List sx={{ width: '100%', maxWidth: '100%',height:'100%', bgcolor: 'background.paper' }} >
                        {notification.map((data, i) =><ListItem onClick={()=>gotoPagesNotify(data?.ID, data?.type, data?.media_id, data?.user_id)} alignItems="flex-start" style={data?.seen_status == 0 ? readNotify:null}>
                            <ListItemAvatar>
                                <Avatar alt={data?.firstname} src={data?.s3profileurl} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={data?.firstname}
                                secondary={
                                    <React.Fragment>
                                        <Typography
                                            sx={{ display: 'inline' }}
                                            component="span"
                                            variant="body2"
                                            color="text.primary"
                                        >
                                        </Typography>{data?.data}
                                    </React.Fragment>
                                }
                            />
                            <h6 style={{fontSize:'10px'}}>{moment(data?.date_time).calendar()}</h6>
                        </ListItem>)}
                        <Divider variant="inset" component="li" />
                      
                    </List>}

                    {data?<div className="card-titles mt-4 mb-4" >
                        <h3 style={{ textAlign: 'center' }}>No notifications currently!</h3>
                    </div>:null}
                </Card>
            </Box>

        </>
    );
}

export default Notification;