import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux';
import  user   from "./user";
import userSignUp from "./userSignUp";
import forgotPassword from "./forgotPassword";
import verifyEmail from "./verifyEmail";

import profile from "./Accounts/profile";
import editProfile from "./Accounts/editProfile";
import profileImage from "./Accounts/profileImage";
import restPass from "./Accounts/restPass";
import coverImage from "./Accounts/coverImage";
import whatsNew from "./Accounts/whatsNew";
import CardsDetails from "./Accounts/CardsDetails";
import loginsession from "./Accounts/loginsession";
import Allusers from "./Accounts/Allusers";

import post from "./post/post";
import postInsert from "./post/postInsert";
import currentUserPost from "./post/currentUserPost";
import postLike from "./post/postLike";
import postComment from "./post/postComment";

import seggestion from "./seggestion/seggestion";

import inbox from "./message/inbox";
import userprofile from "./message/userprofile";
import message from "./message/message";
import sendMsg from "./message/sendMsg";
import magSeen from "./message/magSeen";


const reducer = combineReducers({
    user, userSignUp,forgotPassword, verifyEmail, profile, editProfile, profileImage, restPass,
    coverImage, CardsDetails, whatsNew, loginsession, post, postInsert, currentUserPost,
    seggestion, inbox, userprofile, message, sendMsg, Allusers, postLike, postComment, magSeen, 
});

const store = configureStore({
  reducer,
});
export default store;