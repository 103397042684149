import * as React from 'react';
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import "react-lightbox-pack/dist/index.css";
import api from "../Api";
import moment from 'moment';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import PostComment from "../dasboard/center/post/likes/comments";
import ImageGallery from 'react-image-gallery';
import Loader from "react-loader-spinner";
import FavoriteIcon from '@mui/icons-material/Favorite';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: 500,
    height: 450,
  },
}));


function ColargeBox() {
  const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
  const ids = useParams().path;
  const [toggle, setToggle] = React.useState(false);
  const [sIndex, setSIndex] = React.useState(0);
  const [userData, setUserData] = useState([]);
  const [data, setData] = useState([]);
  var userMedia = '';
  var img = 'https://api.matchwithfans.com/upload/post/';
  const classes = useStyles();

  // Handler
  const lightBoxHandler = (state, sIndex) => {
    setToggle(state);
    setSIndex(sIndex);
  };

  useEffect(() => {
    getpostdata(ids)
  }, [0]);

  const getpostdata = (id) => {
    console.log(`${id}/${currentUser.id}`)
    api.get(`/get-user-single-post/${id}/${currentUser.id}`).then(data => {
      console.log(data)
      data.data.forEach(element => {
        element.id = element.ID;
        userMedia = element.media_data;
      });
      var arr = [];
      userMedia.forEach(element => {
        if (element.media_type == 'image') {
          let body = {
            original:element.s3postmediaurl,
            // thumbnail : img + element.media
          }
          arr.push(body)
        }

      });
      setData(arr);
      setUserData(data.data)
      console.log(data.data)
    })

  }
  const gotoBack = () => {
    window.history.go(-1)
  }
  const selectpostLoke = (ID) => {
    let data = {
      "user_id": currentUser.id,
      "post_id": ID,
    }
    api.post('/post/like', data).then(res=>{
      getpostdata(ids)
    }).catch(err=>{
      console.log('err', err)
    })
  }

  const handleCallback = (childData) =>{
}
  return (
    <div 
    style={{padding:'5px'}}>
      <div className='row'>
        <div className='col-md-8'>
          {/* <IconButton size="small"  aria-label="back"> */}
            <CloseIcon onClick={gotoBack} style={{ position: 'absolute', top: '2px',background:'#3e89c9', left: '14px', zIndex: '99', color: 'white' }} />
          {/* </IconButton> */}
          <div>
          {data.length != 0 ? <div >
              {data.length == 0 ? <div style={{ textAlign: 'center' }}>
                <Loader type="ThreeDots" color="#00BFFF" height={100} width={100} />
              </div> :
                <ImageGallery showPlayButton='false' showFullscreenButton='false' items={data} />}
            </div>:null}
          </div>
        </div>
        <div className='col-md-4 bg-light card'>
          <div className="post-title paddingmodile mt-1">
            {userData.map((data, i) => <div className="profile">
              <div className="media" key={i}>
                <a className="popover-cls user-img" >
                  <Stack direction="row" spacing={2} >
                    <Avatar style={{width:'50px', height:'50px'}} className="imgrad" alt={data?.firstname} src={data?.s3profileurl} />
                  </Stack>
                </a>
                <div className="media-body">
                  <a style={{ textAlign: 'left', }}>
                    <h5 style={{ marginBottom: '0px', fontSize: '16px', paddingTop: '0.5em' }} >{data?.firstname} {data?.lastname}</h5>
                    <h6 style={{ fontSize: '10px', }}>{moment(data?.created_at).format('lll')}</h6>
                  </a>
                </div><br />
              </div>
              <div className="post-details">
                <div className="detail-box">
                  <p style={{ fontSize: '12px', marginTop: '5px' }}>{data?.content} </p>
                </div>
              </div><hr />
              <div className="portfolio-section ratio_square">
                <div className="like-panel">
                <div className="left-emoji">
                    <ul className='likeul'>
                      <li>
                      <p> 
                      {data.Like  != 0?<span><FavoriteIcon style={{color:'red', fontSize:'13px'}} onClick={()=>selectpostLoke(data.ID)}/> {data?.totalLike} Likes</span>:
                           <span><FavoriteBorderIcon  onClick={()=>selectpostLoke(data.ID)}  style={{ fontSize:'13px', color:'red'}}/> {data?.totalLike} Like</span>}</p>
                      </li>
                    </ul>
                </div><hr/>
            </div>
              </div>
              <PostComment props={data} parentCallback={handleCallback}/>
            </div>)}

          </div>
        </div>
      </div>
    </div>
  );
}

export default ColargeBox;