import * as React from 'react';
import { useEffect, useState } from "react";
import NotificationsIcon from '@mui/icons-material/Notifications';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Dropdown from 'react-bootstrap/Dropdown';
import { useHistory } from "react-router-dom";
import { useRef } from "react";
import api from "../Api";
import moment from "moment";
import socket from "../utils/socketNotification";

function HeaderNotification() {
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const [notificationCount, setNotificationCount] = useState('0');
    const [notification, setNotification] = useState([]);
    const [readNotify, setReadNotify] = useState({ background: '#e5e5e5' });
    const history = useHistory();
    const componentMounted = useRef(true);

    useEffect(() => {
        if (componentMounted.current) {
            socket.on('getNotification', (res) => {
                api.get(`/get-user-notification/${currentUser.id}`).then(res => {
                    if(res.data.Status != false){
                        setNotification(res.data);
                    }
                });
                checkCount();
            });
            api.get(`/get-user-notification/${currentUser.id}`).then(res => {
                if(res.data.Status != false){
                  setNotification(res.data);
                }
            }).catch(err=>{
                console.log(err)
            });
            api.get(`/user/notification/count/${currentUser.id}`).then(res => {
                if(res.data.Status != false){
                    res.data.forEach(element => {
                        setNotificationCount(element.count);  
                    });
                }
                
            });
        }
        return () => {
            setNotification([])
            componentMounted.current = false;
        }

    }, []);

    const checkCount = () => {
        api.get(`/user/notification/count/${currentUser.id}`).then(res => {
                if(res.data.Status != false){
                    res.data.forEach(element => {
                        setNotificationCount(element.count);  
                    });
                }
                
        });
    }

    const goToNotification = () => {
        history.push('/notification')
    }

    const gotoPagesNotify = (data, type, id, user_id) => {
        api.get(`/notifiction-seen-status-update/${data}`).then(res => {
            if (type == 'post') {
                checkCount();
                history.push(`/users-notifcation/${id}`)
            }
            else if (type == 'subscribed') {
                checkCount();
                history.push(`/subscriptions/${user_id}`)
            }
            api.get(`/get-user-notification/${currentUser.id}`).then(res => {
                checkCount();
                setNotification(res.data)
            });
        });
    }

    return (
        <div >
            <Dropdown style={{ zIndex: '1000' }}>
                <Dropdown.Toggle style={{ outline: 'none', background: 'none', border: 'none', padding: '0px' }}>
                    <Stack spacing={1} direction="row" sx={{ color: 'action.active' }}>
                        <Badge color="primary" badgeContent={notificationCount}>
                            <NotificationsIcon style={{ color: 'white' }} />
                        </Badge>
                    </Stack>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <div className="dropdown-header">
                        <span>Notifications</span>
                    </div>

                    {notification.slice(0, 5).map((data, i) => <Dropdown.Item key={i} style={data?.seen_status == 0 ? readNotify : null}>
                        <div className="media" onClick={() => gotoPagesNotify(data?.ID, data?.type, data?.media_id, data?.user_id)}>
                            <Stack direction="row" spacing={2}>
                                <Avatar alt={data?.firstname} src={data?.s3profileurl} />
                            </Stack>
                            <div className="media-body">
                                <div>
                                    <h5 className="mt-0"><span>{data?.firstname} {data?.lastname}</span>
                                    </h5>
                                    <h6> {data?.data}</h6>
                                    <h6 style={{ fontSize: '10px' }} className='pt-1'>{moment(data?.date_time).calendar()}</h6>

                                </div>
                            </div>
                        </div>
                        <Dropdown.Divider style={{ color: '#f5eaea' }} />
                    </Dropdown.Item>)}

                    <Dropdown.Item >
                        <p style={{ textAlign: 'right', paddingRight: '5px', cursor: "grab" }} className="pb-1" onClick={goToNotification}>See All</p>
                    </Dropdown.Item>
                </Dropdown.Menu>

            </Dropdown>
        </div>
    );
}

export default HeaderNotification;