import React, { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { useHistory } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import api from "../Api";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Swal from "sweetalert2";

const validateCards = cardDetails => {
    const errors = {};
    if (!cardDetails.firstname) {
        errors.firstname = '*Please Enter First Name';
    }
    if (!cardDetails.lasttname) {
        errors.lasttname = '*Please Enter Last Name';
    }

    if (!cardDetails.month) {
        errors.month = '*Please Select Month';
    }
    if (!cardDetails.year) {
        errors.year = '*Please Select Year';
    }

    if (!cardDetails.card_number) {
        errors.card_number = '*Please Enter Card Number';
    } else if (cardDetails.card_number.length > 16) {
        errors.card_number = '*Card Number cannot exceed 16 characters';
    }
    if (!cardDetails.cvc_number) {
        errors.cvc_number = '*Please Enter CVC';
    } else if (cardDetails.cvc_number.length > 16) {
        errors.cvc_number = '*CVC cannot exceed 16 characters';
    }

    if (!cardDetails.email) {
        errors.email = '*Please Enter Email';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(cardDetails.email)) {
        errors.email = '*Invalid email address';
    }
    return errors;
};


function PaymentDetails(props) {
    const [country, setCountry] = useState([]);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [loading, setLoading] = useState(false);
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const [open, setOpen] = React.useState(true);
    const [scroll, setScroll] = React.useState('paper');
    const history = useHistory();

    const handleClose = () => {
        props.parentCallback({id:props.props.data, Status:false});
        setOpen(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
        handleClickOpen()
    }, [open]);

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };


    useEffect(() => {
        api.get(`/country`).then(data => {
            setCountry(data.data)
        })
    }, [0]);

    const selectState = (name) => {
        api.get(`/state/${name}`).then(data => {
            setState(data.data)
        })
    }

    const selectCity = (name) => {
        let data = {
            Sname: name
        }
        api.post(`/city`, data).then(data => {
            setCity(data.data)
        })
    }

    const formik = useFormik({
        initialValues: {
            firstname:'',
            lasttname:'',
            user_id: currentUser.id,
            country: '',
            state: '',
            city: '',
            address: '',
            zip_code: '',
            email: '',
            card_number: '',
            month: '',
            year: '',
            cvc_number: ''
        },
        validate: validateCards,
        
        onSubmit: (values) => {
            let body = {
                to_user_id:props.props.to_user_id,
                amount:props.props.data.toString(),
                 pay_on_id:props.props.id,
                "pay_on_type":props.props.pay_on_type,
                "payment_type":props.props.payment_type,
                fname:values.firstname,
                lname:values.lasttname,
                user_id:values.user_id,
                country:values.country,
                state:values.state,
                city:values.city,
                address:values.address,
                zipcode:values.zip_code,
                email:values.email,
                cc:values.card_number,
                expire:values.month+values.year,
                cvv:values.cvc_number,
            }
            setLoading(true);
            api.post(`/profile-payment`, body).then(data => {
                setLoading(false);
                if(data.data.Status === false){
                    Swal.fire({  
                      title: 'Warning',  
                      text: data.data.Message,  
                      icon: 'warning',   
                      confirmButtonColor: '#3085d6',  
                      confirmButtonText: 'Yes!',
                      timer: 1500  
                    });
                    setLoading(false);
                }else{
                    Swal.fire({  
                        title: 'Successs',  
                        text: data.data.Message,  
                        icon: 'success',   
                        confirmButtonColor: '#3085d6',  
                        confirmButtonText: 'Yes!',
                        timer: 1500  
                      }); 
                      props.parentCallback({id:props.props.data, Status:true});
                      setLoading(false);
                      handleClose();
                }
            })
            
        }
    });
    return (
        <>
            <Dialog open={open} scroll={scroll} >
                <DialogTitle id="scroll-dialog-title">Payment <span style={{ float: 'right' }}><CloseIcon onClick={handleClose} /></span></DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
                        <div >
                            <Box >
                                <form>
                                    <div className="row">
                                        <div className='col-md-6'>
                                            <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
                                                <TextField id="standard-basic" label="First Name" variant="standard"
                                                    name="firstname" value={formik.values.firstname}
                                                    onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="First Name " />
                                                {formik.touched.firstname && formik.errors.firstname ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.firstname}</span> : null}
                                            </FormControl>
                                        </div>
                                        <div className='col-md-6'>

                                            <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
                                                <TextField id="standard-basic" label="Last Name" variant="standard"
                                                    name="lasttname" value={formik.values.lasttname}
                                                    onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Last Name" />
                                                 {formik.touched.lasttname && formik.errors.lasttname ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.lasttname}</span> : null}
                                            </FormControl>
                                        </div>

                                        <div className='col-md-6'>
                                            <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
                                                <TextField id="standard-basic" label="Email" variant="standard"
                                                    name="email" value={formik.values.email}
                                                    onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Email Address" />
                                                {formik.touched.email && formik.errors.email ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.email}</span> : null}
                                            </FormControl>
                                        </div>
                                        <div className='col-md-6'>
                                            <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
                                                <InputLabel id="demo-simple-select-standard-label">Country</InputLabel>
                                                <Select label="Country" name="country" value={formik.values.country}
                                                    onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="country">
                                                    <MenuItem value=""><em>None</em> </MenuItem>
                                                    {country.map(data => <MenuItem value={data?.name} onClick={() => selectState(data?.name)}>{data?.name}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </div>

                                        <div className='col-md-6'>
                                            <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
                                                <InputLabel id="demo-simple-select-standard-label">State / Provins</InputLabel>
                                                <Select label="State" name="state" value={formik.values.state}
                                                    onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="State / Provins">
                                                    <MenuItem value=""><em>None</em> </MenuItem>
                                                    {state.map(data => <MenuItem value={data?.name} onClick={() => selectCity(data?.name)}>{data?.name}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className='col-md-6'>
                                            <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
                                                <InputLabel id="demo-simple-select-standard-label">City</InputLabel>
                                                <Select label="city" name="city" value={formik.values.city}
                                                    onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="city">
                                                    <MenuItem value=""><em>None</em> </MenuItem>
                                                    {city.map(data => <MenuItem value={data?.name} >{data?.name}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </div>


                                        <div className='col-md-6'>
                                            <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
                                                <TextField id="standard-basic" label="Address" variant="standard" name="address" value={formik.values.address}
                                                    onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Address" />
                                            </FormControl>
                                        </div>
                                        <div className='col-md-6'>
                                            <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
                                                <TextField id="standard-basic" label="Zip / Postal Code" variant="standard" name="zip_code" value={formik.values.zip_code}
                                                    onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="zip_code" />
                                            </FormControl>
                                        </div>


                                        <div className='col-md-6'>
                                            <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
                                                <TextField id="standard-basic" label="Card Number" variant="standard"
                                                    name="card_number" value={formik.values.card_number}
                                                    onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Card Number" />
                                                {formik.touched.card_number && formik.errors.card_number ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.card_number}</span> : null}
                                            </FormControl>
                                        </div>


                                        <div className='col-md-6'>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
                                                        <InputLabel id="demo-simple-select-standard-label">Month</InputLabel>
                                                        <Select label="Month" name="month" value={formik.values.month}
                                                            onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Month">
                                                            <MenuItem value=""><em>None</em> </MenuItem>
                                                            <MenuItem value={'01'}>01</MenuItem>
                                                            <MenuItem value={'02'}>02</MenuItem>
                                                            <MenuItem value={'03'}>03</MenuItem>
                                                            <MenuItem value={'04'}>04</MenuItem>
                                                            <MenuItem value={'05'}>05</MenuItem>
                                                            <MenuItem value={'06'}>06</MenuItem>
                                                            <MenuItem value={'07'}>07</MenuItem>
                                                            <MenuItem value={'08'}>08</MenuItem>
                                                            <MenuItem value={'09'}>09</MenuItem>
                                                            <MenuItem value={'10'}>10</MenuItem>
                                                            <MenuItem value={'11'}>11</MenuItem>
                                                            <MenuItem value={'12'}>12</MenuItem>
                                                        </Select>
                                                        {formik.touched.month && formik.errors.month ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.month}</span> : null}
                                                    </FormControl>
                                                </div>
                                                <div className="col-md-4">
                                                    <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
                                                        <InputLabel id="demo-simple-select-standard-label">Year</InputLabel>
                                                        <Select label="Year" name="year" value={formik.values.year}
                                                            onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="year">
                                                            <MenuItem value=""><em>Year</em> </MenuItem>
                                                            <MenuItem value={'21'}>2021</MenuItem>
                                                            <MenuItem value={'22'}>2022</MenuItem>
                                                            <MenuItem value={'23'}>2023</MenuItem>
                                                            <MenuItem value={'24'}>2024</MenuItem>
                                                            <MenuItem value={'25'}>2025</MenuItem>
                                                            <MenuItem value={'26'}>2026</MenuItem>
                                                            <MenuItem value={'27'}>2027</MenuItem>
                                                            <MenuItem value={'28'}>2028</MenuItem>
                                                            <MenuItem value={'29'}>2029</MenuItem>
                                                            <MenuItem value={'30'}>2030</MenuItem>
                                                            <MenuItem value={'31'}>2031</MenuItem>
                                                            <MenuItem value={'32'}>2032</MenuItem>
                                                        </Select>
                                                        {formik.touched.year && formik.errors.year ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.year}</span> : null}
                                                    </FormControl>
                                                </div>
                                                <div className="col-md-4">
                                                    <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
                                                        <TextField id="standard-basic" label="CVC" variant="standard"
                                                            name="cvc_number" value={formik.values.cvc_number}
                                                            onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="CVC" />
                                                        {formik.touched.cvc_number && formik.errors.cvc_number ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.cvc_number}</span> : null}
                                                    </FormControl>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='row mt-4 mb-4'>
                                        <div className='col-md-3'></div>
                                        <div className='col-md-6'>
                                            <div style={{ textAlign: 'center' }}>
                                                <Stack >
                                                    <Button disabled={!formik.isValid} variant="contained" className='br' onClick={formik.handleSubmit} >
                                                        {loading ? <span><span className="spinner-border spinner-border-sm"></span> Please wait...</span> : 'Proceed To Pay'}</Button>

                                                </Stack>
                                            </div>
                                        </div>
                                        <div className='col-md-3'></div>
                                    </div>
                                </form>
                            </Box>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default PaymentDetails;