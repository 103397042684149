import { createSlice } from '@reduxjs/toolkit'
import api from "../../Api";
import Swal from "sweetalert2";

// Slice
const slice = createSlice({
    name: 'session',
    initialState: {
        session: [],
    },
    reducers: {
        UsesessionSuccess:(res, action) => {
          res.session = action.payload;
      },
    },
  });
  
  export default slice.reducer
  // Actions
  const { UsesessionSuccess } = slice.actions;
  
  export const UserSession = (body) => async dispatch => {
    try {
      const res = await api.get(`/loginHist/fetch/${body}`);
      if(res.data.Status == false){
        // Swal.fire({  
        //   title: 'Warning',  
        //   text: res.data.Message,  
        //   icon: 'warning',   
        //   confirmButtonColor: '#3085d6',  
        //   confirmButtonText: 'Ok',
        //   timer: 1500  
        // });
      }else{
        dispatch(UsesessionSuccess(res.data));
      }
  
    } catch (e) {
      return console.error(e.message);
    }
  }