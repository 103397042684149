import * as React from 'react';
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useFormik } from 'formik';
import api from "../../Api";
import Swal from "sweetalert2";

const validateUser = profilePaid => {
    const errors = {};
    if (!profilePaid.setamount) {
        errors.setamount = '*Please Enter amount...';
    }
    return errors;
};

function SubscriptionPrice() {
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const [loading, setLoading] = useState(false);
    const history = useHistory();


    const formik = useFormik({
        initialValues: {
            setamount: '',
        },
        validate: validateUser,
        onSubmit: (values, { resetForm }) => {
            // api.get(`/cardsDetails/fetch/${currentUser.id}`).then(data => {
            //     if (data.data.length != 0) {
                    let body = {
                        paid: values.setamount,
                        id: currentUser.id
                    }
                    api.post(`/user/SET/Profile/price`, body).then(res => {
                        Swal.fire({  
                            title: 'Successs',  
                            text: res.data.Message,  
                            icon: 'success',   
                            confirmButtonColor: '#3085d6',  
                            confirmButtonText: 'Yes!',
                            timer: 1500  
                          });
                        window.history.go(-1);
                    })
                // }else{
                //       Swal.fire({
                //         title: `Warning`,
                //         text: `PLEASE ADD A PAYMENT CARD`,
                //         icon: 'warning',
                //         showCancelButton: true,
                //         confirmButtonText: 'Yes, Go it!',
                //         cancelButtonText: 'No, keep it'
                //       }).then((result) => {
                //         if (result.value) {
                //           history.push(`/profile/card_add`)
                //         } else if (result.dismiss === Swal.DismissReason.cancel) {
                //         }
                //       })
                // }
            // })
        }
    });
    return (
        <>
            <div className='padd'>
                <Box sx={{ minWidth: 275 }} className='mt-2 '>
                    <Card variant="outlined" className="padds">
                        <form className="theme-form">
                            <div className="row pb-4">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <lable>Price per month</lable>
                                        <input type="text" className="form-control" placeholder="$ Free" 
                                         name="setamount" value={formik.values.setamount} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            <div className=' card-titles'>
                                            <p>Minimum $4.99 USD or free</p>
                                            <p>You must <a style={{color:'#00aff0'}}>Add a Bank Account or Payment Information</a> before you can set your price or accept tips.</p>
                                            </div>
                                    </div>
                                </div>
                                <div>
                                    <span style={{ float: 'right' }}>
                                        <Button disabled={!formik.isValid} style={{ padding: '1px 8px' }} type="submit" variant="contained" color="success" onClick={formik.handleSubmit}>
                                            {loading ? <span><span className="spinner-border spinner-border-sm"></span>Please wait...</span> : 'Submit'} </Button>
                                    </span>
                                </div>
                            </div>
                          

                        </form>
                        <hr/>

                        <div>
                            <div className='card-titles mb-3'>
                                <h3 className='mb-2'>Price per month</h3>
                                <p>Offer a free trial or a discounted subscription on your profile for a limited number of new or already expired subscribers.</p>
                            </div><hr />
                            <div className='card-titles' style={{ textAlign: 'center' }}>
                            <button disabled className="btn">START PROMOTION CAMPAIGN</button>
                            </div>
                        </div>
                        <hr />

                        <div>
                            <div className='card-titles mb-3'>
                                <h3 className='mb-2'>Subscription bundles</h3>
                                <p>Offer several months of subscription as a discounted bundle.</p>
                            </div><hr />
                            <div className='card-titles' style={{ textAlign: 'center' }}>
                                <button disabled className="btn">ADD BUNDLE</button>
                            </div>
                        </div>
                        <hr />

                        <div>
                            <div className='card-titles mb-3'>
                                <h3 className='mb-2'>Free Trial Links</h3>
                                <p disabled >Create and share separate links with free trial subscription.</p>
                            </div><hr />
                            <div className='card-titles' style={{ textAlign: 'center' }}>
                               <button disabled className="btn">CREATE NEW FREE TRIAL LINK</button>
                            </div>
                        </div>
                        <hr />

                    </Card>
                </Box>
            </div>
        </>
    );
}

export default SubscriptionPrice;