import {  useEffect, useState } from "react";
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../store/user'
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Dropdown from 'react-bootstrap/Dropdown';
import HeaderNotification from '../notifications/headernotification';
import HeaderMessages from '../messages/messageheader';
import LogoutIcon from '@mui/icons-material/Logout';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { useHistory } from "react-router-dom";
import TemporaryDrawer from "./side";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";
import PushNotificationDemo from "../PushNotificationDemo";
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import socket from "../utils/socketNotification";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import api from "../Api";

function Header(props) {
  const [value, setValue] = React.useState(0);
  const [opens, setOpens] = React.useState(false);
  const dispatch = useDispatch()
  const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
  const [noti, setNoti] = useState(false);
  const [notification, setMotification] = React.useState(null);
  const open = Boolean(notification);
  const history = useHistory();
  const [search, seSearch] = useState([]);
  const [commme, setCommme] = useState('');

  const handleClick = (event) => {
    setMotification(event.currentTarget);
  };
  const handleClose = () => {
    setMotification(null);
  };
 

  const goToUserProfile = () => {
    history.push('/User/profile')
  }
  const goToHome = () => {
    history.push('/home')
  }

  const createPost = () => {
    history.push('/User/profile')
  }
  const fans = () => {
    history.push('/fans')
  }

  const logoutSession = () => {
    let Typingbody = {
      id: currentUser.id,
      last_seen: new Date
    }
    socket.emit('realTimeTyping', Typingbody)
    dispatch(logout())
    history.push('/')
  }
  if (currentUser?.Status == true) { } else {
    history.push('/')
  }

  const gotoPages = () =>{
    history.push(`/inbox/${currentUser.id}`)
  }

  const handleClickOpen = () => {
    setOpens(true);
  };

  const handleCloses = () => {
    setOpens(false);
  };
  useEffect(() => {
    var module = {
      options: [],
      header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
      dataos: [
        { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
        { name: 'Windows', value: 'Win', version: 'NT' },
        { name: 'iPhone', value: 'iPhone', version: 'OS' },
        { name: 'iPad', value: 'iPad', version: 'OS' },
        { name: 'Kindle', value: 'Silk', version: 'Silk' },
        { name: 'Android', value: 'Android', version: 'Android' },
        { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
        { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
        { name: 'Macintosh', value: 'Mac', version: 'OS X' },
        { name: 'Linux', value: 'Linux', version: 'rv' },
        { name: 'Palm', value: 'Palm', version: 'PalmOS' }
      ],
      databrowser: [
        { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
        { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
        { name: 'Safari', value: 'Safari', version: 'Version' },
        { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
        { name: 'Opera', value: 'Opera', version: 'Opera' },
        { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
        { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
      ],
      init: function () {
        var agent = this.header.join(' '),
          os = this.matchItem(agent, this.dataos),
          browser = this.matchItem(agent, this.databrowser);

        return { os: os, browser: browser };
      },
      matchItem: function (string, data) {
        var i = 0,
          j = 0,
          regex,
          regexv,
          match,
          matches,
          version;

        for (i = 0; i < data.length; i += 1) {
          regex = new RegExp(data[i].value, 'i');
          match = regex.test(string);
          if (match) {
            regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
            matches = string.match(regexv);
            version = '';
            if (matches) { if (matches[1]) { matches = matches[1]; } }
            if (matches) {
              matches = matches.split(/[._]+/);
              for (j = 0; j < matches.length; j += 1) {
                if (j === 0) {
                  version += matches[j] + '.';
                } else {
                  version += matches[j];
                }
              }
            } else {
              version = '0';
            }
            return {
              name: data[i].name,
              version: parseFloat(version)
            };
          }
        }
        return { name: 'unknown', version: 0 };
      }
    };

    var e = module.init()

    if (e.browser.name != 'Safari' && e.os.name != 'iPhone' && e.os.name != 'iPad') {
      setNoti(true);
    } else {
      setNoti(false);
    }
  }, [0]);


const searchData = (e) =>{
 if(e.target.value != ''){
  setCommme(e.target.value)
  let body = {
    firstname:e.target.value,
    id:currentUser.id
  }
  api.post(`/search-user-profiles`, body).then(res=>{
    if(res.data.Status != false){
      seSearch(res.data);
    }
  });
 }else{
  setCommme('');
  seSearch([]); 
 }
}

const onSelect = (ID) =>{
  history.push(`/subscriptions/${ID}`)
  setCommme('');
  seSearch([]);
  handleCloses()
}

  return (
    <>
      <header>
        <div className="container">
          <div className="header-section">
            <div className="header-left">
              {noti ? <PushNotificationDemo  /> : null}
              <div className="brand-logo d-flex">
                <TemporaryDrawer />
                <a onClick={goToHome}>
                  <img style={{ width: "100%", height: '46px' }} src="../assets/images/icon/logo.png" alt="logo" className="img-fluid blur-up lazyload" />
                </a>
                <IconButton className='hide_desktop text-light' aria-label="search" size="large" variant="outlined" onClick={handleClickOpen}>
                  <SearchIcon fontSize="inherit" />
                </IconButton>
              </div>
              <div className="search-box">
                <SearchIcon className="icon uiicons " style={{ color: 'white', fontSize: '30px' }} />
                <input list="browsers" type="text" value={commme} className="form-control" onChange={(e) => searchData(e)} placeholder="find fans..." />
                {search.length != 0 ? <div  className="search-suggestion">
                  
                  <ul className="friend-list mt-4">
                  {search.map((data,i) =>  <li key={i} onClick={()=>onSelect(data.ID)} style={{cursor:'grab'}}>
                      <div className="media">
                      <Stack direction="row" spacing={2}>
                            <Avatar src={data?.s3url} alt={data?.firstname} style={{width:'30px', height:'30px'}} />
                      </Stack>
                        <div className="media-body">
                          <div>
                            <h5 className="mt-0">{data?.firstname} {data?.lastname}</h5>
                          </div>
                        </div>
                      </div>
                    </li>)}
                   
                  </ul>
                </div>:null}
              </div>

              <div className="search-box">
                <Dialog open={opens}>
                <DialogTitle >Search <span style={{ float: 'right' }}><CloseIcon onClick={()=>handleCloses()} /></span></DialogTitle>
                  <DialogContent>
                  <div className="mb-4">
                     <input list="browsers" type="text" className="form-control" onChange={(e) => searchData(e)} placeholder="find fans..." />
                  </div>
                  <div  className="search-suggestion">
                  <ul className="friend-list">
                  {search.map((data,i) =>  <li key={i} onClick={()=>onSelect(data.ID)} style={{cursor:'grab', marginBottom:'10px'}}>
                      <div className="media">
                      <Stack direction="row" spacing={2}>
                            <Avatar src={data?.s3url} alt={data?.firstname} style={{width:'30px', height:'30px'}} />
                      </Stack>
                        <div className="media-body">
                          <div>
                            <h5 className="mt-0">{data?.firstname} {data?.lastname}</h5>
                          </div>
                        </div>
                      </div>
                    </li>)}
                  </ul>
                </div>
                  </DialogContent>
                </Dialog>
              </div>
              <ul className="btn-group">
                {/* <!-- home --> */}
                <li className="header-btn home-btn">
                  <a className="main-link" href="index.html">
                    <i className="icon-light stroke-width-3 iw-16 ih-16" data-feather="home"></i>
                  </a>
                </li>
                {/* <!-- add friend --> */}
                <li className="header-btn custom-dropdown dropdown-lg add-friend">
                  <a className="main-link" href="javascript:void(0)" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="icon-light stroke-width-3 iw-16 ih-16" data-feather="user-plus" />
                  </a>

                  <div className="dropdown-menu">
                    <div className="dropdown-header">
                      <span>friend request</span>
                      <div className="mobile-close">
                        <h5>close</h5>
                      </div>
                    </div>
                    <div className="dropdown-content">
                      <ul className="friend-list">
                        <li>
                          <div className="media">
                            <img src="../assets/images/user-sm/5.jpg" alt="user" />
                            <div className="media-body">
                              <div>
                                <h5 className="mt-0">Paige Turner</h5>
                                <h6> 1 mutual friend</h6>
                              </div>
                            </div>
                          </div>
                          <div className="action-btns">
                            <button className="btn btn-solid">confirm</button>
                            <button className="btn btn-outline ms-1">delete</button>
                          </div>
                        </li>
                        <li>
                          <div className="media">
                            <img src="../assets/images/user-sm/6.jpg" alt="user" />
                            <div className="media-body">
                              <div>
                                <h5 className="mt-0">Paige Turner</h5>
                                <h6> 1 mutual friend</h6>
                              </div>
                            </div>
                          </div>
                          <div className="action-btns">
                            <button className="btn btn-solid">confirm</button>
                            <button className="btn btn-outline ms-1">delete</button>
                          </div>
                        </li>
                        <li>
                          <div className="media">
                            <img src="../assets/images/user-sm/7.jpg" alt="user" />
                            <div className="media-body">
                              <div>
                                <h5 className="mt-0">Paige Turner</h5>
                                <h6> 1 mutual friend</h6>
                              </div>
                            </div>
                          </div>
                          <div className="action-btns">
                            <button className="btn btn-solid">confirm</button>
                            <button className="btn btn-outline ms-1">delete</button>
                          </div>
                        </li>
                        <li>
                          <div className="media">
                            <img src="../assets/images/user-sm/2.jpg" alt="user" />
                            <div className="media-body">
                              <div>
                                <h5 className="mt-0">Paige Turner</h5>
                                <h6> 1 mutual friend</h6>
                              </div>
                            </div>
                          </div>
                          <div className="action-btns">
                            <button className="btn btn-solid">confirm</button>
                            <button className="btn btn-outline ms-1">delete</button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="header-right">
              <ul className="option-list">

                <li className="header-btn custom-dropdown profile-btn btn-group">
                  <div className='pull-right account_icon'>
                    <Dropdown>
                      <Dropdown.Toggle style={{ outline: 'none', background: 'none', border: 'none', padding: '0px' }}>
                        <a className="main-link" href="javascript:void(0)" data-bs-toggle="dropdown"
                          aria-haspopup="true" aria-expanded="false">
                          <i className="icon-light stroke-width-3 d-sm-none d-block iw-16 ih-16"
                            data-feather="user"></i>
                          <div className="media  d-sm-flex">
                            <div className="user-img">
                              <Stack direction="row" spacing={2}>
                                <Avatar alt={currentUser?.full_name} src={currentUser?.s3profileurl} />
                              </Stack>
                              <span className="available-stats online" ></span>
                            </div>
                            <div className="media-body d-md-block">
                              <h4>{currentUser?.full_name}</h4>
                              <span>@{currentUser?.user_name}</span>
                            </div>
                          </div>
                        </a>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="dropdown-header">
                          <span>profile</span>
                          <div className="mobile-close">

                          </div>
                        </div>
                        <Dropdown.Item >
                          <a >
                            <div className="media">
                              <AccountCircleIcon />
                              <div className="media-body">
                                <div onClick={goToUserProfile}>
                                  <h5 className="mt-0">Profile</h5>
                                </div>
                              </div>
                            </div>
                          </a>
                        </Dropdown.Item>
                        <Dropdown.Divider style={{ color: '#f5eaea' }} />
                        <Dropdown.Item >
                          <a onClick={logoutSession}>
                            <div className="media">
                              <LogoutIcon />
                              <div className="media-body">
                                <div>
                                  <h5 className="mt-0">log out</h5>
                                </div>
                              </div>
                            </div>
                          </a>
                        </Dropdown.Item>
                        <Dropdown.Divider style={{ color: '#f5eaea' }} />
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </li>

                {/* <!-- message --> */}
                <li className="hide_mobile header-btn custom-dropdown dropdown-lg btn-group message-btn">
                  <HeaderMessages />
                </li>
                <li className="hide_mobile header-btn custom-dropdown dropdown-lg btn-group notification-btn">
                  <HeaderNotification socket={props.socket}/>
                </li>
                <li className="hide_mobile header-btn custom-dropdown profile-btn btn-group">
                  <Dropdown>
                    <Dropdown.Toggle style={{ outline: 'none', background: 'none', border: 'none', padding: '0px' }}>
                      <a className="main-link" href="javascript:void(0)" data-bs-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i className="icon-light stroke-width-3 d-sm-none d-block iw-16 ih-16"
                          data-feather="user"></i>
                        <div className="media d-none d-sm-flex">
                          <div className="user-img">
                            <Stack direction="row" spacing={2}>
                              <Avatar alt={currentUser?.full_name} src={currentUser?.s3profileurl} />
                            </Stack>
                            <span className="available-stats online"></span>
                          </div>
                          <div className="media-body d-none d-md-block">
                            <h4>{currentUser?.full_name}</h4>
                            <span>@{currentUser?.user_name}</span>
                          </div>
                        </div>
                      </a>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className="dropdown-header">
                        <span>profile</span>
                        <div className="mobile-close">
                          <h5>close</h5>
                        </div>
                      </div>
                      <Dropdown.Item >
                        <a>
                          <div className="media">
                            <AccountCircleIcon />
                            <div className="media-body">
                              <div onClick={goToUserProfile}>
                                <h5 className="mt-0">Profile</h5>

                              </div>
                            </div>
                          </div>
                        </a>
                      </Dropdown.Item>
                      <Dropdown.Divider style={{ color: '#f5eaea' }} />
                      <Dropdown.Item >
                        <a onClick={logoutSession}>
                          <div className="media">
                            <LogoutIcon />
                            <div className="media-body">
                              <div>
                                <h5 className="mt-0">log out</h5>
                              </div>
                            </div>
                          </div>
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                </li>
              </ul>
            </div>

          </div>
        </div>
        <div className="mobile-fix-menu">

          <Box sx={{ width: '100%' }}>
            <BottomNavigation
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}>
              <BottomNavigationAction onClick={goToHome} icon={<HomeIcon />} />
              <BottomNavigationAction icon={<li className="header-btn custom-dropdown dropdown-lg btn-group notification-btn">
                <HeaderNotification />
              </li>} />
              <BottomNavigationAction onClick={createPost} icon={<AccountCircleIcon />} />
              <BottomNavigationAction onClick={fans} icon={<PeopleIcon />} />
              <BottomNavigationAction onClick={gotoPages} icon={
                <li className="header-btn  btn-group message-btn"><MailOutlineIcon />
              </li>} />

            </BottomNavigation>
          </Box>
        </div>
      </header>
    </>
  );
}

export default Header;