import React, { useState, useEffect, useRef } from "react";
import ReplayIcon from '@mui/icons-material/Replay';
import { useHistory } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import api from "../Api";
import Loader from "react-loader-spinner";
import AddTaskIcon from '@mui/icons-material/AddTask';

const Following = () => {
    const componentMounted = useRef(true);
    const [userSeggession, setUserSeggession] = useState([]);
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const history = useHistory();
    
    useEffect(() => {
    if (componentMounted.current) {
        getSeggestion(currentUser.id);
    }
    return () => {
        componentMounted.current = false;
    }
    }, []);

    const getSeggestion = (id) => {
        api.get(`/user/subscription/fetch/${id}`).then(res => {
            console.log(res.data[0])
            if(res.data){
            setUserSeggession(res.data[0]);
            }
        });

    }

    const gotoDataUser = (ID) => {
        history.push(`/subscriptions/${ID}`);
    }

    const gotoBack = () =>{
        window.history.go(-1)
    }

    const refresh = () => {
        setUserSeggession([])
        getSeggestion(currentUser.id);
    }



    const SubscribeProfile = (paid, ids, datas) => {
        let body = {
            "user_id": currentUser.id,
            "to_user_id": ids,
            "payment_status": '0',
            "join_date": new Date
        }
        api.post('/user/subscription/insert', body).then(data => {
            console.log(data.data)
            getSeggestion(currentUser.id);
        });
    }

    return (
        <>
            <div className="suggestion-box d-flex flex-wrap">
                <div className="card-title">
                    <h3>
                    <svg  onClick={()=>gotoBack()} width={15} height={10} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-left fa-w-14 fa-2x"><path fill="currentColor" d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" class=""></path>
                    </svg> Fans following</h3>
                    <div className="settings">
                        <div className="setting-btn">
                            <a className="d-flex" onClick={refresh}>
                                <ReplayIcon className='uiicons' />
                            </a>
                        </div>
                    </div>
                </div>
                
                {userSeggession.map((data, i) => <>
                    <div className="col-sm-12 col-xs-12 col-md-6 fanssuggestion"key={i}>
                        <div  className="suggestion-content ratio_115" style={{ padding: '2px !important' }}>
                            <div className="slide-2 no-arrow default-space" >
                                <div className="story-box" style={{ width: '100%', height: '114px' }}>
                                    <div className="story-bg" >
                                        <img style={{ borderRadius: '10px', width: '100%', height: '100%', maxHeight: '114px', objectFit: 'cover' }} src={data?.cover_image}
                                            onError={(event) => event.target.removeAttribute('src')}
                                            onError={(event) => event.target.src = '../assets/images/avtar-image.jpg'}
                                            className="img-fluid bg-img" data-adaptive-background={1} alt='true' />
                                    </div>

                                    <div className="profile-bg">
                                        <Stack direction="row" onClick={() => gotoDataUser(data.ID)} spacing={2} style={{ position: 'absolute', bottom: '10px', left: '18px', border: 'solid white 2px', borderRadius: '50px',zIndex:'10', backgroundColor: 'gray' }}>
                                            <Avatar className="img-fluid-post blur-up lazyload bg-img imgrad" alt={data?.firstname} src={data?.profile_image} />
                                        </Stack>
                                        <div className="story-content" >   <span>
                                            <h6 onClick={() => gotoDataUser(data.ID)} style={{ fontSize: "16px", fontWeight: 'bold', marginLeft: '30%' }}>{data?.firstname} {data?.lastname}</h6>
                                            <p style={{ textAlign:'center',fontSize: "12px", marginBottom:'0px' }}>@{data?.user_name} </p> 
                                            <p style={{ textAlign:'center',fontSize: "12px", marginBottom:'0px' }}>Interest match {data?.match_percentage} % </p> 
                                        </span>
                                        
                                        </div>
                                       
                                    </div>
                                    <div style={{ padding: '5px 20px', }}>
                                    <p className="subButton" onClick={()=>SubscribeProfile(data?.paid, data.to_user_id, data)}>
                                        <AddTaskIcon   style={{color:'#fff',cursor: "pointer"}}/>       
                                    </p>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </>
                )}
                {userSeggession.length == 0 ?
                   <div style={{textAlign:'center', width:'100%'}} className='mt-4 mb-4'>
                   <Loader type="Bars" color="#3e89c9" height={30} width={30}/>
                </div> : null}
            </div>
        </>
    );
}

export default Following
