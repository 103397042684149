import * as React from 'react';
import { useRef, useEffect, useState } from "react";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useFormik } from 'formik';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfile } from '../../store/Accounts/profile';
import { UserProfileImage } from '../../store/Accounts/profileImage';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import api from "../../Api";
import Swal from "sweetalert2";

function EditProfiles() {
    const componentMounted = useRef(true);
    const [imagesd, setImagesd] = useState('');
    const dispatch = useDispatch();
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const [cover, setCover] = useState([]);
    const { profile } = useSelector(state => state)
    const [loading, setLoading] = useState(false);
    const [loadingImg, setLoadingImg] = useState(false);
    const history = useHistory();
    const [onerror, setOnerror] = useState(false);
    const [countrydata, setCountrydata] = useState('');
    const [statedata, setStatedata] = useState('');
    const [citydata, setCitydata] = useState('');

    const [country, setCountry] = useState([]);
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);

    var settings = {
        dots: false,
        infinite: true,
        autoPlay: true,
        speed: 500,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const onImageChangeCover = (event) => {
        setLoadingImg(true)
        for (let index = 0; index < event.target.files.length; index++) {
            let reader = new FileReader();
            reader.readAsDataURL(event.target.files[index]);
            reader.onload = (_event) => { }
        }
        const formData = new FormData();
        formData.append('id', currentUser.id);
        formData.append('file[]', event.target.files[0]);
        api.post('user/UserCoverImages', formData).then(res => {
            if (res.data) {
                getCoverImage(currentUser.id);
                setLoadingImg(false)
            }
        });
    };

    const onImageChange = (event) => {
        for (let index = 0; index < event.target.files.length; index++) {
            let reader = new FileReader();
            reader.readAsDataURL(event.target.files[index]);
            reader.onload = (_event) => {
                setImagesd(reader.result)
            }
        }
        const formData = new FormData();
        formData.append('id', currentUser.id);
        formData.append('file', event.target.files[0]);
        dispatch(UserProfileImage(formData));
        getProfile();
    };


    useEffect(() => {
        if (componentMounted.current) {
            getProfile();
            getCoverImage(currentUser.id)
            // setTimeout(() => {
                setCountrydata((profile.userProfile.country) ? profile.userProfile.country : 'Country');
                setStatedata((profile.userProfile.state)? profile.userProfile.state: 'State');
                setCitydata((profile.userProfile.city)? profile.userProfile.city : "City");
            // }, 1500);
        }
        return () => {
            componentMounted.current = false;
        }
    }, []);

    const getCoverImage = (id) => {
        api.get(`user/UserCoverImages/${id}`).then(res => {
            if (res.data.Status != false) {
                setCover(res.data);
                setOnerror(false)
            } else {
                setOnerror(true)
            }
        })
    }


    const getProfile = () => {
        let body = {
            id: currentUser.id
        }
        dispatch(UserProfile(body))
        setImagesd(profile.userProfile?.s3url)
    }

    useEffect(() => {
        api.get(`/country`).then(data => {
            setCountry(data.data)
        })
    }, [0]);

    const selectState = (name) => {
        setCountrydata(name.target.value);
        api.get(`/state/${name.target.value}`).then(data => {
            setState(data.data)
        })
    }

    const selectCity = (name) => {
        setStatedata(name.target.value);
        let datas = {
            Sname: name.target.value
        }
        api.post(`/city`, datas).then(data => {
            setCity(data.data)
        })
    }
    const onSelectedCity = (name) =>{
        setCitydata(name.target.value);
        console.log(name.target.value)
    }

    const formik = useFormik({
        initialValues: {
            id: currentUser.id,
            location: profile.userProfile?.location,
            email: profile.userProfile?.email,
            gender: profile.userProfile?.gender,
            firstname: profile.userProfile?.firstname,
            lastname: profile.userProfile?.lastname,
            userName: profile.userProfile?.user_name,
            site_url: profile.userProfile?.site_url,
            zip_code: profile.userProfile?.zip_code,
            facebook_url: profile.userProfile?.facebook_url,
            instagram_url: profile.userProfile?.instagram_url,
            twitter_url: profile.userProfile?.twitter_url,
            onlyFans_url: profile.userProfile?.onlyFans_url,
            bio: profile.userProfile?.bio
        },
        onSubmit: values => {
            let data = {
                id: values.id,
                location: values.location,
                email: values.email,
                gender: values.gender,
                firstname: values.firstname,
                lastname: values.lastname,
                userName: values.user_name,
                site_url: values.site_url,
                country: countrydata,
                state: statedata,
                city: citydata,
                zip_code: values.zip_code,
                facebook_url: values.facebook_url,
                instagram_url: values.instagram_url,
                twitter_url: values.twitter_url,
                onlyFans_url: values.onlyFans_url,
                bio: values.bio
            }
            api.post('user/UpdateProfile', data).then(res => {
                if (res.data.Status == false) {
                    Swal.fire({
                        title: 'Warning',
                        text: res.data.Message,
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok',
                        timer: 1500
                    });
                } else {
                    Swal.fire({
                        title: 'Successs',
                        text: res.data.Message,
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Yes!',
                        timer: 1500
                    });
                    window.history.go(-1);
                }
            })
        }
    });

    const goToeditSubscription = () => {
        history.push('/profile/subscription')
    };
    const gotoPrivacyandPolicy = () => {
        history.push('/profile/privacy')
    }
    const goTocoverImage = () =>{
        history.push('/profile/images')
    }
    return (
        <>
            <div className='padd'>
                <div className="profile-cover" >
                    {loadingImg ? <span className="spinner-border spinner-border-sm coverLoder"></span> : null}
                    <Slider {...settings} style={{ height: '330px', objectFit: 'cover' }}>
                        {onerror ? <div style={{ height: '350px', objectFit: 'cover', width: '100%' }}>
                            <img src='' onError={(event) => event.target.removeAttribute('src')}
                                onError={(event) => event.target.src = '../assets/images/cover/1.jpg'} className="img-fluid blur-up lazyload bg-img" style={{ height: '350px', objectFit: 'cover', width: '100%' }} />
                        </div> : null}
                        {cover.map((data, i) =>
                            <div key={i} style={{ height: '350px', objectFit: 'cover', width: '100%' }}>
                                <img src={data?.seimagecoverUrl} onError={(event) => event.target.removeAttribute('src')}
                                    onError={(event) => event.target.src = '../assets/images/cover/1.jpg'} className="img-fluid blur-up lazyload bg-img" style={{ height: '350px', objectFit: 'cover', width: '100%' }} />
                            </div>)}
                    </Slider>
                    <div style={{ position: 'absolute', right: '0px', top: '48px' }}>
                        <Tooltip title="Upload Cover Picture" style={{ width: '100%' }}>
                            <label className="rt-icon2-camera uplode" htmlFor="photo-upload"
                                style={{ width: "40px", height: '40px' }}>
                                <CameraAltIcon style={{ color: 'white' }} />
                            </label>
                        </Tooltip>
                        <input type="file" id="photo-upload" className="form-control-file" style={{ width: '40px' }} multiple name="myImage" onChange={onImageChangeCover} />

                    </div>
                    <div className="d-lg-block  profilesecpadd">
                        <div className="profile-content">
                            <div className="image-section">
                                <div className="profile-img">
                                    <div>
                                        <div className="bacbuttonpositionss">
                                            <div className="form-group Photo">
                                                <div className="form-group">
                                                    <Tooltip title="Upload Profile Picture" style={{ width: '100%' }}>
                                                        <label className="rt-icon2-camera uplode profile_pic" htmlFor="profilepics">
                                                            <Stack direction="row" spacing={2}>
                                                                <Avatar alt="kelin jasen" src={imagesd} style={{ width: '80px', height: '80px' }} />
                                                            </Stack>
                                                        </label>
                                                    </Tooltip>
                                                    <input type="file" className="form-control-file" style={{ width: '80px' }} id='profilepics' name="myImage" onChange={onImageChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Box sx={{ minWidth: 275 }} className='mt-2 '>
                    <Card variant="outlined" className="padds">
                        <form className="theme-form">
                            <div>
                                <p className='mb-2'>Personal Information</p>
                                <div className="row pb-4">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="firstname" placeholder="First Name" value={formik.values.firstname}
                                                onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="lastname" placeholder="Last Name" value={formik.values.lastname}
                                                onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row pb-4">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" name="userName" placeholder="User Name" value={formik.values.userName}
                                                onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                        </div>
                                    </div>

                                    <div className="form-group col-md-6">
                                        <select type="text" className="form-control" name="gender" placeholder="Gender" value={formik.values.gender}
                                            onChange={formik.handleChange} onBlur={formik.handleBlur} >
                                            <option value="">Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </select>
                                    </div>

                                </div>

                                <div className="row pb-4">
                                    <div className="form-group col-md-6">
                                        <input type="text" className="form-control" name="location" placeholder="Location" value={formik.values.location}
                                            onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input type="url" className="form-control" name="site_url" placeholder="Web Site Url" value={formik.values.site_url}
                                            onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    </div>

                                </div>

                                <div className="row pb-4">
                                    <div className="form-group col-md-6">
                                        <select className="form-control" name="country" placeholder={countrydata} 
                                        onChange={ (e)=>selectState(e)} >
                                            <option value={countrydata}>{countrydata}</option>
                                            {country.map(data => <option value={data?.name} >{data?.name}</option>)}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <select className="form-control" name="state"  placeholder={statedata}
                                            onChange={(e)=>selectCity(e)}
                                             >
                                                 <option value={statedata}>{statedata}</option>
                                                 {state.map(data => <option value={data?.name} >{data?.name}</option>)}
                                        </select>
                                    </div>

                                </div>
                                <div className="row pb-4">
                                    <div className="form-group col-md-6">
                                        <select className="form-control"  placeholder={citydata}
                                        onChange={(e)=>onSelectedCity(e)}>
                                                <option value={citydata}>{citydata}</option>
                                                {city.map(data => <option value={data?.name} >{data?.name}</option>)}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input type="url" className="form-control" name="zip_code" placeholder="Zip code" value={formik.values.zip_code}
                                            onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    </div>
                                </div>
                                <div className="row pb-4">
                                    <div className="form-group col-md-12">
                                        <textarea type="text" className="form-control" name="bio" placeholder="Bio" value={formik.values.bio}
                                            onChange={formik.handleChange} onBlur={formik.handleBlur} ></textarea>
                                    </div>
                                </div>
                            </div>
                            <hr />

                            <div>
                                <p className='mb-2'>Social Links</p>
                                <div className="row pb-4">
                                    <div className="form-group col-md-6">
                                        <input type="text" className="form-control" name="facebook_url" placeholder="Facebook Profile Url" value={formik.values.facebook_url}
                                            onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input type="url" className="form-control" name="instagram_url" placeholder="Instgram Profile Url" value={formik.values.instagram_url}
                                            onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    </div>

                                </div>
                                <div className="row pb-4">
                                    <div className="form-group col-md-6">
                                        <input type="text" className="form-control" name="twitter_url" placeholder="Twitter Profile Url" value={formik.values.twitter_url}
                                            onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <input type="url" className="form-control" name="onlyFans_url" placeholder="OnlyFans Profile Url" value={formik.values.onlyFans_url}
                                            onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    </div>

                                </div>
                            </div>



                            <div className="setting-dropdown  custom-dropdown arrow-none dropdown-sm pb-4" style={{ textAlign: 'center' }}>
                                <a onClick={formik.handleSubmit} className="btn btn-solid">
                                    {loading ? <span><span className="spinner-border spinner-border-sm"></span> wait...</span> : 'Update'}
                                </a>
                            </div>

                        </form>

                        <div>
                            <List className='rightborder' sx={{ width: '100%' }}>
                                <h2 className="nav-link"> Subscription  </h2><hr />
                                <ListItemButton onClick={goToeditSubscription}>
                                    <ListItemText primary=" Subscription price and bundles " />
                                </ListItemButton><hr />

                                <ListItemButton onClick={goTocoverImage}>
                                    <ListItemText primary="My Cover Image" />
                                </ListItemButton><hr />

                                <ListItemButton onClick={gotoPrivacyandPolicy}>
                                    <ListItemText primary="Privacy and safety" />
                                </ListItemButton>

                            </List>
                        </div>
                    </Card>
                </Box>
            </div>
        </>
    );
}

export default EditProfiles;
