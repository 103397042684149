import * as React from 'react';
import { useEffect, useState, useRef } from "react";
// import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import api from "../../Api";
import Loader from "react-loader-spinner";

function MyIntrest() {
    const componentMounted = useRef(true);
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const [intrest, setIntrest] = useState([]);
    const [loadings, setLoadings] = useState(false);

    useEffect(() => {
        if (componentMounted.current) {
            getinterset();
            setLoadings(true);
            api.get(`/user/interset/${currentUser.id}`).then(data=>{
                setIntrest(data.data);
                setLoadings(false);
            });
        }
        return () => {
            componentMounted.current = false;
        }
    }, []);

    const getinterset = () => {
        api.get(`/user/interset/${currentUser.id}`).then(data=>{
            setIntrest(data.data);
        });
    }

    const selectField = (e, interested) =>{
     if(!interested){
        let body = {
            "user_id":currentUser.id,
            "interset_id":e.target.value
         }
         api.post(`user/update-interset`, body).then(res=>{
            getinterset();
         });
     }else{
        let body = {
            "user_id":currentUser.id,
            "id":e.target.value
         }
        api.post(`user/interset-uncheck`, body).then(res=>{
            getinterset();
         });
     }
    }
    const gotoBack = () =>{
        window.history.go(-1)
    }

    return (
        <><div className='padd'>
                <Card variant="outlined" className="padds">
                    <div>
                        <h2>
                        <svg  onClick={()=>gotoBack()} width={15} height={15} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-arrow-left fa-w-14 fa-2x"><path fill="currentColor" d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" class=""></path>
                    </svg> My intrest</h2>
                    </div><hr />
                    {loadings? 
                    <div style={{textAlign:'center'}} className='mt-4 mb-4'>
                       <Loader type="Bars" color="#3e89c9" height={100} width={100}  />
                    </div>
                    :
                   
                    <form>
                            <div className='row pb-4'>
                          
                                {intrest.map((data, i)=><div className="col-md-2">
                               
                                <input class="form-check-input" type="checkbox" id="inlineCheckbox1" 
                                checked={data?.interested} name={data.interest_name} color="primary"
                                value={data.id} onChange={(e)=>selectField(e, data.interested)}/>
                                 <label for="inlineCheckbox1" style={{marginLeft:'5px'}}>{data.interest_name} </label>
                                </div>)}
                            </div>
                    </form>}
                </Card>
        </div>
        </>
    );
}

export default MyIntrest;