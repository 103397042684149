import { createSlice } from '@reduxjs/toolkit'
import api from "../../Api";
import Swal from "sweetalert2";

// Slice
const slice = createSlice({
    name: 'profData',
    initialState: {
        profData: [],
    },
    reducers: {
      userProfulSuccess:(data, action) => {
            data.profData = action.payload;
      },
    },
  });
  
  export default slice.reducer
  // Actions
  const { userProfulSuccess } = slice.actions;
  
  export const getProf= (id) => async dispatch => {
    try {
      const res = await api.get(`/user/msg/Profile/${id}`);
      if(res.data.Status == false){
        Swal.fire({  
          title: 'Warning',  
          text: res.data.Message,  
          icon: 'warning',   
          confirmButtonColor: '#3085d6',  
          confirmButtonText: 'Ok',
          timer: 1500  
        });
      }else{
        dispatch(userProfulSuccess(res.data));
      }
  
    } catch (e) {
      return console.error(e.message);
    }
  }