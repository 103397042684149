import { createSlice } from '@reduxjs/toolkit'
import api from "../../Api";
import Swal from "sweetalert2";

// Slice
const slice = createSlice({
    name: 'success',
    initialState: {
        success: [],
    },
    reducers: {
      MsgSentSuccess:(data, action) => {
            data.success = action.payload;
      },
    },
  });
  
  export default slice.reducer
  // Actions
  const { MsgSentSuccess } = slice.actions;
  
  export const sendMsg = (body) => async dispatch => {
    try {
      const res = await api.post(`user/msg/insert`, body);
      if(res.data.Status == false){
        Swal.fire({  
          title: 'Warning',  
          text: res.data.Message,  
          icon: 'warning',   
          confirmButtonColor: '#3085d6',  
          confirmButtonText: 'Ok',
          timer: 1500  
        });
      }else{
        dispatch(MsgSentSuccess(res.data));
      }
  
    } catch (e) {
      return console.error(e.message);
    }
  }