import React, { useRef, useEffect, useState } from "react";
import { useParams } from "react-router";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Post from "./post/post";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import api from "../Api";
import socket from "../utils/socketNotification";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ReactVideo } from "reactjs-media";
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import PaymentDetails from "../AddCards/patmentpage";
import Tooltip from '@material-ui/core/Tooltip';

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={15}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: 'rgb(255 255 255)',
        paddingTop: '1em'
    },
    imageList: {
        width: "100%",
        height: "100%",
    },
}));



const SubProfile = () => {
    const componentMounted = useRef(true);
    const ids = useParams().path;
    const [value, setValue] = React.useState('1');
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const [userdata, setUserdata] = React.useState({});
    const [cover, setCover] = useState([]);
    const [onerror, setOnerror] = useState(false);
    const [sub, setSub] = React.useState({});
    const [clas, setClas] = React.useState([false]);
    const [photo, setPhoto] = React.useState([]);
    const [video, setVideo] = React.useState([]);
    const [view, setView] = React.useState([]);
    const [post, setPost] = React.useState('');
    const [photocount, setPhotocount] = React.useState('');
    const [videocount, setVideocount] = React.useState('');
    const [matches, setMatches] = React.useState('');
    const [subPaid, setSubPaid] = useState();
    const classes = useStyles();

    var settings = {
        dots: false,
        infinite: true,
        autoPlay: true,
        speed: 500,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1
    };



    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue == 2) {
            api.get(`/post-images-media/${ids}`).then(res => {
                if (res.data.Status != false) {  
                setPhoto(res.data)
                }
            })
        } else if (newValue == 3) {
            api.get(`/post-video-media/${ids}`).then(res => {
                if (res.data.Status != false) {
                setVideo(res.data);
                }
            })
        }
    };

    useEffect(() => {
        // if (componentMounted.current) {
            let body = {
                to_user_id: ids,
                user_id: currentUser.id
            }
            api.post(`/profile-views`, body).then(res => { })
            api.get(`/get-user-profile-views-count/${ids}`).then(res => {
                setView(res.data)
            })
        
            api.get(`/get-single-profile-matcheas/${currentUser.id}/${ids}`).then(res => {
                res.data.forEach(element => {
                    setMatches(element.match_percentage);
                });
            });
           
        // }
        // return () => {
        //     componentMounted.current = false;
        // }
    }, [ids]);

    useEffect(() => {
        api.get(`/get-single-profile-matcheas/${currentUser.id}/${ids}`).then(res => {
            res.data.forEach(element => {
                setMatches(element.match_percentage);
            });
        });
        api.get(`/get-users-media-count/${ids}`).then(res => {
            res.data.forEach(element => {
                setPost(element.post_count + ' POST ');
                setPhotocount(element.image_count + ' PHOTOS ');
                setVideocount(element.video_count + ' VIDEOS ');
            });
        }).catch(err=>{
            console.log(err)
        })
    }, [ids]);

    useEffect(() => {
        getProfile();
    }, [ids]);

    const getProfile = () => {
        let body = {
            id: ids
        }
        api.post('user/Profile', body).then(res => {
            if(res.data.Status != false){
            setUserdata(res.data);
            }
        });
        api.get(`/Profile-subscribation-check/${ids}/${currentUser.id}`).then(res => {
            if(res.data.Status != false){
            setSub(res.data);
            }
        });
        api.get(`user/UserCoverImages/${ids}`).then(res => {
            let array = [];
            if (res.data.Status != false) {
                for (let index = 0; index < res.data.length; index++) {
                    const element = res.data[index];
                    array.push(element, element);   
                }
               setTimeout(() => {
                setCover(array);
               }, 500);
            } else {
                setOnerror(true);
            }
        });
    }

    const handleCallback = (childData) => {
       setSubPaid(false);
       console.log(childData)
       if(childData.Status === true){
        let body = {
            "user_id": currentUser.id,
            "to_user_id": ids,
            "payment_status": childData.id,
            "join_date": new Date
        }
        api.post('/user/subscription/insert', body).then(data => {
            getProfile();
            let body = {
                type: 'subscribed',
                media_id: ids,
                user_id: currentUser.id,
                data: 'has subscribed you.',
                to_user_id: ids,
                notification_type: 'subscription',
                date_time: new Date
            }
            socket.emit('sendNotification', body);
        })
       }
       
    }

    const SubscribeProfile = (paid) => {
        if(paid != ''){
            setSubPaid(true);
        }else{
        let body = {
            "user_id": currentUser.id,
            "to_user_id": ids,
            "payment_status": paid,
            "join_date": new Date
        }
        api.post('/user/subscription/insert', body).then(data => {
            getProfile();
            let body = {
                type: 'subscribed',
                media_id: ids,
                user_id: currentUser.id,
                data: 'has subscribed you.',
                to_user_id: ids,
                notification_type: 'subscription',
                date_time: new Date
            }
            socket.emit('sendNotification', body)
        });
        }
    }

    const contantToggle = (id) => {
        let ClassToggle = {}
        if (!clas[id]) {
            ClassToggle[id] = true
            setClas(ClassToggle);
        } else {
            ClassToggle[id] = false
            setClas(ClassToggle);
        }
    }

    return (
        < >
        <div  >
              {subPaid? <PaymentDetails props={{data:userdata.paid , id:ids, to_user_id:ids , pay_on_type:'profile', payment_type:'subscribe'}} parentCallback={handleCallback} />:null}
            <div className="profile-cover">
                {cover.length != 0 ?
                <Slider {...settings} style={{ height: '106px', objectFit: 'cover' }}>
                    {cover.map((data, i) =>
                        <div key={i} style={{ height: '106px', objectFit: 'cover', width: '100%' }}>
                            <img src={data?.seimagecoverUrl} onError={(event) => event.target.removeAttribute('src')}
                                onError={(event) => event.target.src = '../assets/images/cover/1.jpg'} className="img-fluid blur-up lazyload bg-img" style={{ height: '140px', objectFit: 'cover', width: '100%' }} />
                        </div>)}
                </Slider>:
                    <div style={{ height: '106px', objectFit: 'cover' }}>
                        {onerror ?
                            <img src='' onError={(event) => event.target.removeAttribute('src')}
                                onError={(event) => event.target.src = '../assets/images/cover/1.jpg'} className="img-fluid blur-up lazyload bg-img" style={{ height: '140px', objectFit: 'cover', width: '100%' }} /> :
                            null}
                    </div>}

                <div className="d-flex profilesecpadd">
                    <div className="profile-content d-flex " style={{ padding: '0px 5px' }}>
                        <div className="image-section ms-1">
                            <div className="profile-img" >
                                <Stack direction="row" spacing={2}>
                                    <Avatar alt={userdata?.firstname} src={userdata?.s3url} style={{ width: '80px', height: '80px', top: '0px' }} />
                                </Stack>
                            </div>
                        </div>
                        <div className="profile-detail ps-3 mt-4 pt-2">
                            <h2 style={{ color: '#0389c9' }} >{userdata?.firstname} {userdata?.lastname} <span style={{ color: 'red' }}>❤</span>  </h2>
                            <h5 className="mt-2">
                                <span style={{ color: '#647589b3', fontSize: '12px', cursor: "pointer" }}>@{userdata?.user_name}</span> |<span title="Profile Views" className="ms-2" style={{ color: '#0389c9', cursor:'pointer' }}>
                                <svg aria-hidden="true" width={15} height={10} focusable="false" data-prefix="fas" data-icon="eye" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-eye fa-w-18 fa-2x">
                                   <path fill="currentColor" d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"></path>
                            </svg>
                            {view.map((data, i) => <span key={i}>{ data?.total_view}</span>)} Fans views</span>
                            </h5>

                        </div>
                    </div>
                </div>
                <div className="description profileseDetailscpadd" style={{ background: 'rgb(255 255 255)' }}>
                    <div style={{ padding: '5px 20px', background: 'rgb(255 255 255)' }}> <a title="Profile Views" className="ms-2" style={{ color: '#0389c9' }}>
                        <span>Interest match</span>
                        <span > <LinearProgressWithLabel value={matches} /></span>
                    </a>
                    </div>
                    <div className="post-details" style={{ padding: '0px 25px 10px 25px' }}>
                        <div className="post-details">
                            <div className="detail-box">
                                {clas[userdata?.bio] ?
                                        <div className="">
                                            <p className="pb-2">{userdata?.bio} </p>
                                            <a style={{ color: 'rgba(0, 0, 0, 0.54)' }}><svg style={{ color: 'rgba(0, 0, 0, 0.54)' }} width={15} height={15} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-map-marker-alt fa-w-12 fa-2x">
                                                <path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" class=""></path>
                                            </svg>&nbsp;
                                                {userdata.location ? userdata.location : 'N/A'}</a>
                                            <ul className="socialLinks">
                                                <li style={{ paddingLeft: "0px" }}>
                                                    <a>
                                                        <Tooltip title="facebook profile url" aria-label="facebook profile url">
                                                            <svg style={{ color: '#4267B2' }} aria-hidden="true" width={15} height={15} focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-facebook-f fa-w-10 fa-2x">
                                                                <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" class=""></path>
                                                            </svg>
                                                        </Tooltip>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a>
                                                        <Tooltip title="Instagram profile url" aria-label="Instagram profile url">
                                                            <svg style={{ color: '#8a3ab9' }} aria-hidden="true" width={15} height={15} focusable="false" data-prefix="fab" data-icon="instagram" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-instagram fa-w-14 fa-2x">
                                                                <path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" class=""></path>
                                                            </svg>
                                                        </Tooltip>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a>
                                                        <Tooltip title="Onlyfans profile url" aria-label="Onlyfans profile url">
                                                            <svg style={{ color: '#00AFF0' }} aria-hidden="true" width={15} height={15} focusable="false" data-prefix="fas" data-icon="lock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-lock fa-w-14 fa-2x">
                                                                <path fill="currentColor" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z" class=""></path>
                                                            </svg>
                                                        </Tooltip>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a>
                                                        <Tooltip title="Twitter profile url" aria-label="Twitter profile url">
                                                            <svg style={{ color: '#26a7de' }} aria-hidden="true" width={15} height={15} focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-twitter fa-w-16 fa-2x">
                                                                <path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" class=""></path>
                                                            </svg>
                                                        </Tooltip>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        :
                                        <p className='blogDes'>{userdata?.bio} </p>
                                    }
                                    {userdata?.bio != null && userdata?.bio != '' || userdata?.location != '' ?
                                        <a>
                                            <p onClick={() => contantToggle(userdata?.bio)} style={{ fontSize: '10px', textAlign: 'right', marginTop: '0px', cursor: 'grab', color: '#0389c9' }}>{clas[userdata?.bio] ? <span>Collapse info</span> : <span>More info</span>}</p>
                                        </a>
                                        : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className="sm-p-1" style={{paddingLeft:'3em', paddingRight:'3em'}}>
                            {sub.status != 'Subscribied' ? <button onClick={() => SubscribeProfile(userdata?.paid)} className="btn btn-solid" style={{ width: '100%', borderRadius: '50px' }}><span style={{ float: 'left' }}>
                            <svg aria-hidden="true" width={15} height={15} focusable="false" data-prefix="fas" data-icon="lock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-lock fa-w-14"><path fill="currentColor" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z" class=""></path></svg>
                                 Subscribe</span> {userdata?.for_free != 0 ? <span style={{ float: 'right' }}>For Free</span> : <span style={{ float: 'right' }}>{userdata?.paid} $</span>}</button> :
                                <button onClick={() => SubscribeProfile(userdata?.paid)} className="btn btn-solid" style={{ width: '100%', borderRadius: '50px', background: '#3e89c9', color: 'white' }}><span style={{ textAlign: 'center' }} className="subTitle">
                                   <svg aria-hidden="true" width={15} height={15} focusable="false" data-prefix="fas" data-icon="lock-open" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-lock-open fa-w-18 fa-3x"><path fill="currentColor" d="M423.5 0C339.5.3 272 69.5 272 153.5V224H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48h-48v-71.1c0-39.6 31.7-72.5 71.3-72.9 40-.4 72.7 32.1 72.7 72v80c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24v-80C576 68 507.5-.3 423.5 0z" class=""></path></svg>
                                      Subscribed</span></button>}
                        </div>
                    </div>
                    <div className='col-md-12'>
                    </div>
                </div>
              
                <section className="user-profile">
                    <div className="photo-wrapper section-t-space ">
                        <div className="post-panel post-wrapper" style={{ margin: '10px 0px 0px 0px' }}>
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value} style={{ backgroundColor: '#fff' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab label={post} value="1" />
                                            <Tab label={photocount} value="2" />
                                            <Tab label={videocount} value="3" />
                                        </TabList>
                                    </Box>
                                    {/* Post */}
                                    <TabPanel value="1">
                                        <Post props={ids} />
                                    </TabPanel>
                                    {/* Images */}
                                    <TabPanel value="2">
                                        <div className={classes.root}>
                                            <ImageList rowHeight={160} className={classes.imageList} cols={3}>
                                                {photo.map((item, i) => (
                                                    <ImageListItem key={i} cols={item.cols || 1}>
                                                        <img src={item.s3postmediaurl} alt='{item.title}' />
                                                    </ImageListItem>
                                                ))}
                                            </ImageList>
                                        </div>
                                    </TabPanel>
                                    {/* Videos */}
                                    <TabPanel value="3">
                                        <div className={classes.root}>
                                            <ImageList rowHeight={260} className={classes.imageList} cols={2}>
                                                {video.map((item, i) => (
                                                    <ImageListItem key={i} cols={item.cols || 1}>
                                                        <div className='videocontainer2'>
                                                            <ReactVideo
                                                                poster='../assets/images/icon/logo.png'
                                                                src={item?.s3postmediaurl}
                                                                width='100%'
                                                                height='250px !important'
                                                                controls playsinline />
                                                        </div>
                                                    </ImageListItem>
                                                ))}
                                            </ImageList>
                                        </div>
                                    </TabPanel>
                                </TabContext>
                            </Box>


                        </div>

                    </div>

                </section>

            </div>

            </div>
        </>)

}

export default SubProfile

