import React, { useState} from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { useHistory } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import PaymentDetails from "../AddCards/patmentpage";

export default function SendDialog(props) {
  const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
  const [open, setOpen] = React.useState(true);
  const [scroll, setScroll] = React.useState('paper');
  const [tipdata, setTipdata] = useState('');
  const [subPaid, setSubPaid] = useState();
  const [userData, setUserData] = useState();
  const history = useHistory();

  
  const handleClose = () => {
    setOpen(false);
    props.parentCallback('kjfds')
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    handleClickOpen()
  }, [open]);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
   
    setScroll(scrollType);
  };
  const handleCallback = (childData) => {
    setSubPaid(false);
    handleClose();
}
const tipData = (e) =>{
  setTipdata(e.target.value)
}
  const gotopaymentDetails = () => {
    let data = {
      data:tipdata , 
      id:props.props.ID, 
      to_user_id:props.props.to_user_id,
      pay_on_type:props.props.pay_on_type, 
      payment_type:'tip'
    }
    setUserData(data)
    setTimeout(() => {
      setSubPaid(true)
    },1500);
  }

  return (
    <div>
      <Dialog
        open={open}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">SEND TIP <span style={{float:'right'}}><CloseIcon onClick={handleClose} /></span></DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText id="scroll-dialog-description"
            ref={descriptionElementRef} tabIndex={-1}>
            <div>
              <div>
                <a className="main-link">
                  <div className="media d-none d-sm-flex">
                    <div className="user-img">
                      <Stack direction="row" spacing={2}>
                        <Avatar alt={props.props.firstname} src={props.props.s3profileurl} />
                      </Stack>
                    </div>
                    <div className="media-body d-none d-md-block ml-1">
                      <h4>{props.props.firstname}</h4>
                    </div>
                  </div>
                </a>
                <hr />
                  <div disabled>
                    <div className="form-group mb-4">
                      <input type="text" name="tip" onChange={(e) => tipData(e)} className="form-control" placeholder="$ Tip Amount" />
                      <div className='card-titles'>
                        <p style={{ color: '#00aff0' }}>Please add a payment </p>
                      </div>
                    </div>
                    {/* <div className="form-group mb-4">
                      <input type="email" name="email" className="form-control" placeholder="Message (Optional)" />
                      <div className='card-titles'>
                        <p style={{ color: '#00aff0'}}>Max 100 words</p>
                      </div>
                    </div> */}
                    <div className="row pt-2 pb-3">
                      <div style={{ textAlign: 'center' }}>
                          <button variant="contained"  onClick={()=>gotopaymentDetails()} className="btn btn-solid" > Proceed To Pay</button>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            
          </DialogContentText>
        </DialogContent>
      </Dialog>
      
      {subPaid ? <PaymentDetails props={userData} parentCallback={handleCallback} style={{display:'none'}} />: null}
    </div>
  );
}
