import React from "react";
import { useHistory } from "react-router-dom";
import {useDispatch} from 'react-redux';
import { logout} from '../store/user'


function AccountVerificationDone() {
    const dispatch = useDispatch()
    const history = useHistory();

    const goToPass = () => {
        dispatch(logout())
        history.push('/')
    }


    return (
        <div>
            <section className="login-section">
                <div className="header-section">
                </div>
                <div className="container">
                    <div className="row">
                    <div className="col-xl-6 col-lg-5 d-none d-lg-block">
                    <img style={{ height:'92px'}} src="../assets/images/icon/logo.png" alt="logo" className="img-fluid blur-up lazyload" />
                            <div className="login-welcome">
                                <div style={{textAlign:'left'}}>
                                    <h1 style={{textAlign:'left', fontSize:'34px'}}>Connect with Creators & Fans</h1>
                                    <p className='mt-2' style={{textAlign:'left', color:'white', fontSize:'18px'}}>More than 2 million people in over countries use 'Match With Fans' to stay in touch with creators & fans.</p>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7 col-md-10 col-12 m-auto">
                            <div className="login-form text-center">
                                <div>
                                    <div className="login-title">
                                        <h3>Congratulation!</h3>
                                    </div>
                                    <div className="login-discription">
                                        <h4 style={{color:'green'}}>Your email account has verified.</h4>
                                    </div>
                                    <div className="form-sec">

                                        <div>
                                        <h4 >Now you can enjoy MatchWithFans community.</h4>
                                           <h4 className="mb-4">Please click here to sign in your account </h4>
                                          

                                            <div style={{ textAlign: 'center' }} className="pt-2 pb-2">
                                                <p onClick={goToPass} className="btn btn-primary"><b>Sign in</b></p>
                                            </div>
                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </section>
        </div>
    );
}
export default AccountVerificationDone;

