import React, { useState, useEffect, useRef } from "react";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Post from "./post/post";
import { useDispatch } from 'react-redux';
import { UserProfile } from '../store/Accounts/profile';
import { useHistory } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import api from "../Api";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ReactVideo } from "reactjs-media";
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import PostsCreatess from "./post/postcreatess";
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        paddingBottom: '2em',
        paddingTop: '1em'
    },
    imageList: {
        width: "100%",
        height: "100%",
    },
}));


const Profile = () => {
    const componentMounted = useRef(true);
    const dispatch = useDispatch();
    const [value, setValue] = React.useState('1');
    const [photo, setPhoto] = React.useState([]);
    const [video, setVideo] = React.useState([]);
    const [view, setView] = React.useState([]);
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const [cover, setCover] = useState([]);
    const [onerror, setOnerror] = useState(false);
    const [userData, setUserData] = useState({});
    const history = useHistory();
    const [clas, setClas] = React.useState([false]);
    const classes = useStyles();
    const [post, setPost] = React.useState('');
    const [photocount, setPhotocount] = React.useState('');
    const [videocount, setVideocount] = React.useState('');

    var settings = {
        dots: true,
        infinite: true,
        autoPlay: true,
        speed: 500,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    useEffect(() => {
        if (componentMounted.current) {
            let body = {
                id: currentUser.id
            }
            dispatch(UserProfile(body))
            getProfile();
            getCoverImage(currentUser.id)
            console.log('check count')
            api.get(`/get-users-media-count/${currentUser.id}`).then(res => {
                res.data.forEach(element => {
                    setPost(element.post_count + ' POST ');
                    setPhotocount(element.image_count + ' PHOTOS ');
                    setVideocount(element.video_count + ' VIDEOS ');
                });
            });
        }
        return () => {
            componentMounted.current = false;
        }

    }, []);

    const handleCallback = (childData) => {
        api.get(`/get-users-media-count/${currentUser.id}`).then(res => {
            res.data.forEach(element => {
                setPost(element.post_count + ' POST ');
                setPhotocount(element.image_count + ' PHOTOS ');
                setVideocount(element.video_count + ' VIDEOS ');
            });
        });
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue == 2) {
            api.get(`/post-images-media/${currentUser.id}`).then(res => {
                if (res.data.Status != false) {
                    setPhoto(res.data);
                }
            })
        } else if (newValue == 3) {
            api.get(`/post-video-media/${currentUser.id}`).then(res => {
                if (res.data.Status != false) {
                    setVideo(res.data);
                }
            })
        }
    };

    const getCoverImage = (id) => {
        api.get(`user/UserCoverImages/${id}`).then(res => {
            let array = [];
            if (res.data.Status != false) {
                for (let index = 0; index < res.data.length; index++) {
                    const element = res.data[index];
                    array.push(element, element);   
                }
               setTimeout(() => {
                setCover(array);
               }, 500);
            } else {
                setOnerror(true);
            }
        });
        // api.get(`/get-user-profile-views-count/${id}`).then(res => {
        //     if(res.data.Status != false){
        //     setView(res.data)
        //     }
        // })
    }

    const getProfile = () => {
        let body = {
            id: currentUser.id
        }
        api.post('user/Profile', body).then(res => {
            if (res.data) {
                setUserData(res.data);

            }
        })
    }


    const goToeditProfile = () => {
        history.push('/profile/edit-profile')
    }

    const contantToggle = (id) => {
        let ClassToggle = {}
        if (!clas[id]) {
            ClassToggle[id] = true
            setClas(ClassToggle);
        } else {
            ClassToggle[id] = false
            setClas(ClassToggle);
        }
    }



    const [imageToShow, setImageToShow] = useState("");
    const [lightboxDisplay, setLightBoxDisplay] = useState(false);
  
    //function to show a specific image in the lightbox, amd make lightbox visible
    const showImage = (image) => {
      setImageToShow(image);
      setLightBoxDisplay(true);
    };
  
    //hide lightbox
    const hideLightBox = () => {
      setLightBoxDisplay(false);
    };
  
    // //show next image in lightbox
    // const showNext = (e) => {
    //   e.stopPropagation();
    //   let currentIndex = photo.indexOf(imageToShow);
    //   console.log(currentIndex)
    //   if (currentIndex >= photo.length - 1) {
    //     setLightBoxDisplay(false);
    //   } else {
    //     let nextImage = photo[currentIndex + 1];
    //     setImageToShow(nextImage);
    //   }
    // };
  
    // //show previous image in lightbox
    // const showPrev = (e) => {
    //   e.stopPropagation();
    //   let currentIndex = photo.indexOf(imageToShow);
    //   if (currentIndex <= 0) {
    //     setLightBoxDisplay(false);
    //   } else {
    //     let nextImage = photo[currentIndex - 1];
    //     setImageToShow(nextImage);
    //   }
    // };

    return (
        <>
            <div className=''>
                {userData == null ? <div className="pre-loader">
                    <div className="content-left">
                        <div className="suggestion-box section-t-space">

                            <div className="suggestion-content ratio_115">
                                <div className="slide-2 no-arrow default-space">
                                    <div>
                                        <div className="story-box">
                                            <div className="story-bg bg-size"></div>
                                            <div className="card-title pt-2">
                                                <h3 />
                                            </div>
                                            <div className="card-title pt-2">
                                                <h3 />
                                            </div>
                                            <div className="card-title pt-2">
                                                <h3 />
                                            </div>
                                            <div className="ldr-btn btn"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> :
                    <div>
                        <div className="profile-cover">
                            {cover.length != 0 ?
                                <Slider {...settings} style={{ height: '106px', objectFit: 'cover' }}>
                                    {cover.map((data, i) =>
                                        <div key={i} style={{ height: '106px', objectFit: 'cover', width: '100%' }}>
                                            <img src={data?.seimagecoverUrl} onError={(event) => event.target.removeAttribute('src')}
                                                onError={(event) => event.target.src = '../assets/images/cover/1.jpg'} className="img-fluid blur-up lazyload bg-img" style={{ height: '140px', objectFit: 'cover', width: '100%' }} />
                                        </div>)}
                                </Slider> :
                                <div style={{ height: '106px', objectFit: 'cover' }}>
                                    {onerror ?
                                        <img src='' onError={(event) => event.target.removeAttribute('src')}
                                            onError={(event) => event.target.src = '../assets/images/cover/1.jpg'} className="img-fluid blur-up lazyload bg-img" style={{ height: '140px', objectFit: 'cover', width: '100%' }} /> :
                                        null}
                                </div>}
                            <div className="d-flex profilesecpadd">
                                <div className="profile-content">
                                    <div className="image-section">
                                        <div className="profile-img">
                                            <div>
                                                <Stack direction="row" spacing={2}>
                                                    <Avatar alt={userData?.firstname} src={userData.s3url} style={{ width: '80px', height: '80px', top: '0px' }} />
                                                </Stack>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="profile-detail ps-3 mt-4 pt-1">
                                    <h2 style={{ color: '#0389c9' }}>{userData?.firstname} {userData?.lastname} <span style={{ color: 'red' }}>❤</span></h2>
                                    <h5 className="mt-2" style={{ color: '#647589b3' }}>@{userData?.user_name} <span title="Profile Views" className="ms-2" style={{ color: '#0389c9', cursor:'pointer' }}>
                                        <svg aria-hidden="true" width={15} height={10} focusable="false" data-prefix="fas" data-icon="eye" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="svg-inline--fa fa-eye fa-w-18 fa-2x">
                                            <path fill="currentColor" d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z" class=""></path>
                                        </svg>
                                        {view.map(data => <span>{data?.total_view}</span>)} Fans views</span></h5>
                                </div>
                                <div id='edit' className="setting-dropdown  custom-dropdown arrow-none dropdown-sm " style={{ position: 'absolute', right: '25px' }}>
                                    <Tooltip title="Edit profile">
                                        <IconButton className='btn-solid' aria-label="delete" size="large" onClick={goToeditProfile} style={{ borderRadius: '50%', fontSize: '15px' }}>
                                            <EditIcon fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="description " style={{ padding: '0px 25px 10px 25px' }}>
                                <div className="post-details">
                                    <div className="detail-box">
                                        {clas[userData?.bio] ?
                                            <div className="post-details">
                                                <p>{userData?.bio} </p>
                                                <a style={{ color: 'rgba(0, 0, 0, 0.54)' }}><svg style={{ color: 'rgba(0, 0, 0, 0.54)' }} width={15} height={15} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="svg-inline--fa fa-map-marker-alt fa-w-12 fa-2x">
                                                    <path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" class=""></path>
                                                </svg>&nbsp;
                                                    {userData.location ? userData.location : 'N/A'}</a>
                                                <ul className="socialLinks">
                                                    <li style={{ paddingLeft: "0px" }}>
                                                        <Tooltip title="facebook Profile" aria-label="facebook Profile">
                                                            <svg style={{ color: '#4267B2' }} aria-hidden="true" width={15} height={15} focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-facebook-f fa-w-10 fa-2x">
                                                                <path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" class=""></path>
                                                            </svg>
                                                        </Tooltip>
                                                    </li>
                                                    <li>
                                                        <Tooltip title="Instagram Profile" aria-label="Instagram Profile">
                                                            <svg style={{ color: '#8a3ab9' }} aria-hidden="true" width={15} height={15} focusable="false" data-prefix="fab" data-icon="instagram" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-instagram fa-w-14 fa-2x">
                                                                <path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" class=""></path>
                                                            </svg>
                                                        </Tooltip>
                                                    </li>
                                                    <li>
                                                        <Tooltip title="Onlyfans Profile" aria-label="Onlyfans Profile">
                                                            <svg style={{ color: '#00AFF0' }} aria-hidden="true" width={15} height={15} focusable="false" data-prefix="fas" data-icon="lock" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-lock fa-w-14 fa-2x">
                                                                <path fill="currentColor" d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z" class=""></path>
                                                            </svg>
                                                        </Tooltip>
                                                    </li>
                                                    <li>
                                                        <Tooltip title="Twitter Profile" aria-label="Twitter Profile">
                                                            <svg style={{ color: '#26a7de' }} aria-hidden="true" width={15} height={15} focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-twitter fa-w-16 fa-2x">
                                                                <path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" class=""></path>
                                                            </svg>
                                                        </Tooltip>
                                                    </li>
                                                </ul>
                                            </div> : <p className='blogDes' style={{ color: '#51595e' }}>{userData?.bio} </p>
                                        }
                                        {userData?.bio != null && userData?.bio != '' || userData?.location != '' ? 
                                         <p onClick={() => contantToggle(userData?.bio)} style={{ fontSize: '10px', textAlign: 'right',cursor:'pointer', marginTop: '0px', color: '#0389c9' }}>{clas[userData?.bio] ? <span>Less...</span> : <span>More...</span>}</p>
                                         : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                <section className='mt-4'>
                    <PostsCreatess props={'data'} parentCallback={handleCallback} />
                </section>


                <section className="user-profile">
                    <div className="photo-wrapper section-t-space ">
                        <div className="post-panel post-wrapper" style={{ margin: '10px 0px 0px 0px' }}>
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value} style={{ backgroundColor: '#fff' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab label={post} value="1" />
                                            <Tab label={photocount} value="2" />
                                            <Tab label={videocount} value="3" />
                                        </TabList>
                                    </Box>

                                    <TabPanel value="1">
                                        <Post />
                                    </TabPanel>

                                    {/* Images */}
                                    <TabPanel value="2">
                                        {
                                            lightboxDisplay ?
                                                <div id="lightbox">
                                                   <a className="lightClose"  onClick={hideLightBox}>
                                                   <svg style={{position:'absolute', left:'5px', top:'8px'}} className="CloseIcons" aria-hidden="true" width={20} height={12} focusable="false" data-prefix="fas" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" class="svg-inline--fa fa-times fa-w-11 fa-2x">
                                                       <path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" class=""></path>
                                                   </svg>
                                                   </a>
                                                    <img id="lightbox-img" src={imageToShow}></img>
                                                    {/* <button onClick={showNext}>⭢</button> */}
                                                </div>
                                                : ""
                                        }

                                        <div className={classes.root}>
                                            <ImageList rowHeight={160} className={classes.imageList} cols={3}>
                                                {photo.map((item) => (
                                                    <ImageListItem key={item.media} cols={item.cols || 1}>
                                                        <img onClick={() => showImage(item.s3postmediaurl)} src={item.s3postmediaurl} alt='{item.title}' />
                                                    </ImageListItem>
                                                ))}
                                            </ImageList>
                                        </div>
                                        {photo.length == 0 ? <div style={{ textAlign: 'center' }} className='mt-4 mb-4'>
                                            <h3> No data Available.</h3>
                                        </div> : null}
                                    </TabPanel>

                                    {/* Videos */}
                                    <TabPanel value="3">
                                        <div className={classes.root}>
                                            <ImageList rowHeight={260} className={classes.imageList} cols={2}>
                                                {video.map((item) => (
                                                    <ImageListItem key={item.media} cols={item.cols || 1}>
                                                        <div className='videocontainer2'>
                                                            <ReactVideo
                                                                poster='../assets/images/icon/logo.png'
                                                                src={item?.s3postmediaurl}
                                                                width='100%'
                                                                height='250px !important'
                                                                controls playsinline />
                                                        </div>
                                                    </ImageListItem>
                                                ))}
                                            </ImageList>
                                        </div>
                                        {video.length == 0 ? <div style={{ textAlign: 'center' }} className='mt-4 mb-4'>
                                            <h3> No data Available.</h3>
                                        </div> : null}
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </div>
                    </div>
                </section>
            </div>
        </>)

}

export default Profile