import * as React from 'react';
import { useEffect, useState } from "react";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MessageIcon from '@mui/icons-material/Message';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import TheatersIcon from '@mui/icons-material/Theaters';
import SettingsIcon from '@mui/icons-material/Settings';
import Chip from '@mui/material/Chip';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../store/user'
import { useHistory } from "react-router-dom";
import PolicyIcon from '@mui/icons-material/Policy';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

function SideHeader() {
  const dispatch = useDispatch()
  const currentUser = JSON.parse(sessionStorage.getItem('user' || null))
  const history = useHistory();
  const handleClick = () => {
    dispatch(logout());
    history.push('/')
  };

  useEffect(() => {
    let url = window.location.href
    console.log(url.slice(22, 50))
  }, []);


  const gotoHome = () => {
    history.push('/home');
  };
  const gotoNotification = () => {
    history.push('/notification');
  };
  const gotoMessage = () => {
    history.push(`/inbox/${currentUser.id}`);
  };
  const gotoSubscriptions = () => {
    history.push('/Mysubscriptions/');
  };
  const gotoMedia = () => {
    // history.push('/home');
  };
  const gotoSettings = () => {
    history.push('/profile/accounts');
  };
  const gotoMyProfile = () => {
    history.push('/User/profile');
  };


  const goToeditProfile = () => {
    history.push('/profile/edit-profile')
  };
  const gotoPrivacyandPolicy = () => {
    history.push('/profile/privacy')
  }
  const gotoWhatIsNew = () => {
    history.push('/profile/whats-new')
  }

  const gotomyIntrest = () => {
    history.push('/profile/my-intrest')
  }
  const gotomyWallet = () => {
    history.push('/wallet')
  }



  return (
    <div className="conatainer-fluid" style={{ paddingLeft: '5px' }}>
      <List className='rightborder' sx={{ width: '100%', maxWidth: 260 }}>
        <Link className=" main-link" to="/User/profile" data-bs-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <div className="media  d-sm-flex" style={{ padding: '14px 14px 0px 14px' }}>
            <div className="user-img">
              <Stack direction="row" spacing={5}>
                <Avatar alt={currentUser?.full_name} src={currentUser?.s3profileurl} />
              </Stack>
              <span className="available-stats online" ></span>
            </div>
            <div className="media-body  d-md-block ml-1" style={{ paddingLeft: '15px' }}>
              <h4>{currentUser?.full_name} <span className="inbox available-stats online " style={{ position: 'absolute', left: '18%' }}></span></h4>
              <span>@{currentUser?.user_name}</span>
            </div>
          </div>
        </Link><hr></hr>

        <ListItemButton onClick={gotoHome}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
        <ListItemButton onClick={gotoNotification}>
          <ListItemIcon>
            <NotificationsActiveIcon />
          </ListItemIcon>
          <ListItemText primary="Notification" />
        </ListItemButton>

        <ListItemButton onClick={gotoMessage}>
          <ListItemIcon>
            <MessageIcon />
          </ListItemIcon>
          <ListItemText primary="Message" />
        </ListItemButton>

        <ListItemButton onClick={gotoSubscriptions}>
          <ListItemIcon>
            <NotificationsActiveIcon />
          </ListItemIcon>
          <ListItemText primary="Subscriptions" />
        </ListItemButton>

        <ListItemButton onClick={gotoSettings}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItemButton>

        <ListItemButton onClick={gotoMyProfile}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText primary="My Profile" />
        </ListItemButton>

        <ListItemButton onClick={gotomyIntrest}>
          <ListItemIcon>
            <SupervisedUserCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Interest" />
        </ListItemButton>

        <ListItemButton onClick={gotomyWallet}>
          <ListItemIcon>
            <AccountBalanceWalletIcon />
          </ListItemIcon>
          <ListItemText primary="Wallet" />
        </ListItemButton>


        <ListItemButton onClick={gotoPrivacyandPolicy}>
          <ListItemIcon>
            <PolicyIcon />
          </ListItemIcon>
          <ListItemText primary="Privacy and safety" />
        </ListItemButton>

        <ListItemButton onClick={gotoWhatIsNew}>
          <ListItemIcon>
            <HelpOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="What's New" />
        </ListItemButton>

        <div className="container-fluid">
          <Stack direction="row" spacing={4} className="pt-2 pb-2">
            <Chip label="Log-out" onClick={handleClick} style={{ width: "100%" }} />
          </Stack>
        </div>
      </List>
    </div>

  );
}

export default SideHeader;
