import * as React from 'react';
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useFormik } from 'formik';
import api from "../Api";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Swal from "sweetalert2";

const validateUser = RegisterData => {
    const errors = {};
    const pattern = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');
    if (!RegisterData.password) {
        errors.password = '*Please Enter Password';
    } else if (!pattern.test(RegisterData.password)) {
        errors.password = `*Password Minimum eight characters, at
        least one uppercase letter, one lowercase letter, one number and one
        special character`;
    }

    if (!RegisterData.confirm_password) {
        errors.confirm_password = 'Please Enter Confirm Password';
    } else if (!pattern.test(RegisterData.confirm_password)) {
        errors.confirm_password = `*Password Minimum eight characters, at
        least one uppercase letter, one lowercase letter, one number and one
        special character`;
    }

    if (!RegisterData.email) {
        errors.email = '*Please Enter Email';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(RegisterData.email)) {
        errors.email = '*Invalid email address';
    }
    if (!RegisterData.firstname) {
        errors.name = '*Please Enter First Name';
    }
    if (!RegisterData.lastname) {
        errors.name = '*Please Enter Last Name';
    }
    return errors;
};

function SignUp() {
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isRevealPwd2, setIsRevealPwd2] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();


    const formik = useFormik({
        initialValues: {
            password: '',
            email: '',
            confirm_password: '',
            firstname: '',
            lastname: '',
            agree_terms: true,
        },
        validate: validateUser,
        onSubmit: values => {
            let body = {
                password: values.password,
                email: values.email,
                firstname: values.firstname,
                lastname: values.lastname,
            }
            setLoading(true);
            api.post('user/insert', body).then(res=>{
                setLoading(false);
                if(res.data.Status === false){
                    Swal.fire({  
                      title: 'Warning',  
                      text: res.data.Message,  
                      icon: 'warning',   
                      confirmButtonColor: '#3085d6',  
                      confirmButtonText: 'Ok',
                      timer: 1500  
                    });
                  }else{
                    Swal.fire({  
                      title: 'Successs',  
                      text: res.data.Message,  
                      icon: 'success',   
                      confirmButtonColor: '#3085d6',  
                      confirmButtonText: 'Yes!',
                      timer: 1500  
                    });
                    goToLogin();
                  }
                });
        }
    });
    const goToLogin = () => {
        sessionStorage.removeItem('user');
        history.push('/')
    }

    return (
        <div>

            <section className="login-section">
                <div className="header-section">
                </div>
                <div className="container">
                    <div className='row '>
                        <div className='col-xl-4 col-lg-4 col-md-4'>
                            <img src="../assets/images/icon/logo.png" alt="logo" className="img-fluid blur-up lazyload" />
                        </div>
                        <div className='col-xl-8 col-lg-8 col-md-8'></div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-xl-8 col-lg-5 d-none d-lg-block pt-5">
                            <div className="login-welcome">
                                <div style={{ textAlign: 'left' }}>
                                    <h1 style={{ textAlign: 'left', fontSize: '34px' }}>Connect with Creators & Fans</h1>
                                    <p className='mt-4' style={{ textAlign: 'left', color: 'white', fontSize: '18px' }}>More than 2 million people in over countries use 'Match With Fans' to stay in touch with creators & fans.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-7 col-md-10 col-12 m-auto">
                            <div className="login-form">
                                <div>
                                    <div className="login-title">
                                        <h3>Create your account</h3>
                                    </div>
                                    <div className="form-sec">

                                        <div>
                                            <form className="theme-form">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" name="firstname" placeholder="First Name" value={formik.values.firstname}
                                                                onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                            {formik.touched.firstname && formik.errors.firstname ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.firstname}</span> : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" name="lastname" placeholder="Last Name" value={formik.values.lastname}
                                                                onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                            {formik.touched.lastname && formik.errors.lastname ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.lastname}</span> : null}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <input type="email" className="form-control" name="email" placeholder="Email ID" id="login_email" value={formik.values.email}
                                                                onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                            {formik.touched.email && formik.errors.email ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.email}</span> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="form-group col-md-12">
                                                        <input type={isRevealPwd ? "text" : "password"} name="password" className="form-control" id="password" value={formik.values.password}
                                                            onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Password" />
                                                        <div className="passIcon">
                                                            {isRevealPwd ? <VisibilityOffIcon onClick={() => setIsRevealPwd(prevState => !prevState)} /> :
                                                                <VisibilityIcon onClick={() => setIsRevealPwd(prevState => !prevState)} />
                                                            }
                                                        </div>
                                                        {formik.touched.password && formik.errors.password ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.password}</span> : null}
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        <input type={isRevealPwd2 ? "text" : "password"} name="confirm_password" className="form-control" value={formik.values.confirm_password}
                                                            onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Confirm Password" />
                                                        <div className="passIcon">
                                                            {isRevealPwd2 ? <VisibilityOffIcon onClick={() => setIsRevealPwd2(prevState => !prevState)} /> :
                                                                <VisibilityIcon onClick={() => setIsRevealPwd2(prevState => !prevState)} />
                                                            }
                                                        </div>
                                                        {formik.touched.confirm_password && formik.errors.confirm_password ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.confirm_password}</span> : null}
                                                    </div>
                                                </div>

                                                <div className="row pt-2 pb-2">
                                                    <div className="col-md-2" />
                                                    <div className="col-md-8">
                                                        <div style={{ textAlign: 'center' }}>
                                                            <Stack >
                                                                <Button variant="contained" disabled={!formik.isValid} className='br' onClick={formik.handleSubmit}>
                                                                    {loading ? <span><span className="spinner-border spinner-border-sm"></span> Please wait...</span> : 'Sign Up'}</Button>
                                                            </Stack>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2" />
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-1" />
                                                    <div className="col-md-10">
                                                        <div className="form-group text-center pt-2 pb-2">
                                                            <span style={{ fontSize: '12px' }}>By signing up you agree to our
                                                                <span style={{ fontSize: '12px' }}><span style={{ color: 'blue' }}>Terms of Service</span></span> and <span><span style={{ color: 'blue' }}>Privacy Policy</span></span>, and confirm that you are at least 18 years old.</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-1" />
                                                </div>
                                            </form>


                                            <div style={{ textAlign: 'center' }} className="pb-2">
                                                <span onClick={()=>goToLogin()}><span>Already have an account? </span><span style={{ color: 'blue' }}>Sign In</span></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
export default SignUp;