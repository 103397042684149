import { createSlice } from '@reduxjs/toolkit'
import api from "../Api";
import Swal from "sweetalert2";
// Slice
const slice = createSlice({
  name: 'UserSignUp',
  initialState: {
    UserSignUp: null,
  },
  reducers: {
    RegisterSuccess: (states, action) => {
      states.UserSignUp = action.payload;
      console.log(states.UserSignUp)
    },
  },
});

export default slice.reducer
// Actions
const { RegisterSuccess } = slice.actions;
export const signup = (body) => async dispatch => {
  try {
    const res = await api.post('user/insert', body);
    console.log(res)
    if(res.data.Status == false){
      Swal.fire({  
        title: 'Warning',  
        text: res.data.Message,  
        icon: 'warning',   
        confirmButtonColor: '#3085d6',  
        confirmButtonText: 'Ok',
        timer: 1500  
      });
    }else{
      Swal.fire({  
        title: 'Successs',  
        text: res.data.Message,  
        icon: 'success',   
        confirmButtonColor: '#3085d6',  
        confirmButtonText: 'Yes!',
        timer: 1500  
      });
      dispatch(RegisterSuccess(res.data));
    }

  } catch (e) {
    return console.error(e.message);
  }
}


