import React from 'react'
import Box from '@mui/material/Box';
import { Divider, List, ListItem } from 'semantic-ui-react';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
const Footer = () => {

    return (
        <footer>
            <div className='container-fluid py-4' style={{ backgroundColor: 'white' }}>
                <div className="container">
                    <div className="row">


                        <div className="col-xl-2 col-lg-2 col-md-2 col-xs-6">
                            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

                                <List component="nav" aria-label="secondary mailbox folder">
                                    <ListItemButton>
                                        <ListItemText primary="Help" />
                                    </ListItemButton>

                                    <ListItemButton>
                                        <ListItemText primary="How It Works" />
                                    </ListItemButton>
                                </List>
                            </Box>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-xs-6">
                            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

                                <List component="nav" >
                                    <ListItemButton>
                                        <ListItemText primary="About" />
                                    </ListItemButton>
                                    <ListItemButton>
                                        <ListItemText primary="Term of Service" />
                                    </ListItemButton>



                                </List>
                            </Box>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-xs-6">
                            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

                                <List component="nav">
                                    <ListItemButton>
                                        <ListItemText primary="Blog" />
                                    </ListItemButton>

                                    <ListItemButton>
                                        <ListItemText primary="Referrals" />
                                    </ListItemButton>

                                </List>
                            </Box>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-xs-6">
                            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

                                <List component="nav">

                                    <ListItemButton>
                                        <ListItemText primary="Privacy" />
                                    </ListItemButton>

                                    <ListItemButton>
                                        <ListItemText primary="Contact" />
                                    </ListItemButton>
                                </List>
                            </Box>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-xs-6">
                            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

                                <List component="nav">
                                    <ListItemButton>
                                        <ListItemText primary="USC 2257" />
                                    </ListItemButton>

                                    <ListItemButton>
                                        <ListItemText primary="Acceptable Use Policy" />
                                    </ListItemButton>

                                </List>
                            </Box>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-xs-6" >
                            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

                                <List component="nav" aria-label="secondary mailbox folder">
                                    

                                    <ListItem>
                                        <Stack direction="row" spacing={1}>
                                            <IconButton aria-label="FacebookIcon" style={{ color: '#385cb4' }}>
                                                <FacebookIcon />
                                            </IconButton>
                                            <IconButton aria-label="InstagramIcon" style={{ color: '#8a3ab9' }}>
                                                <InstagramIcon />
                                            </IconButton>
                                            <IconButton aria-label="TwitterIcon" style={{ color: '#00a0dd' }}>
                                                <TwitterIcon />
                                            </IconButton>
                                        </Stack>

                                    </ListItem>
                                    <ListItemButton>
                                        <ListItemText primary="©2021 Match With Fans" />
                                    </ListItemButton>
                                </List>
                            </Box>
                        </div>

                    </div>

                </div>
            </div>
        </footer>
    )
}

export default Footer
