import { createSlice } from '@reduxjs/toolkit'
import api from "../../Api";
import Swal from "sweetalert2";

// Slice
const slice = createSlice({
    name: 'alluser',
    initialState: {
        alluser: null,
    },
    reducers: {
      AllUserSuccess:(state, action) => {
          state.alluser = action.payload;
      },
    },
  });
  
  export default slice.reducer
  // Actions
  const { AllUserSuccess } = slice.actions;
  
  export const UserAlls = (id) => async dispatch => {
    try {
      const res = await api.get(`user/All/${id}`);
      var arr = [];
      res.data.forEach(element => {
          let body = {
            label:element.firstname,
            ID:element.ID 
          }
          arr.push(body)
      });
        dispatch(AllUserSuccess(arr));
    } catch (e) {
      return console.error(e.message);
    }
  }