import { createSlice } from '@reduxjs/toolkit'
import api from "../../Api";
import Swal from "sweetalert2";

// Slice
const slice = createSlice({
    name: 'CUpost',
    initialState: {
        CUpost: [],
    },
    reducers: {
        CUserPostSuccess:(data, action) => {
            data.CUpost = action.payload;
      },
    },
  });
  
  export default slice.reducer
  // Actions
  const { CUserPostSuccess } = slice.actions;
  
  export const CUserPost = (id, uid) => async dispatch => {
    try {
      const res = await api.get(`/get-user-post/${id}/${uid}`);
      if(res.data.Status == false){
        // Swal.fire({  
        //   title: 'Warning',  
        //   text: res.data.Message,  
        //   icon: 'warning',   
        //   confirmButtonColor: '#3085d6',  
        //   confirmButtonText: 'Ok',
        //   timer: 1500  
        // });
      }else{
        dispatch(CUserPostSuccess(res.data));
      }
  
    } catch (e) {
      return console.error(e.message);
    }
  }