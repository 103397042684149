import { createSlice } from '@reduxjs/toolkit'
import api from "../../Api";
import Swal from "sweetalert2";

// Slice
const slice = createSlice({
    name: 'userupdateProfile',
    initialState: {
        userupdateProfile: null,
    },
    reducers: {
        UserUpdateProfileSuccess:(state, action) => {
          state.userupdateProfile = action.payload;
      },
    },
  });
  
  export default slice.reducer
  // Actions
  const { UserUpdateProfileSuccess } = slice.actions;
  
  export const UserUpdateProfile = (body) => async dispatch => {
    try {
      const res = await api.post('user/UpdateProfile', body);
      if(res.data.Status == false){
        Swal.fire({  
          title: 'Warning',  
          text: res.data.Message,  
          icon: 'warning',   
          confirmButtonColor: '#3085d6',  
          confirmButtonText: 'Ok',
          timer: 1500  
        });
      }else{
        Swal.fire({  
            title: 'Successs',  
            text: res.data.Message,  
            icon: 'success',   
            confirmButtonColor: '#3085d6',  
            confirmButtonText: 'Yes!',
            timer: 1500  
          });
        dispatch(UserUpdateProfileSuccess(res.data));
      }
  
    } catch (e) {
      return console.error(e.message);
    }
  }