import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import moment from "moment";
import Button from '@mui/material/Button';

function LookingFords() {
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
       
    }, []);
    
    return (
        <><div className='padd'>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '50ch' },
                }}
                noValidate
                autoComplete="off">
                <Card variant="outlined" className="padds">
                    <div>
                        <h2>Looking for</h2>
                    </div><hr />
                    <form>
                        <div className='row'>
                            <div className="col-md-6">
                                <TextField style={{ width: '100%' }} label="Sort Tag line" variant="filled" />
                            </div>
                            <div className="col-md-6">
                                <TextField style={{ width: '100%' }} label="What are you looking for" variant="filled" />
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-md-12">
                                <TextField style={{ width: '100%' }} label="Additional Information" variant="filled" />
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-8"></div>
                            <div className="col-md-4 text-end">
                                <div style={{ textAlign: 'center' }}>
                                    <Stack className='pt-4 pb-4'>
                                        <Button variant="contained" className='br' >
                                            {loading ? <span><span className="spinner-border spinner-border-sm"></span> Please wait...</span> : 'Update'}</Button>

                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </form>
                </Card>
            </Box>
        </div>
        </>
    );
}

export default LookingFords;