

function isPushNotificationSupported() {
  return "serviceWorker" in navigator && "PushManager" in window;
}

async function askUserPermission() {
  return await Notification.requestPermission();
}

function sendNotification(data) {
  // const img = "https://api.matchwithfans.com/upload/post/image-1639490836184.webp";
  const text = `${data.data}`;
  const title = `${data.firstname} ${data.lastname}`;
  const options = {
    body: text,
    icon: "https://cdn.dribbble.com/users/3337757/screenshots/6650748/notif-dribbble-video.gif",
    vibrate: [400, 200, 400],
    tag: "new-product",
    // image: img,
    badge: `https://api.matchwithfans.com/upload/profile/${data.profile_image}`,
    actions: [{ action: "Detail", title: "View", icon: "http://localhost:3001/User/profile" }]
  };
  navigator.serviceWorker.ready.then(function(serviceWorker) {
    serviceWorker.showNotification(title, options);
  });
}

function registerServiceWorker() {
  return navigator.serviceWorker.register("/sw.js");
}

export {
  isPushNotificationSupported,
  askUserPermission,
  registerServiceWorker,
  sendNotification,
};
