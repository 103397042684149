import * as React from 'react';
import { useRef, useEffect } from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import {useDispatch, useSelector} from 'react-redux';
import { updateNewData } from '../../store/Accounts/whatsNew';
import moment from "moment";

function WhatIsNew() {
    const componentMounted = useRef(true);
    const dispatch = useDispatch();
    const { whatsNew } = useSelector(res => res)
  
    useEffect(() => {
        if (componentMounted.current) {
            dispatch(updateNewData()) 
        }
        return () => {
            componentMounted.current = false;
        }
      },[]);

    return (
        <><div className='padd'>
            <Box sx={{ minWidth: 275 }} className='mt-2 '>
                <Card variant="outlined" className="padds">
                    <div>
                        <h2>WHAT'S NEW</h2>
                    </div><hr />
                  {whatsNew.updateNew.length != 0 ?<div>
                   {whatsNew.updateNew.map(data=> <div>
                        <div className='card-titles mb-3'>
                            <h3 className='mb-2'>{moment(data?.month).format('LL').substring(0, 9)}</h3><hr/>
                            <h3 className='mb-2'>{moment(data?.date).format('ll')}</h3>
                            <p>{data?.text}</p>
                        </div><hr />
                        
                    </div>)}
                   </div>:
                   <div><h2 style={{textAlign:'center'}} className='mb-4 mt-4'>Data Not available.</h2></div>}
                </Card>
            </Box>
        </div>
        </>
    );
}

export default WhatIsNew;