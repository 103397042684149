import { useFormik } from 'formik';
import React, { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { UserPost } from '../../../store/post/post';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import api from "../../../Api";
import CloseIcon from '@mui/icons-material/Close';
import Dropdown from 'react-bootstrap/Dropdown';
import socket from "../../../utils/socketNotification";
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import IconButton from '@material-ui/core/IconButton';
import { Video } from "reactjs-media";

const input = styled('input')({
    display: 'none',
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));
const useStyless = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    imageList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
}));


const validateUser = post => {
    const errors = {};
    if (!post.post_Content) {
        errors.post_Content = '*Please Enter compose message...';
    }

    if (!post.payment_type) {
        errors.email = '*Please select post type';
    }
    return errors;
};

function PostsCreate(prop) {
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const dispatch = useDispatch();
    const [imagesd, setImagesd] = useState([]);
    const [newImageData, setNewImageData] = useState([]);
    const [getImages, setGetImages] = useState([]);
    const [loadingimage, setLoadingimage] = useState(false);
    const [fileType, setFileType] = useState([]);
    const [datapost, setDatapost] = useState(false);
    const [loadings, setLoadings] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [paid, setPaid] = useState(false);
    const [paymentType, setPaymentType] = useState('free')
    const history = useHistory();
    const classes = useStyles();
    const classess = useStyless();

    const onImageChange = (event) => {
        let images = [];
        let newimages = [];
        var arr = [];
        var newarr = []
        setLoadingimage(true);
        setDisabled(true)
        if (imagesd.length == 0) {
            for (let index = 0; index < event.target.files.length; index++) {
                arr.push(event.target.files[index])
                let reader = new FileReader();
                setFileType(event.target.files[index].type)
                reader.readAsDataURL(event.target.files[index]);
                reader.onload = (_event) => {
                    images.push({ img: reader.result, type: event.target.files[index].type })
                    setTimeout(() => {
                        setImagesd(images);
                        setLoadingimage(false);
                    }, 500);
                }
            }
            setTimeout(() => {
                setNewImageData(arr);
            }, 1000);
        } else {
            for (let index = 0; index < event.target.files.length; index++) {
                newarr.push(event.target.files[index])
                let reader = new FileReader();
                setFileType(event.target.files[index].type)
                reader.readAsDataURL(event.target.files[index]);
                reader.onload = (_event) => {
                    newimages.push({ img: reader.result, type: event.target.files[index].type })
                    setTimeout(() => {
                        setImagesd([...imagesd, ...newimages]);
                        setLoadingimage(false);
                    }, 500);
                }
            }
            setTimeout(() => {
                setNewImageData([...newImageData, ...newarr])
            }, 1500);
        }
    };


    const removeMedia = (index, img) => {
        setImagesd([
            ...imagesd.slice(0, index),
            ...imagesd.slice(index + 1)
        ]);
        setNewImageData([
            ...newImageData.slice(0, index),
            ...newImageData.slice(index + 1)
        ]);
    }

    const formik = useFormik({
        initialValues: {
            post_Content: '',
            payment_type: 'free',
            amount: ''
        },
        validate: validateUser,
        onSubmit: (values, { resetForm }) => {
            setLoadings(true);
            const formData = new FormData();
            formData.append('user_id', currentUser.id);
            formData.append('content', values.post_Content);
            formData.append('post_for_free', paymentType);
            formData.append('post_for_paid', values.amount);
            newImageData.forEach((element, i) => {
                formData.append('image', element);
            });

            api.post('/user/create/post', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }).then(data => {
                let body = {
                    type: 'post',
                    media_id: data.data.post_id,
                    user_id: currentUser.id,
                    to_user_id: currentUser.id,
                    data: 'Created a new post that you might like.',
                    notification_type: 'create_post',
                    date_time: new Date
                }
                socket.emit('sendNotification', body);
                setDatapost(true);
                dispatch(UserPost());
                setGetImages([]);
                setImagesd([]);
                resetForm();
                setLoadings(false);
                setTimeout(() => {
                    setDatapost(false);
                    history.push('/User/profile')
                    prop.parentCallback("data")
                },1000);
            });
        }
    });

    const setPayments = () => {
        setPaid(true);
        setPaymentType('paid')
    };
    const setPaymentsfree = () => {
        setPaid(false)
        setPaymentType('free')
    };

    return (
        <div >
            <div className="content-center">
                <div className="create-post mb-1">
                    <form>
                        <div className="static-section">
                            {loadings ? <div className={classes.root}>
                                <LinearProgress />
                            </div> : null}
                          
                            <div className="card-titles">
                                <h3 style={{ alignItems: 'left' }}>create post
                                    <span style={{ float: 'right' }}>
                                        <Button disabled={!formik.isValid} style={{ padding: '2px 8px', borderRadius: '20px', fontSize: '12px' }} type="submit" variant="contained" color="primary" onClick={formik.handleSubmit}>
                                            Post</Button>
                                    </span>
                                </h3>
                            </div>
                            
                            {datapost?<div className='mb-2 mt-2'>
                              <p style={{color:"green"}}>Post uploded successfully</p>
                            </div>:null}
                            <div style={{ border: 'solid #6475891a 1px', padding: '5px', borderRadius: '4px' }} className='mt-3'>
                                <div className="search-input icon-right " style={{ background: 'white', cols: "50" }}>
                                    <textarea type="text" className="form-control" placeholder="Write something here..." variant="standard" style={{ width: '100%', border: 'none', resize: "none", fontSize: '14px' }}
                                        name="post_Content" value={formik.values.post_Content}
                                        onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                </div>
                                {paid ?
                                    <div className="search-input icon-right mt-4">
                                        <TextField label="Amount" id="filled-start-adornment" sx={{ width: '100%' }}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }} variant="standard"
                                            name="amount" value={formik.values.amount}
                                            onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    </div> : null}

                                <div className='row' >
                                    <div className='col-12'>
                                        <ul className='likeul'>
                                            <li>
                                                <label htmlFor="photo-upload" className="" >
                                                    <AddPhotoAlternateIcon htmlFor="photo-upload" style={{ color: "#3e89c9", cursor:'pointer' }} aria-label="upload picture" />
                                                </label>
                                            </li>
                                            <li> <Dropdown style={{ zIndex: '1' }}>
                                                <Dropdown.Toggle style={{ outline: 'none', background: 'none', border: 'none', padding: '0px' }}>
                                                    <MoreHorizIcon style={{ color: '#647589', paddingLeft: '5px' }} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu style={{ padding: '15px' }}>
                                                    <FormControl fullWidth variant="standard">
                                                        <select labelId="demo-simple-select-label" id="demo-simple-select" style={{ border: "none" }}
                                                            name="payment_type" defaultValue='free' value={formik.values.payment_type}
                                                            onChange={formik.handleChange, setPaymentsfree, setPayments} onBlur={formik.handleBlur}>
                                                            <option value={"free"}>Free</option>
                                                            <option value={"paid"}>Paid</option>
                                                        </select>
                                                    </FormControl>
                                                    {formik.touched.payment_type && formik.errors.payment_type ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.payment_type}</span> : null}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            </li>
                                            <input style={{ display: 'none' }} type='file' name="image" id="photo-upload" multiple onChange={onImageChange} required />
                                        </ul>
                                    </div>
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='row'>
                                                    <div className='col-md-12' >
                                                        <div className={classess.root}>
                                                            <ImageList className={classess.imageList} cols={imagesd.length}>
                                                                {imagesd.map((data, i) => (
                                                                    <ImageListItem key={i}>
                                                                        <div>
                                                                            <div className="item-media pb-2">
                                                                                <Tooltip title="Remove this Post" style={{ zIndex: '1000', position: 'absolute', right: '8px', top: '-5px' }}>
                                                                                    {/* <IconButton > */}
                                                                                        <CloseIcon className="setremvemedia"  onClick={() => removeMedia(i, data.img)} fontSize="inherit" style={{ color: 'white', zIndex:'100', cursor:'pointer'}} />
                                                                                    {/* </IconButton> */}
                                                                                </Tooltip>
                                                                                {data.type == 'image/jpeg' || fileType == 'image/gif' || data.type == 'image/png' ?
                                                                                    <div className='containerImage'>
                                                                                        <div className='img'>
                                                                                            <img src={data.img} />
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    <div>
                                                                                        {/* <span>{console.log(data.type)}</span> */}
                                                                                        {data.type === 'video/mp4' || data.type === 'video/MOV'|| data.type === 'video/m4v' ||
                                                                                            data.type === 'video/MKV ' || data.type === 'video/3gp' ?
                                                                                            <video src={data.img} width='100%' style={{ width: '100%', height: '100%' }} controls /> : null
                                                                                        }
                                                                                    </div>}
                                                                            </div>
                                                                        </div>
                                                                    </ImageListItem>
                                                                ))}
                                                            </ImageList>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default PostsCreate;
