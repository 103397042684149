import * as React from 'react';
import { useEffect, useRef, useState } from "react";
import Avatar from '@mui/material/Avatar';
import { useHistory } from "react-router-dom";
import api from "../Api";
import moment from "moment";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import socket from "../utils/socketNotification";

function InboxMessages() {
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const componentMounted = useRef(true);
    const [users, setUsers] = useState([]);
    const [inbox, setInbox] = useState([]);
    const top100Films = users;
    const history = useHistory();

    useEffect(() => {
        if (componentMounted.current) {
            let body = {
                id: currentUser.id,
                last_seen: 'online'
            }
            socket.emit('realTimeTyping', body)
            allUsersData(currentUser.id);
            allUsersInbox(currentUser.id);
        }
        return () => {
            componentMounted.current = false;
        }
    }, [0]);

    useEffect(() => {
        if (componentMounted.current) {
            socket.on('getMessage', (res) => {
                setTimeout(() => {
                    allUsersInbox(currentUser.id);
                }, 500);
            });
        }
        return () => {
            componentMounted.current = false;
        }
    }, []);

    const allUsersData = (id) => {
        var arr = [];
        api.get(`user/All/${id}`).then(res => {
            res.data.forEach(element => {
                let body = {
                    label: element.firstname,
                    ID: element.ID
                }
                arr.push(body);
            });
            setTimeout(() => {
                setUsers(arr);
            }, 800);
        }).catch((e) => {
            return console.error(e.message);
        });
    }

    const allUsersInbox = (id) => {
        api.get(`user/msg/inbox/${id}`).then(res => {
            if (res.data.Status != false) {
                setInbox(res.data)
            }
        }).catch((e) => {
            return console.error(e.message);
        });
    }

    const UsersInboxMsgSeen = (id) => {
        api.get(`user/msg/seen/Status/${id}`).then(res => { }).catch((e) => {
            return console.error(e.message);
        });
    }

    const gotomessage = (id, to_user_id) => {
        UsersInboxMsgSeen(to_user_id);
        allUsersInbox(currentUser.id);
        history.push(`/inbox/${id}`);
    }

    return (
        <div>
            <section className="messanger-section">
                <div className="chat-users" style={{ width: '100%' }}>
                    <div style={{ width: '100%', padding: '20px' }}>
                        <a href="" className="back-btn d-block d-sm-none">
                            <i className="ih-18 iw-18" data-feather="arrow-left" />
                        </a>
                        <div className="search-bar">
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={top100Films}
                                sx={{ width: "100%" }}
                                onChange={(event, newValue) => {
                                    sessionStorage.setItem('umsg', JSON.stringify(newValue))
                                    history.push(`/inbox/${newValue.ID + currentUser.id}`)
                                }}
                                renderInput={(params) =>
                                    <TextField {...params} variant="standard" placeholder="find fans..." />}
                            />
                        </div>
                    </div>
                    {inbox.length == 0 ?
                        <a style={{ textAlign: 'center' }}>
                            <div className="media">
                                <div className="media-body">
                                    <p>no message available.</p>
                                </div>
                            </div>
                        </a> : null}
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        {inbox.map((data, i) => <li key={i} className="nav-item" role="presentation">
                            <a onClick={() => gotomessage(data.commenID, data.to_user_id)} className="nav-link" >
                                <div className="media list-media">
                                    <div className="story-img" style={{ marginRight: '0px' }}>
                                        <div className="user-img" >
                                            <Avatar alt={data?.firstname} src={data?.profile_image} />
                                        </div>
                                    </div>
                                    <div className="media-body">
                                        <h5>{data?.firstname} {data?.lastname} {data?.last_seen == 'online' ? <span className="inbox available-stats online " style={{ marginLeft: '5px' }}></span> : null} <span>{moment(data?.dateTime).format('LT')}</span></h5>
                                        <div className="chat">
                                            <h6 style={{ width: '100%' }}>{data?.message}</h6>
                                            {data?.unreadCount != 0 ? <span className="count" style={{ float: 'right' }}>{data?.unreadCount}</span> : null}
                                        </div>
                                    </div>
                                </div>

                            </a>
                        </li>)}
                    </ul>
                </div>
            </section>
        </div>
    );
}

export default InboxMessages;