import { createSlice } from '@reduxjs/toolkit'
import api from "../../Api";
import Swal from "sweetalert2";

// Slice
const slice = createSlice({
    name: 'userProfile',
    initialState: {
        userProfile: null,
    },
    reducers: {
      UserProfileSuccess:(state, action) => {
          state.userProfile = action.payload;
          // console.log(state.userProfile)
      },
    },
  });
  
  export default slice.reducer
  // Actions
  const { UserProfileSuccess } = slice.actions;
  
  export const UserProfile = (body) => async dispatch => {
    try {
      const res = await api.post('user/Profile', body);
      if(res.data.Status == false){
        Swal.fire({  
          title: 'Warning',  
          text: res.data.Message,  
          icon: 'warning',   
          confirmButtonColor: '#3085d6',  
          confirmButtonText: 'Ok',
          timer: 1500  
        });
      }else{
        dispatch(UserProfileSuccess(res.data));
      }
  
    } catch (e) {
      return console.error(e.message);
    }
  }