import React, { useState, useContext, useEffect } from "react";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import FavoriteIcon from '@mui/icons-material/Favorite';
import socket from "../../../../utils/socketNotification";
import api from "../../../../Api";

const PostLikes = (props) => {
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const gotoCardDetails = () =>{
      history.push('/profile/card_add')
    }

    const selectpostLoke = () => {
        let data = {
            "user_id": currentUser.id,
            "post_id": props.props.ID,
        }
        api.post('/post/like', data).then(res => {
            props.parentCallback("data");
            if (res.data.Message == 'Post like successfully.') {
                let body = {
                    type: 'post',
                    media_id: props.props.ID,
                    user_id: currentUser.id,
                    data: 'likes your post.',
                    to_user_id:props.props.user_id,
                    notification_type:'post_like',
                    date_time:new Date
                }
                socket.emit('sendNotification', body)
            }
        });
    }

    return (
        <div>
            <div className="like-panel">
                <div className="left-emoji">
                    <ul>
                    <li>
                         {props.props.Like  != 0?<FavoriteIcon style={{color:'red', fontSize:'18px', cursor:'pointer'}} onClick={selectpostLoke}/>:
                           <FavoriteBorderIcon  onClick={selectpostLoke}  style={{color:'red', fontSize:'18px', cursor:'pointer'}}/>}
                        </li>
                    </ul>
                </div>
                <div className="right-stats">
                    <ul>
                    <li><h5><span className="likespan" style={{color:'#8997a6'}}><b>{props.props.totalLike}</b> Like</span></h5></li>
                    </ul>
                </div>
            </div>
            <div>
                <Dialog open={open} onClose={handleClose} scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description">
                    <DialogTitle id="scroll-dialog-title">SEND TIP</DialogTitle>
                    <DialogContent dividers={scroll === 'paper'}>
                        <DialogContentText id="scroll-dialog-description"
                            ref={descriptionElementRef} tabIndex={-1}>
                            <div>
                                <div>
                                    <a className="main-link">
                                        <div className="media d-none d-sm-flex">
                                            <div className="user-img">
                                                <Stack direction="row" spacing={2}>
                                                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                                                </Stack>
                                            </div>
                                            <div className="media-body d-none d-md-block ml-1">
                                                <h4>Josephin water</h4>
                                                <span>active now</span>
                                            </div>
                                        </div>
                                    </a>
                                    <hr />
                                    <form className="theme-form">
                                       <div disabled>
                                       <div className="form-group mb-4">
                                            <input type="email" name="email" className="form-control" placeholder="Tip Amount" />
                                            <div className='card-titles'>
                                                <p style={{color:'#00aff0'}}>Please add a payment card</p>
                                            </div>
                                        </div>

                                        <div className="form-group mb-4">
                                            <input type="email" name="email" className="form-control" placeholder="Message (Optional)" />
                                            <div className='card-titles'>
                                                <p style={{color:'#00aff0', textAlign:'right'}}>Max 200 words</p>
                                            </div>
                                        </div>
                                        <div className="row pt-2 pb-3">
                                                <div style={{ textAlign: 'center' }}>
                                                    <Stack >
                                                        <Button variant="contained" disabled className='br' >
                                                            {loading ? <span><span className="spinner-border spinner-border-sm"></span> Please wait...</span> : 'Submit'}</Button>
                                                    </Stack>
                                                </div>
                                        </div>
                                       </div>
                                        
                                    </form>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}> Cancel </Button>
                        <Button onClick={gotoCardDetails}> Add a payment card </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>


    );

}

export default PostLikes