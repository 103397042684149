import React from "react";
import usePushNotifications from "./usePushNotifications";

export default function PushNotificationDemo() {
  const {
    userConsent,
  } = usePushNotifications();

  const isConsentGranted = userConsent === "granted";

  return (
    <main>
    </main>
  );
}
