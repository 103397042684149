import * as React from 'react';
import { useEffect, useState } from "react";
import PaymentDetails from "../AddCards/patmentpage";
import { useHistory } from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Divider from '@mui/material/Divider';
import ImageIcon from '@mui/icons-material/Image';
function PaidPost(prop) {
    const [subPaid, setSubPaid] = useState();

    useEffect(() => {console.log(prop)}, []);

    const handleCallback = (childData) => {
        setSubPaid(false);
        prop.parentCallbacks({Status:false});
    }
    const SubscribeProfile = (paid) => {
        setSubPaid(true);
    }

    return (
        <div>
                 {subPaid? <PaymentDetails props={{data:prop.prop.post_for_paid , id:prop.prop.ID, pay_on_type:'post', payment_type:'subscribe'}} parentCallback={handleCallback} />:null}
            <div >
                <div >
                    <div style={{ textAlign: 'center', width: "100%", height: '180px', paddingTop: '15%', color:'#e9e9e9' }}>
                        {/* <LockIcon className ='icond'></LockIcon> */}
                        <LockIcon style={{fontSize:'50px'}}/>
                    </div>

                    <Divider className='mt-4 mb-4' />
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="paidprofsub">
                                <span style={{ fontSize: "12px",color:'rgb(159 159 159)' }}> <ImageIcon style={{ fontSize: "14px" }} /> {prop.prop?.media_data.length}</span>
                                <div className="pb-4 pt-2" style={{padding:'5px'}}>
                                    <button onClick={()=>SubscribeProfile(prop.prop?.post_for_paid)} className="btn btn-solid btnhover" style={{ width: '100%', borderRadius: '50px' }}><span style={{ float: 'left' }}>Subscribe to see user's posts</span> <span style={{ float: 'right' }}>{prop.prop?.post_for_paid} $</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaidPost;