import * as React from 'react';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useState } from "react";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import moment from "moment"
import socket from "../../utils/socketNotification";
import { useHistory } from "react-router-dom";
function ChatMessagesHeader(props) {
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const history = useHistory();
    
    const gotoBack = () => {
        let Typingbody = {
            id: currentUser.id,
            last_seen: 'online'
        }
        socket.emit('realTimeTyping', Typingbody);
        window.history.go(-1)
    }

    const gotoProfile = () => {
        if (props.proprs.to_user_id !== currentUser.id) {
            history.push(`/subscriptions/${props.proprs.to_user_id}`)
        } else {
            return;
        }
    }

    return (
        <>
            <Box sx={{ minWidth: '100%' }} >
                <Card variant="outlined">
                    <section style={{ padding: '5px 20px' }}>
                        <div className="chat-content">
                            <div className="tab-content" >
                                <div className="" >
                                    <div className="tab-box">
                                        <div className="user-chat">
                                            <div className="user-title" style={{ width: "55%" }}>
                                                <div className="back-btn d-block d-sm-none" onClick={gotoBack}>
                                                    <ArrowBackIosNewRoundedIcon style={{ fontSize: '14px' }} />
                                                </div>
                                                <div className="media list-media">
                                                    <div className="story-img">
                                                        <div className="user-img" onClick={()=> gotoProfile()}>
                                                            <Avatar alt={props.proprs.firstname} src={props.proprs.imageUrl} />
                                                        </div>
                                                    </div>
                                                    <div className="media-body pt-1" style={{ paddingLeft: '4px' }} >
                                                        <h5>{props.proprs.firstname} {props.proprs.lastname}  {props.proprs?.last_seen == 'online' ? <span className="inbox available-stats online " style={{ marginLeft: '5px' }}></span> : null} </h5>
                                                        {props.proprs.last_seen == 'online' ? <small>{props.proprs.last_seen}</small> : props.proprs.last_seen == 'typing...' ? <small>{props.proprs.last_seen}</small> :
                                                            <small>{moment(props.proprs.last_seen).calendar()}</small>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Card>
            </Box>
        </>
    );
}

export default ChatMessagesHeader;