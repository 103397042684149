import { createSlice } from '@reduxjs/toolkit'
import api from "../../Api";
import Swal from "sweetalert2";

// Slice
const slice = createSlice({
    name: 'seggest',
    initialState: {
        seggest: [],
    },
    reducers: {
        SeggestionSuccess:(data, action) => {
            data.seggest = action.payload;
      },
    },
  });
  
  export default slice.reducer
  // Actions
  const { SeggestionSuccess } = slice.actions;
  
  export const SeggestionData = (id) => async dispatch => {
    try {
      const res = await api.get(`/user/seggestion/${id}`);
      if(res.data.Status == false){
        // Swal.fire({  
        //   title: 'Warning',  
        //   text: res.data.Message,  
        //   icon: 'warning',   
        //   confirmButtonColor: '#3085d6',  
        //   confirmButtonText: 'Ok',
        //   timer: 1500  
        // });
      }else{
        dispatch(SeggestionSuccess(res.data[0]));
      }
  
    } catch (e) {
      return console.error(e.message);
    }
  }