import { useState, useEffect, useRef } from "react";
import api from "./Api";
import socket from "./utils/socketNotification";
import {
  isPushNotificationSupported,
  askUserPermission,
  registerServiceWorker,
  sendNotification,
} from "./push-notifications";
//import all the function created to manage the push notifications
const pushNotificationSupported = isPushNotificationSupported();
//first thing to do: check if the push notifications are supported by the browser

export default function usePushNotifications() {
  const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
  const [userConsent, setSuserConsent] = useState(Notification.permission);
  const [error, setError] = useState(null);
  const componentMounted = useRef(true);

  useEffect(() => {
    if (componentMounted.current) {
      if (pushNotificationSupported) {
        registerServiceWorker().then(() => { });
      }
    }
    return () => {
      componentMounted.current = false;
    }
  }, [0]);

  useEffect(() => {
    if (componentMounted.current) {
      askUserPermission().then(consent => {
        setSuserConsent(consent);
        if (consent !== "granted") {
          setError({
            name: "Consent denied",
            message: "You denied the consent to receive notifications",
            code: 0
          });
        }
      });
    }
    return () => {
      componentMounted.current = false;
    }
  }, [0]);

  useEffect(() => {
    if (componentMounted.current) {
      setTimeout(() => {
        if (userConsent == "granted") {
          socket.on('getNotification', (res) => {
            api.get(`/get-user-notification/${currentUser.id}`).then(res => {
              if (res.data.Status != false) {
                console.log(res.data[0])
                sendNotification(res.data[0])
              }
            });
          });
        }
      }, 1500);
    }
    return () => {
      componentMounted.current = false;
    }

  }, []);



  return {
    userConsent,
    pushNotificationSupported,
  };
}
