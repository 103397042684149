import React, { useState, useRef, useEffect } from "react";
import ReplayIcon from '@mui/icons-material/Replay';
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import api from "../../../Api";


const Matches = () => {
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    var img = 'https://api.matchwithfans.com/upload/profile/';
    const [matchesData, setMatchesData] = useState([]);
    const componentMounted = useRef(true);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        centerMode: false,
        slidesToShow: 2,
        slidesToScroll: 2
    };

    useEffect(() => {
        if (componentMounted.current) {
            let Id = currentUser.id;
            matchDatas(Id) 
        }
        return () => {
            componentMounted.current = false;
        }
    }, [componentMounted]);

    const matchDatas = (Id) =>{
        setLoading(true);
        api.get(`get-profile-matcheas/${Id}`).then(res => {
           if(res.data.Status!= false){
            setMatchesData(res.data);
            setLoading(false);
           }else{
            setLoading(false);   
           }
        }).catch(err=>{
            console.log(err)
        });
    }
    const refreshmatsh = () =>{
        let Id = currentUser.id;
        matchDatas(Id)
    }

    const gitoSubscriptionPage = (id) =>{
     history.push(`/subscriptions/${id}`)
    }


    return (
        <div>
            <div className="suggestion-box section-t-space pb-4">
                <div className="card-title">
                    <h3>Fans Interest Matches</h3>
                    <div className="settings">
                        <div className="setting-btn">
                            <a className="d-flex" onClick={()=>refreshmatsh()}>
                            < ReplayIcon className='uiicons' />
                            </a>
                        </div>
                    </div>
                </div>
                {loading? <div style={{textAlign:'center'}} className='mt-4 mb-4'>
                       <Loader type="Bars" color="#00BFFF" height={30} width={30}  />
                    </div>
                    :
                <Slider {...settings}>
                    {matchesData.map((data ,i) =>(
                    <div className="suggestion-content ratio_115" key={i} style={{padding:'2px !important'}}>
                        <div className="slide-2 no-arrow default-space" >
                            <div>
                                <div className="story-box" onClick={()=>gitoSubscriptionPage(data?.ID)}>
                                    <div className="adaptive-overlay pink-overlay" />
                                    <div className="story-bg" style={{width:'100%', height:'150px'}}>
                                        <img style={{width:'100%', height:'150px', borderRadius:'10px', objectFit:'fill'}}
                                        onError={(event) => event.target.removeAttribute('src')}
                                        onError={(event) => event.target.src = '../../assets/images/avtar-image.jpg'}
                                         src={data?.s3url} className="img-fluid bg-img" data-adaptive-background={1} alt='true' />
                                    </div>
                                    <div className="story-content">
                                        <h6 style={{fontSize:'12px'}}>{data?.firstname} {data?.lastname}</h6>
                                        <h6 style={{fontSize:'14px'}}>{data?.match_percentage.toFixed()}%</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>))}

                </Slider>}
            </div>


        </div>
    );

}

export default Matches