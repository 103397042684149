import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './App.css';
import { Provider } from 'react-redux';
import store from "./store/index";
import { StyledEngineProvider } from '@mui/material/styles';
import Login from "./auth/login";
import SignUp from "./auth/register";
import EmailVerificationp from "./auth/emailVerification";
import ForgotPassword from "./auth/forgotPassword";
import AccountVerification from "./auth/accountVerify";
import AccountVerificationDone from "./auth/accountVerified";

import Header from "./layout/header";
import Footer from './layout/Footer'
import Center from "./dasboard/center/center";
import Left from "./dasboard/left/left";
import Right from "./dasboard/right/right";
import Notification from "./notifications/notification";
import NotificationData from "./notifications/notificationData";
import Lists from "./list/lists";
import InboxMessages from "./messages/messages";
import ChatMessages from "./messages/components/chat";
import SubProfile from "./suscribes/profile";
import ColargeBox from "./lightbox/colargBox";
import Profile from "./profile/profile";
import EditProfiles from "./profile/EditProfile/profiles";
import SubscriptionPrice from "./profile/EditProfile/SubscriptionPrice";
import PrivacyAndSafty from "./profile/EditProfile/privacyandsafty";
import WhatIsNew from "./profile/EditProfile/whatsNew";
import Accounts from "./profile/EditProfile/accounts";
import CardDetails from "./AddCards/cardDetails";
import PaymentDetails from "./AddCards/patmentpage";

import DeleteAccount from './profile/EditProfile/account/delete'
import LoginSession from './profile/EditProfile/account/loginsession'
import ChangePassword from './profile/EditProfile/account/passwoed'
import PhoneNumber from './profile/EditProfile/account/phone'
import ChangeEmail from './profile/EditProfile/account/email'
import ChangeUserName from './profile/EditProfile/account/usename'
import LookingFords from './profile/EditProfile/lookingFor'
import MyIntrest from './profile/EditProfile/myIntrest';
import MyCoverImage from './profile/EditProfile/coverImages';
import InboxmobileMessages from "./messages/mobileMessages";
import MySubscriptions from "./profile/MySubscriptions/MySubscriptions";
import PostsCreate from "./dasboard/center/post/createpost";
import FansSuggestion from "./dasboard/right/components/FansSuggestion";


import MyWallets from "./myWallets/mywallets";


import Followers from "./followingandfollowers/followers";
import Following from "./followingandfollowers/following";

function App() {
 

  return (
    <>
      <Provider store={store} >
        <StyledEngineProvider injectFirst>
       
          <Router>
            <Switch>
              <Route exact path="/">
                <Login />
                <Footer />
              </Route>
              <Route exact path="/account-verified">
                <AccountVerificationDone />
                <Footer />
              </Route>
              <Route exact path="/account-verification">
                <AccountVerification />
                <Footer />
              </Route>
              <Route exact path="/signup">
                <SignUp />
                <Footer />
              </Route>
              <Route exact path="/email-verification">
                <EmailVerificationp />
                <Footer />
              </Route>
              <Route exact path="/forgot-password/:id">
                <ForgotPassword />
                <Footer />
              </Route>

              <Route exact path="/:path">
                <Header  />
                
                <div className='padd'>
                  <div className="container">
                    <div className='row'>
                      <div className='col-md-3'>
                        <Left />
                      </div>
                      <div className='col-md-6'>
                        <Switch>
                          <Route component={Lists} exact path="/list"></Route>
                          <Route exact path="/home">
                            <Center />
                          </Route>
                          <Route exact path="/notification">
                            <Notification />
                          </Route>
                          <Route component={PaymentDetails} exact path="/payment"></Route>
                          <Route component={MySubscriptions} exact path="/Mysubscriptions"></Route>
                          <Route component={PostsCreate} exact path="/createpost"></Route>
                          <Route component={FansSuggestion} exact path="/fans"></Route>
                          <Route component={MyWallets} exact path="/wallet"></Route>
                          <Route component={Followers} exact path="/follower"></Route>
                          <Route component={Following} exact path="/following"></Route>
                        </Switch>
                      </div>
                      <div className='col-md-3'>
                        <Right />
                      </div>
                    </div>
                  </div>
                </div>
              </Route>

              <Route exact path="/subscriptions/:path">
                <Header />
                <div className='padd'>
                  <div className="container">
                    <div className='row'>
                      <div className='col-md-3'>
                        <Left />
                      </div>
                      <div className='col-md-6'>
                        <Switch>
                          <Route component={SubProfile} exact path="/subscriptions/:path"></Route>
                        </Switch>
                      </div>
                      <div className='col-md-3'>
                        <Right />
                      </div>
                    </div>
                  </div>
                </div>
              </Route>

              <Route exact path="/users-notifcation/:path">
                <Header />
                <div className='padd'>
                  <div className="container">
                    <div className='row'>
                      <div className='col-md-3'>
                        <Left />
                      </div>
                      <div className='col-md-6'>
                        <Switch>
                          <Route component={NotificationData} exact path="/users-notifcation/:path"></Route>
                        </Switch>
                      </div>
                      <div className='col-md-3'>
                        <Right />
                      </div>
                    </div>
                  </div>
                </div>
              </Route>


              <Route exact path="/media/:path">
                <Header />
                <div className='padd'>
                  <div className="container">
                    <div className='row'>
                      <div className='col-md-12'>
                        <Switch>
                          <Route component={ColargeBox} exact path="/media/:path"></Route>
                        </Switch>
                      </div>
                    </div>
                  </div>
                </div>
              </Route>


              <Route exact path="/inbox/:path">
                <Header />
                <div className='padd'>
                  <div className="container">
                    <div className='row'>
                      <div className='col-md-3 hide_mobile'>
                        <InboxMessages />
                      </div>
                      <div className='col-sm-12 hide_desktop'>
                        <InboxmobileMessages />
                      </div>
                      <div className='col-md-9 hide_mobile' style={{ paddingLeft: '0px' }}>
                        <ChatMessages />
                      </div>
                    </div>
                  </div>
                </div>
              </Route>
              <Route exact path="/allinbox/:path">
                <Header />
                <div className=' padd'>
                  <div className="container">
                    <div className='row'>

                      <div className='col-md-12 hide_desktop' style={{ paddingLeft: '0px' }}>
                        <ChatMessages />
                      </div>
                      <div className='col-md-3 hide_mobile'>
                        <InboxMessages />
                      </div>

                      <div className='col-md-9 hide_mobile' style={{ paddingLeft: '0px' }}>
                        <ChatMessages />
                      </div>
                    </div>
                  </div>
                </div>
              </Route>


              <Route exact path="/User/profile">
                <Header />
                <div className='padd'>
                  <div className="container">
                    <div className='row'>
                      <div className='col-md-3'>
                        <Left />
                      </div>
                      <div className='col-md-6'>
                        <Profile />
                      </div>
                      <div className='col-md-3'>
                        <Right />
                      </div>
                    </div>
                  </div>
                </div>
              </Route>

              <Route path="/profile/:path">
                <Header />
                <div className=''>
                  <div className="container">
                    <div className='row'>
                      {/* <div className='col-md-2'>
                        <Lefts />
                      </div> */}
                      <div className='col-md-12'>
                        <Switch>
                          <Route component={EditProfiles} exact path="/profile/edit-profile"></Route>
                          <Route component={SubscriptionPrice} exact path="/profile/subscription"></Route>
                          <Route component={PrivacyAndSafty} exact path="/profile/privacy"></Route>
                          <Route component={WhatIsNew} exact path="/profile/whats-new"></Route>
                          <Route component={Accounts} exact path="/profile/accounts"></Route>
                          <Route component={CardDetails} exact path="/profile/card_add"></Route>
                          <Route component={DeleteAccount} exact path="/profile/delete"></Route>
                          <Route component={LoginSession} exact path="/profile/login"></Route>
                          <Route component={ChangePassword} exact path="/profile/password"></Route>
                          <Route component={PhoneNumber} exact path="/profile/phone"></Route>
                          <Route component={ChangeEmail} exact path="/profile/email"></Route>
                          <Route component={ChangeUserName} exact path="/profile/username"></Route>
                          <Route component={LookingFords} exact path="/profile/looking-for"></Route>
                          <Route component={MyIntrest} exact path="/profile/my-intrest"></Route>
                          <Route component={MyCoverImage} exact path="/profile/images"></Route>
                        </Switch>                   
                      </div>
                    </div>
                  </div>
                </div>
              </Route>
            </Switch>
          
          </Router>
        </StyledEngineProvider>
      </Provider>

    </>
  );
}

export default App;
