import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router";
import ReplayIcon from '@mui/icons-material/Replay';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux';
import { SeggestionData } from '../../../store/seggestion/seggestion';
import Loader from "react-loader-spinner";
import moment from 'moment';
import api from "../../../Api";

const FriendSuggestion = () => {
    const componentMounted = useRef(true);
    const [userSeggession, setUserSeggession] = useState([])
    const [usernodata, setUsernodata] = useState(false)
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const history = useHistory();

    useEffect(() => {
        if (componentMounted.current) {
            getSeggestion(currentUser.id);
        }
        return () => {
            componentMounted.current = false;
        }
    }, []);

    const getSeggestion = (id) => {
        api.get(`/user/seggestion/${id}`).then(data => {
            if(data.data){
                setUsernodata(false)
                setUserSeggession(data.data[0]);
            }else{
                setUsernodata(true)
            }

        })
    }

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesPerRow:2,
        nav: true,
        rewind: true,
        autoplay: false,
    };

    const gotoDataUser = (ID) => {
        history.push(`/subscriptions/${ID}`);
    }
    const gotofans = () =>{
        history.push(`/fans`);
    }

    const refresh = () => {
        setUserSeggession([])
        getSeggestion(currentUser.id);
    }

    return (
        <>
            <div className="suggestion-box ">
                <div className="card-title">
                    <h3 style={{ textTransform: 'none' }}>Fans you can follow</h3>
                    <div className="settings">
                        <div className="setting-btn">
                            <a className="d-flex" onClick={refresh}>
                                <ReplayIcon className='uiicons' />
                            </a>
                        </div>
                    </div>
                </div>
                {userSeggession.length == 0 ?
                    <div style={{ textAlign: 'center' }} className='mt-4 mb-4'>
                        {usernodata ? <div className="text-center">
                            <p onClick={() => gotofans()} style={{ padding: '10px 5px', cursor: 'grab' }}>fans not available.</p>
                        </div> : null}
                        <Loader type="Bars" color="#3e89c9" height={30} width={30} />
                    </div>
                    : 
                    <Slider {...settings}>
                        {userSeggession.map((data, i) => <>
                            <div key={i} className="suggestion-content ratio_115" style={{ padding: '2px !important' }}>
                                <div className="slide-2 no-arrow default-space" >
                                    <div className="story-box" style={{ width: '100%', height: '120px' }}>
                                        <div className="story-bg" >
                                            <img style={{ borderRadius: '10px', width: '100%', height: '100%', maxHeight: '120px', objectFit: 'cover' }} src={data?.cover_image}
                                                onError={(event) => event.target.removeAttribute('src')}
                                                onError={(event) => event.target.src = '../assets/images/avtar-image.jpg'}
                                                className="img-fluid bg-img" data-adaptive-background={1} alt='true' />
                                        </div>
                                        <div className="profile-bg">
                                            <Stack direction="row" spacing={2} style={{ position: 'absolute', bottom: '10px', left: '25px', border: 'solid white 2px', borderRadius: '50px', backgroundColor: 'gray' }}>
                                                <Avatar className="img-fluid-post blur-up lazyload bg-img imgrad" alt={data?.firstname} src={data?.profile_image} />
                                            </Stack>
                                            <div className="story-content" onClick={() => gotoDataUser(data.ID)}>   <span>
                                                <h6 style={{ fontSize: "16px",marginLeft: '36%', fontWeight: 'bold' }}>{data?.firstname} {data?.lastname}</h6>
                                                <span style={{ marginLeft: '36%' }}>@{data?.user_name}</span>
                                            </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        )}
                </Slider>}
               
                <div className="bottom-part">
                <a className="event-btn" onClick={()=>gotofans()} style={{ padding:'10px 5px', cursor: "pointer"}}>more fans...</a>
                    
                  </div>
                
            </div>


        </>
    );

}

export default FriendSuggestion