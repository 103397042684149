import * as React from 'react';
import { useEffect, useRef, useState, createRef } from "react";
import ChatMessagesHeader from "./heasers";
import { useParams } from "react-router";
import InputEmoji from 'react-input-emoji';
import moment from "moment";
import api from "../../Api";
import socket from "../../utils/socketNotification";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Tooltip from '@material-ui/core/Tooltip';
import SendDialog from "../../Dialog/sendTips";

function ChatMessages() {
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const [msg, setMsg] = useState([])
    const [usersData, setUsersData] = useState({})
    const ids = useParams().path;
    const [text, setText] = useState('')
    const messagesEndRef = useRef(null)
    const [tips, setTips] = useState(false);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
      }

    useEffect(scrollToBottom, [msg]);

    useEffect(() => {
        if (currentUser.id != ids) {
            uesrsMsg();
            api.get(`/user/msg/Profile/${ids - currentUser.id}`).then(res => {
                res.data.ID = ids - currentUser.id;
                res.data.s3profileurl = res.data.imageUrl;
                res.data.to_user_id = ids - currentUser.id;
                res.data.pay_on_type = 'message'
                setUsersData(res.data);
            }); 
        }
    }, [ids]);

    const uesrsMsg = () =>{
        api.get(`user/single/message/${ids}`).then(res => {
            setMsg(res.data);
            api.get(`user/msg/seen/Status/${ids - currentUser.id}`).then(data => { });
        });
    }

    const handleOnEnter = (text) => {
        let body = {
            "user_id": currentUser.id,
            "to_user_id": ids - currentUser.id,
            "message": text,
            "date_time": new Date
        }
        socket.emit('sentMessage', body);
        setTimeout(() => {
            uesrsMsg();
            let Typingbody = {
                id: currentUser.id,
                last_seen: 'online'
            }
            socket.emit('realTimeTyping', Typingbody)
        }, 800);
        
    }

    const handleCallback = (childData) => {
        setTips(false);
    }

    const sentTip = () =>{
        setTips(true) 
    }

    if(text){
        var strcount = text.length
        if(strcount == 1){
            let Typingbody = {
                id: currentUser.id,
                last_seen: 'typing...'
            }
            socket.emit('realTimeTyping', Typingbody)
        }else{
        }
    }


    return (
        <>
            {ids != currentUser.id ? <div>
                <ChatMessagesHeader proprs={usersData} />
                <section className="messanger-section mobile">
                    <div className="chat-content" style={{ width: '100%'}}>
                        <div className="tab-content" >
                            <div className="tab-box">
                                <div className="user-chat" style={{ width: '100%' }}>
                                    <div className="chat-history">
                                        <div className="avenue-messenger" >
                                            <div className="chat" >
                                                <div className="messages-content messagesWrapper mb-4"   style={{ background: "#f7f7f7"}}>
                                                <div ref={messagesEndRef} />
                                                    {msg.map((data, i) => <div key={i} style={{paddingBottom:'4rem'}}>
                                                        {data.user_id != currentUser.id ?
                                                            <div className="message new" style={{ minWidth: '200px', textAlign: 'left' }}>
                                                                {data?.message}
                                                                <div className="timestamp">{moment(data?.date_time).calendar()}</div>
                                                            </div> : null}
                                                        {data.user_id == currentUser.id ? <div className="message message-personal new" style={{ minWidth: '200px', textAlign: 'left' }}>
                                                            {data?.message}
                                                            <div className="timestamp">{moment(data?.date_time).calendar()}</div>
                                                            {data.seen_status == 0 ? <div className="checkmark-sent-delivered">✓</div> :
                                                                <div className="checkmark-read" style={{ color: 'blue' }}>✓✓</div>}

                                                        </div> : null}
                                                    </div>)}
                                                    
                                                </div>
                                                <div className="search-input icon-right sendmessage" style={{ background: 'white' }}>
                                               <p> 
                                                <span className='msgTips' onClick={()=>sentTip()}><Tooltip title="Sent Tip" ><MonetizationOnIcon style={{ fontSize: '18px' }} /></Tooltip></span>
                                               <span><InputEmoji value={text} onChange={setText} cleanOnEnter onEnter={handleOnEnter} placeholder="message..." borderRadius='0px' margin='1px'/></span>
                                               </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {tips? <SendDialog props={usersData} parentCallback={handleCallback} /> : null}
                </section>
            </div> : null}
        </>
    );
}

export default ChatMessages;