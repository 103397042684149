import { createSlice } from '@reduxjs/toolkit'
import api from "../../Api";
import Swal from "sweetalert2";

// Slice
const slice = createSlice({
    name: 'postComm',
    initialState: {
        postComm: [],
    },
    reducers: {
        PostCommSuccess:(state, action) => {
          state.postComm = action.payload;
      },
    },
  });
  
  export default slice.reducer
  // Actions
  const { PostCommSuccess } = slice.actions;
  
  export const PostComm = (body) => async dispatch => {
    try {
      const res = await api.post('/post/comment/insert', body);
      if(res.data.Status == false){
        Swal.fire({  
          title: 'Warning',  
          text: res.data.Message,  
          icon: 'warning',   
          confirmButtonColor: '#3085d6',  
          confirmButtonText: 'Ok',
          timer: 1500  
        });
      }else{

      }
  
    } catch (e) {
      return console.error(e.message);
    }
  }

  export const PostReComm = (body) => async dispatch => {
    try {
      const res = await api.post('/post/recomment/insert', body);
      if(res.data.Status == false){
        // Swal.fire({  
        //   title: 'Warning',  
        //   text: res.data.Message,  
        //   icon: 'warning',   
        //   confirmButtonColor: '#3085d6',  
        //   confirmButtonText: 'Ok',
        //   timer: 1500  
        // });
      }else{
    
      }
  
    } catch (e) {
      return console.error(e.message);
    }
  }

  export const PostGETComm = (id) => async dispatch => {
    try {
      const res = await api.get(`/post/comment/insert/${id}`);
      if(res.data.Status == false){
        // Swal.fire({  
        //   title: 'Warning',  
        //   text: res.data.Message,  
        //   icon: 'warning',   
        //   confirmButtonColor: '#3085d6',  
        //   confirmButtonText: 'Ok',
        //   timer: 1500  
        // });
      }else{
        dispatch(PostCommSuccess(res.data));
      }
  
    } catch (e) {
      return console.error(e.message);
    }
  }