import * as React from 'react';
import { useEffect, useState } from "react";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useFormik } from 'formik';
import api from "../Api";
import Swal from "sweetalert2";

const validateCards = cardDetails => {
    const errors = {};
    if (!cardDetails.nameOnAccount) {
        errors.nameOnAccount = '*Please Enter Account Holder name';
    }

    if (!cardDetails.routingNumber) {
        errors.routingNumber = '*Please Enter Routing Number';
    } else if (cardDetails.routingNumber.length > 9) {
        errors.routingNumber = '*Routing Number cannot exceed 9 characters';
    }
    if (!cardDetails.accountNum) {
        errors.accountNum = '*Please Enter Account Number ';
    } else if (cardDetails.accountNum.length > 12) {
        errors.accountNum = '*Account Number cannot exceed 12 characters';
    }
    return errors;
};



function CardDetails() {
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const [loading, setLoading] = useState(false);

    useEffect(() => { }, []);

    const formik = useFormik({
        initialValues: {
            user_id: currentUser.id,
            routingNumber: '',
            accountNum: '',
            nameOnAccount: ''
        },
        validate: validateCards,
        onSubmit: values => {
            setLoading(true);
            api.post('/cardsDetails/insert', values).then(res=>{
             if(res.data.Status !== false){
                Swal.fire({  
                    title: 'Successs',  
                    text: res.data.Message,  
                    icon: 'success',   
                    confirmButtonColor: '#3085d6',  
                    confirmButtonText: 'Ok',
                    timer: 1500  
                  });
                setLoading(false);
                window.history.go(-1);
             }else{
                Swal.fire({  
                    title: 'Warning',  
                    text: res.data.Message,  
                    icon: 'warning',   
                    confirmButtonColor: '#3085d6',  
                    confirmButtonText: 'Ok',
                    timer: 1500  
                  });
                  setLoading(false);
             }
            }).catch(err=>{ console.log(err.errors) })
        }
    });

    return (
        <>
            <div className='padd'>
                <Box sx={{ minWidth: 275 }} className='mt-2 '>
                    <Card variant="outlined" className="padds">
                        <div>
                            <h2>ADD ACCOUNT DETAILS</h2>
                        </div><hr />
                        <form>
                            <div className="row">
                                <div className='col-md-12'>
                                    <div className='card-titles mb-3'>
                                        <h3 className='mb-2'>Account Details</h3>
                                        <p>We are fully compliant with Payment Details Industry Data Security Standards.</p>
                                    </div>
                                    <div className="form-group mb-3">
                                        <input type="text" className="form-control" name="nameOnAccount" placeholder="Account Holder Name" value={formik.values.nameOnAccount}
                                            onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                        {formik.touched.nameOnAccount && formik.errors.nameOnAccount ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.nameOnAccount}</span> : null}
                                    </div>

                                    <div className="form-group mb-3">
                                        <input type="text" className="form-control" name="accountNum" placeholder="Account No." value={formik.values.accountNum}
                                            onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                        {formik.touched.accountNum && formik.errors.accountNum ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.accountNum}</span> : null}
                                    </div>
                                    <div className="form-group mb-3">
                                        <input type="text" className="form-control" name="routingNumber" placeholder="Routing Number" value={formik.values.routingNumber}
                                            onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                        {formik.touched.routingNumber && formik.errors.routingNumber ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.routingNumber}</span> : null}
                                    </div>


                                </div>
                            </div>
                            <div className='row mt-4 mb-4'>
                                <div className='col-md-3'></div>
                                <div className='col-md-6'>
                                    <div style={{ textAlign: 'center' }}>
                                        <Stack >
                                            <Button variant="contained" color="primary" className='br' onClick={formik.handleSubmit} disabled={!formik.isValid} >
                                                {loading ? <span><span className="spinner-border spinner-border-sm"></span> Please wait...</span> : 'Submit'}</Button>
                                        </Stack>
                                    </div>
                                </div>
                                <div className='col-md-3'></div>
                            </div>
                        </form>

                    </Card>
                </Box>
            </div>
        </>
    );
}

export default CardDetails;