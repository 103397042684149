import * as React from 'react';
import { useEffect, useRef, useState } from "react";
import Avatar from '@mui/material/Avatar';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getInbox } from '../store/message/inbox';
import { UserAlls } from '../store/Accounts/Allusers';
import { SeenMsg } from '../store/message/magSeen';
import moment from "moment";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Stack } from '@mui/material';

function InboxmobileMessages() {
    const componentMounted = useRef(true);
    const dispatch = useDispatch();
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const { Allusers } = useSelector(res => res)
    const { inbox } = useSelector(data => data)
    const top100Films = Allusers.alluser;
    const history = useHistory();
    const socket = useRef();

    useEffect(() => {
        if (componentMounted.current) {
            dispatch(UserAlls(currentUser.id));
            dispatch(getInbox(currentUser.id));
        }
        return () => {
            componentMounted.current = false;
        }
    }, [0]);

    const gotoBack = () => {
        window.history.go(-1)
    }

    const gotoMessages = () => {
        history.push('/message')
    }

    const gotomessage = (id, to_user_id) => {
        dispatch(SeenMsg(to_user_id))
        dispatch(getInbox(currentUser.id))
        history.push(`/allinbox/${id}`)
    }


    return (
        <div>
            <section className="messanger-section">
                <div className="chat-users" style={{ width: '100%' }}>
                    <div style={{ width: '100%', padding: '20px' }}>
                        <a href="" className="back-btn d-block d-sm-none">
                            <i className="ih-18 iw-18" data-feather="arrow-left" />
                        </a>
                        <div className="search-bar">
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={top100Films}
                                sx={{ width: "100%" }}
                                onChange={(event, newValue) => {
                                    sessionStorage.setItem('umsg', JSON.stringify(newValue))
                                    history.push(`/allinbox/${newValue.ID + currentUser.id}`)
                                }}
                                renderInput={(params) =>
                                    <TextField {...params} variant="standard" placeholder="find fans..." />}
                            />
                        </div>
                    </div>
                    {inbox.inbox.length == 0 ?
                        <a style={{ textAlign: 'center' }}>
                            <div className="media">
                                <div className="media-body">
                                    <p>no message available.</p>
                                </div>
                            </div>
                        </a> : null}
                    <ul className="nav nav-tabs" id="myTab" role="tablist">

                        {inbox.inbox.map((data, i) => <li key={i} className="nav-item" role="presentation">
                            <a onClick={() => gotomessage(data.commenID, data.to_user_id)} className="nav-link" >
                                <div className="media list-media">
                                    <div className="story-img">
                                     
                                        <div className="user-img">
                                        <Stack direction="row" spacing={2}>
                                            <Avatar  alt={data?.firstname} src={data?.profile_image} />
                                        </Stack>
                                        <span className="available-stats online"></span>
                                        </div>

                                    </div>

                                    <div className="media-body">
                                        <h5>{data?.firstname} {data?.lastname} {data?.last_seen == 'online'?<span  className="inbox available-stats online " style={{marginLeft:'5px'}}></span>:null} <span>{moment(data?.dateTime).format('LT')}</span></h5>
                                        <div className="chat">
                                    <h6 style={{ width: '100%' }}>{data?.message}</h6>
                                    {data?.unreadCount != 0 ? <span className="count" style={{ float: 'right' }}>{data?.unreadCount}</span> : null}
                                </div>
                                    </div>
                                </div>
                                
                            </a>
                        </li>)}



                    </ul>
                </div>
            </section>
        </div>
    );
}

export default InboxmobileMessages;