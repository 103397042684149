import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from 'formik';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Swal from "sweetalert2";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import api from "../Api";
import Geocode from "react-geocode";

const validateUser = logData => {
    const errors = {};
    if (!logData.password) {
        errors.password = '*Please Enter Password';
    } else if (logData.password.length > 20) {
        errors.password = '*Password cannot exceed 10 characters';
    }

    if (!logData.email) {
        errors.email = '*Please Enter Email';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(logData.email)) {
        errors.email = '*Invalid email address';
    }
    return errors;
};

function Login() {
    const currentUser = JSON.parse(sessionStorage.getItem('user') || null)
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [ip, setIp] = useState('');
    const [location, setLocation] = useState('');

    useEffect(() => {
        getLocation();
        if (currentUser != null) {
            history.push(`/home`);
        }
    }, []);

    function text(url) {
        return fetch(url).then(res => res.text());
    }

    text('https://www.cloudflare.com/cdn-cgi/trace').then(data => {
        let ipRegex = /[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}/
        let ip = data.match(ipRegex)[0];
        setIp(ip)
    });

    const getDeviceType = () => {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            return "tablet";
        }
        if (
            /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                ua
            )
        ) {
            return "mobile";
        }
        return "desktop";
    };

    const goToPass = () => {
        history.push('/email-verification')
    }

    const goToSignUp = () => {
        history.push('/signup')
    }

    const formik = useFormik({
        initialValues: {
            password: '',
            email: ''
        },
        validate: validateUser,
        onSubmit: values => {
            setLoading(true);
            var res = '';
            api.post('user/login', values).then(data => {
             
                if (data.data.Status == false) {
                    Swal.fire({
                        title: 'Warning',
                        text: data.data.Message,
                        icon: 'warning',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok',
                        timer: 1500
                    });
                    setLoading(false);
                } else {
                 
                    // Swal.fire({  
                    //   title: 'Successs',  
                    //   text: data.data.Message,  
                    //   icon: 'success',   
                    //   confirmButtonColor: '#3085d6',  
                    //   confirmButtonText: 'Yes!',
                    //   timer: 1500  
                    // });
                    res = data.data
                    sessionStorage.setItem('user', JSON.stringify(data.data))
                    if (data.data.status == 0) {
                        var module = {
                            options: [],
                            header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
                            dataos: [
                                { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
                                { name: 'Windows', value: 'Win', version: 'NT' },
                                { name: 'iPhone', value: 'iPhone', version: 'OS' },
                                { name: 'iPad', value: 'iPad', version: 'OS' },
                                { name: 'Kindle', value: 'Silk', version: 'Silk' },
                                { name: 'Android', value: 'Android', version: 'Android' },
                                { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
                                { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
                                { name: 'Macintosh', value: 'Mac', version: 'OS X' },
                                { name: 'Linux', value: 'Linux', version: 'rv' },
                                { name: 'Palm', value: 'Palm', version: 'PalmOS' }
                            ],
                            databrowser: [
                                { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
                                { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
                                { name: 'Safari', value: 'Safari', version: 'Version' },
                                { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
                                { name: 'Opera', value: 'Opera', version: 'Opera' },
                                { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
                                { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
                            ],
                            init: function () {
                                var agent = this.header.join(' '),
                                    os = this.matchItem(agent, this.dataos),
                                    browser = this.matchItem(agent, this.databrowser);

                                return { os: os, browser: browser };
                            },
                            matchItem: function (string, data) {
                                var i = 0,
                                    j = 0,
                                    regex,
                                    regexv,
                                    match,
                                    matches,
                                    version;

                                for (i = 0; i < data.length; i += 1) {
                                    regex = new RegExp(data[i].value, 'i');
                                    match = regex.test(string);
                                    if (match) {
                                        regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                                        matches = string.match(regexv);
                                        version = '';
                                        if (matches) { if (matches[1]) { matches = matches[1]; } }
                                        if (matches) {
                                            matches = matches.split(/[._]+/);
                                            for (j = 0; j < matches.length; j += 1) {
                                                if (j === 0) {
                                                    version += matches[j] + '.';
                                                } else {
                                                    version += matches[j];
                                                }
                                            }
                                        } else {
                                            version = '0';
                                        }
                                        return {
                                            name: data[i].name,
                                            version: parseFloat(version)
                                        };
                                    }
                                }
                                return { name: 'unknown', version: 0 };
                            }
                        };
                        var e = module.init(),
                        debug = '';
                        let body = {
                            user_id: data.data.id,
                            user_name: data.data.user_name,
                            email: data.data.email,
                            token: data.data.token,
                            location: location,
                            ip_address: ip,
                            device: getDeviceType(),
                            browser: e.browser.name,
                            os: e.os.name,
                            date_time: new Date()
                        }
                        api.post('/loginHist/insert', body).then(data => {
                            console.log(data)
                            if (data.data.Status == false) {
                                Swal.fire({
                                    title: 'Warning',
                                    text: data.data.Message,
                                    icon: 'warning',
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'Ok',
                                    timer: 1500
                                });
                                setLoading(false);
                            } else {
                                setLoading(false);
                                history.push('/account-verification');
                            }
                        })

                    } else {
                        var module = {
                            options: [],
                            header: [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor, window.opera],
                            dataos: [
                                { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
                                { name: 'Windows', value: 'Win', version: 'NT' },
                                { name: 'iPhone', value: 'iPhone', version: 'OS' },
                                { name: 'iPad', value: 'iPad', version: 'OS' },
                                { name: 'Kindle', value: 'Silk', version: 'Silk' },
                                { name: 'Android', value: 'Android', version: 'Android' },
                                { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
                                { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
                                { name: 'Macintosh', value: 'Mac', version: 'OS X' },
                                { name: 'Linux', value: 'Linux', version: 'rv' },
                                { name: 'Palm', value: 'Palm', version: 'PalmOS' }
                            ],
                            databrowser: [
                                { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
                                { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
                                { name: 'Safari', value: 'Safari', version: 'Version' },
                                { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
                                { name: 'Opera', value: 'Opera', version: 'Opera' },
                                { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
                                { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
                            ],
                            init: function () {
                                var agent = this.header.join(' '),
                                    os = this.matchItem(agent, this.dataos),
                                    browser = this.matchItem(agent, this.databrowser);

                                return { os: os, browser: browser };
                            },
                            matchItem: function (string, data) {
                                var i = 0,
                                    j = 0,
                                    regex,
                                    regexv,
                                    match,
                                    matches,
                                    version;

                                for (i = 0; i < data.length; i += 1) {
                                    regex = new RegExp(data[i].value, 'i');
                                    match = regex.test(string);
                                    if (match) {
                                        regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
                                        matches = string.match(regexv);
                                        version = '';
                                        if (matches) { if (matches[1]) { matches = matches[1]; } }
                                        if (matches) {
                                            matches = matches.split(/[._]+/);
                                            for (j = 0; j < matches.length; j += 1) {
                                                if (j === 0) {
                                                    version += matches[j] + '.';
                                                } else {
                                                    version += matches[j];
                                                }
                                            }
                                        } else {
                                            version = '0';
                                        }
                                        return {
                                            name: data[i].name,
                                            version: parseFloat(version)
                                        };
                                    }
                                }
                                return { name: 'unknown', version: 0 };
                            }
                        };

                        var e = module.init(),
                            debug = '';
                       
                        let body = {
                            user_id: data.data.id,
                            user_name: data.data.user_name,
                            email: data.data.email,
                            token: data.data.token,
                            location: location,
                            ip_address: ip,
                            device: getDeviceType(),
                            browser: e.browser.name,
                            os: e.os.name,
                            date_time: new Date()
                        }
                        api.post('/loginHist/insert', body).then(data => {
                            if (data.data.Status == false) {
                                Swal.fire({
                                    title: 'Warning',
                                    text: data.data.Message,
                                    icon: 'warning',
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'Ok',
                                    timer: 1500
                                });
                                setLoading(false);
                            } else {
                               
                                setLoading(false);
                                history.push(`/home`);
                            }
                        })

                    }
                }
            })

        }
    });

    Geocode.setApiKey("AIzaSyCJO17hr0x0XPD8fhY4WxgxGb1muNBEhfE");
    Geocode.enableDebug();
    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            alert("Geolocation is not supported by this browser.");
        }
    }

    function showPosition(position) {
        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(
            response => {
                const address = response.results[0].formatted_address;
                setLocation(address);
            },
            error => {
                console.error(error);
            }
        );
    }


    return (
        <div>
            <section className="login-section">
                <div className="container">
                    <div className='row'>
                        <div className='col-xl-4 col-lg-4 col-md-4'>
                            <img  src="../assets/images/icon/logo.png" alt="logo" className="img-fluid blur-up lazyload" />
                        </div>
                        <div className='col-xl-8 col-lg-8 col-md-8'>

                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-xl-8 col-lg-5 d-none d-lg-block">

                            <div className="login-welcome">
                                <div style={{ textAlign: 'left' }}>
                                    <h1 style={{ textAlign: 'left', fontSize: '34px' }}>Connect with Creators & Fans</h1>
                                    <p className='mt-4' style={{ textAlign: 'left', color: 'white', fontSize: '18px' }}>More than 2 million people in over countries use 'Match With Fans' to stay in touch with creators & fans.</p>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-7 col-md-10 col-12 m-auto">
                            <div className="login-form">
                                <div>
                                    <div className="login-title">
                                        <h3><b>Sign In</b></h3>
                                    </div>
                                    <div className="login-discription">
                                        <h4>Welcome to Match With Fans</h4>
                                    </div>
                                    <div className="form-sec">

                                        <div>
                                            <form className="theme-form" >

                                                <div className="form-group">
                                                    {/* <label >Email</label> */}
                                                    <input type="email" name="email" className="form-control" placeholder="Email"
                                                        value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                    {formik.touched.email && formik.errors.email ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.email}</span> : null}
                                                </div>
                                                <div className="form-group">
                                                    {/* <label >Password</label> */}
                                                    <input type={isRevealPwd ? "text" : "password"} name="password" className="form-control" value={formik.values.password}
                                                        onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Password" />
                                                    <div className="passIcon">
                                                        {isRevealPwd ? <VisibilityOffIcon onClick={() => setIsRevealPwd(prevState => !prevState)} /> :
                                                            < VisibilityIcon onClick={() => setIsRevealPwd(prevState => !prevState)} />
                                                        }
                                                    </div>
                                                    {formik.touched.password && formik.errors.password ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.password}</span> : null}
                                                </div>
                                                <div className="row pt-2 pb-2">
                                                    <div className="col-md-2" />
                                                    <div className="col-md-8">
                                                        <div style={{ textAlign: 'center' }}>
                                                            <Stack >
                                                                <Button variant="contained" disabled={!formik.isValid} className='br' onClick={formik.handleSubmit}>
                                                                    {loading ? <span><span className="spinner-border spinner-border-sm"></span>wait..</span> : 'Sign In'}
                                                                </Button>
                                                            </Stack>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2" />
                                                </div>

                                            </form>

                                            <div style={{ textAlign: 'center' }} className="pt-2 pb-2">
                                                <span onClick={goToPass}>Forget Password?</span> &nbsp;| &nbsp;
                                                <span onClick={goToSignUp}>Sign up Now</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
}
export default Login;

