import { createSlice } from '@reduxjs/toolkit'
import api from "../Api";
import Swal from "sweetalert2";
// Slice

const initialUser = sessionStorage.getItem('user')? JSON.parse(localStorage.getItem('user')) : null
const slice = createSlice({
  name: 'user',
  initialState: {
    user: null,
  },
  reducers: {
    RegisterSuccess: (state, action) => {
      state.user = action.payload;
      // console.log(state.user)
    },

    loginSuccess: (state, action) => {
      state.user = action.payload;
      // console.log(state.user)
      sessionStorage.setItem('user', JSON.stringify(action.payload))
    },
    
    logoutSuccess: (state, action) =>  {
      state.user = null;
      sessionStorage.removeItem('user')
    },
  },
});

export default slice.reducer
// Actions
const { loginSuccess, logoutSuccess, RegisterSuccess } = slice.actions;

export const signup = (body) => async dispatch => {
  try {
    const res = await api.post('user/insert', body);
    if(res.data.Status == false){
      Swal.fire({  
        title: 'Warning',  
        text: res.data.Message,  
        icon: 'warning',   
        confirmButtonColor: '#3085d6',  
        confirmButtonText: 'Ok',
        timer: 1500  
      });
    }else{
      Swal.fire({  
        title: 'Successs',  
        text: res.data.Message,  
        icon: 'success',   
        confirmButtonColor: '#3085d6',  
        confirmButtonText: 'Yes!',
        timer: 1500  
      });
      dispatch(RegisterSuccess(res.data));
    }

  } catch (e) {
    return console.error(e.message);
  }
}

export const login = (body) => async dispatch => {
  try {
    const res = await api.post('user/login', body);
    if(res.data.Status == false){
      Swal.fire({  
        title: 'Warning',  
        text: res.data.Message,  
        icon: 'warning',   
        confirmButtonColor: '#3085d6',  
        confirmButtonText: 'Ok',
        timer: 1500  
      });
    }else{
      // Swal.fire({  
      //   title: 'Successs',  
      //   text: res.data.Message,  
      //   icon: 'success',   
      //   confirmButtonColor: '#3085d6',  
      //   confirmButtonText: 'Yes!',
      //   timer: 1500  
      // });
      dispatch(loginSuccess(res.data));
    }

  } catch (e) {
    return console.error(e.message);
  }
}


export const logout = () => async dispatch => {
  try {
    // const res = await api.post('/api/auth/logout/')
    return dispatch(logoutSuccess())
  } catch (e) {
    return console.error(e.message);
  }
}