import * as React from 'react';
import { useState } from "react";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {useDispatch, useSelector} from 'react-redux';
import { EmailVerfy } from '../store/verifyEmail'
import { useFormik } from 'formik';
import { useHistory } from "react-router-dom";

const validateUser = logData => {
    const errors = {};
    if (!logData.email) {
        errors.email = '*Please Enter Email';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(logData.email)) {
        errors.email = '*Invalid email address';
    }
    return errors;
};

function EmailVerificationp() {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const { verifyEmail } = useSelector(state => state)

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validate: validateUser,
        onSubmit: values => {
            dispatch(EmailVerfy(values));
            setLoading(true);
            setTimeout(() => {
             setLoading(false);
            }, 1000);
        }
    });

    const goToLogin = () =>{
        history.push('/')
    }

    if (verifyEmail.UserEmailVerify) {
        if(verifyEmail.UserEmailVerify.Status === true){
            history.push('/')
        }
     }else{}

    return (
        <div>
        
        <section className="login-section">
            <div className="header-section">
            </div>
            <div className="container">
            <div className='row'>
                        <div className='col-xl-4 col-lg-4 col-md-4'>
                            <img src="../assets/images/icon/logo.png" alt="logo" className="img-fluid blur-up lazyload" />
                        </div>
                        <div className='col-xl-8 col-lg-8 col-md-8'>

                        </div>
                    </div>
                <div className="row mt-2">
                <div className="col-xl-8 col-lg-5 d-none d-lg-block pt-5">
                  
                            <div className="login-welcome">
                                <div style={{textAlign:'left'}}>
                                    <h1 style={{textAlign:'left', fontSize:'34px'}}>Connect with Creators & Fans</h1>
                                    <p className='mt-4' style={{textAlign:'left', color:'white', fontSize:'18px'}}>More than 2 million people in over countries use 'Match With Fans' to stay in touch with creators & fans.</p>
                                    
                                </div>
                            </div>
                        </div>
                    <div className="col-xl-4 col-lg-7 col-md-10 col-12 m-auto">
                        <div className="login-form">
                            <div>
                            <div className="login-title">
                                        <h3>Reset Your Account</h3>
                                    </div>
                                    <div className="login-discription">
                                        <h4>Please enter your email. We will send you an email to reset your password.</h4>
                                    </div>
                                <div className="form-sec">

                                    <div>
                                        <form className="theme-form">
                                            <div className="form-group">
                                            <input type="email" name="email" className="form-control" placeholder="Enter email"
                                                value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                {formik.touched.email && formik.errors.email ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.email}</span> : null}
                                            </div>

                                            <div className="row pt-2 pb-3">
                                                <div className="col-md-2" />
                                                <div className="col-md-8">
                                                        <div style={{ textAlign: 'center' }}>
                                                            <Stack >
                                                                <Button variant="contained" disabled={!formik.isValid} className='br' onClick={formik.handleSubmit}>
                                                                    {loading ? <span><span className="spinner-border spinner-border-sm"></span> Please wait...</span> : 'Submit'}</Button>
                                                            </Stack>
                                                        </div>
                                                    </div>
                                                <div className="col-md-2" />
                                            </div>
                                           
                                        </form>

                                        <div style={{ textAlign: 'center' }} className="pb-2">
                                        <p onClick={goToLogin}><span>Already have an account? </span><span style={{color:'blue'}}>Sign In</span></p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    );
}

export default EmailVerificationp;



