import React, { useState, useEffect } from "react";
import PostLikes from "./likes/likes";
import PostComment from "./likes/comments";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import { ReactVideo } from "reactjs-media";
import PaidPost from "../../lightbox/postPaid";
import { useHistory } from "react-router-dom";
import api from "../../Api";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import SendDialog from "../../Dialog/sendTips";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import Tooltip from '@material-ui/core/Tooltip';
import PaymentDetails from "../../AddCards/patmentpage";
const Post = (props) => {
    const history = useHistory();
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const [postData, setPostData] = useState([]);
    const [commentPanel, setCommentPanel] = useState([false]);
    const [clas, setClas] = React.useState([false]);
    const [animation, setAnimation] = useState(true);
    const [tips, setTips] = useState([false]);

    useEffect(() => {

        getPost()

    }, [props.props]);

    const getPost = () => {
        api.get(`/get-user-post/${props.props}/${currentUser.id}`).then(res => {
            if (res.data.Status != false) {
                res.data.forEach(element => {
                    element.to_user_id = element.user_id;
                    element.pay_on_type = 'post'
                });
                setTimeout(() => {
                    setPostData(res.data);
                }, 500);
                setAnimation(false)
            } else {
                setAnimation(false)
                setPostData([]);
            }
        })
    }

    const commentsPost = (id) => {
        let dataToggle = {}
        if (!commentPanel[id]) {
            dataToggle[id] = true
            setCommentPanel(dataToggle);
        } else {
            dataToggle[id] = false
            setCommentPanel(dataToggle);
        }
    }

    const contantToggle = (id) => {
        let ClassToggle = {}
        if (!clas[id]) {
            ClassToggle[id] = true
            setClas(ClassToggle);
        } else {
            ClassToggle[id] = false
            setClas(ClassToggle);
        }
    }

    const lightBoxHandlerBar = (data) => {
        history.push(`/media/${data}`);
    }

    const handleCallback = (childData) => {
        getPost();
    }
    const handleCallbacks = (childData) => {
        getPost();
    }

    const sentTpsData = (id) => {
        let dataToggle = {}
        if (!tips[id]) {
            dataToggle[id] = true;
            setTips(dataToggle);

        } else {
            dataToggle[id] = false;
            setTips(dataToggle);
        }
    }
    return (
        <>
            {animation ? <div className="pre-loader">
                <div className="content-left">
                    <div className="suggestion-box section-t-space">
                        <div className="suggestion-content ratio_115">
                            <div>
                                <div className="overlay-bg" />
                                <div className="post-panel section-t-space">
                                    <div className="post-wrapper col-grid-box">
                                        <div className="post-title">
                                            <div className="profile">
                                                <div className="media">
                                                    <div className="user-img">
                                                    </div>
                                                    <div className="media-body">
                                                        <h5 />
                                                        <h6 />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="post-details ratio2_1">
                                            <div className="img-wrapper bg-size">
                                            </div>
                                            <div className="detail-box">
                                                <div className="ldr-p">
                                                    <p />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-wrapper col-grid-box section-t-space">
                                        <div className="post-title">
                                            <div className="profile">
                                                <div className="media">
                                                    <div className="user-img">
                                                    </div>
                                                    <div className="media-body">
                                                        <h5 />
                                                        <h6 />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="post-details ratio2_1">
                                            <div className="img-wrapper bg-size">
                                            </div>
                                            <div className="detail-box">
                                                <div className="ldr-p">
                                                    <p />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> :
                <div className="content-center">
                    <div className="overlay-bg" />
                    <div className="post-panel  section-t-space">
                        <div className="post-panel  section-t-space">
                            {postData.map((data, i) => <div className="post-wrapper col-grid-box" key={i}>

                                <div className="post-title">
                                    <div className="profile">
                                        <div className="media">
                                            <a className="popover-cls user-img" data-bs-toggle="popover" data-placement="right" data-name="sufiya eliza" data-img="../assets/images/user-sm/1.jpg">
                                                <Stack direction="row" spacing={0}>
                                                    <Avatar style={{ width: '50px', height: '50px' }} className="img-fluid-post blur-up lazyload bg-img imgrad" alt={data?.firstname} src={data?.isProfile} />
                                                </Stack>
                                            </a>
                                            <div className="media-body">
                                                <a style={{ textAlign: 'left' }}>
                                                    <h5 style={{ marginBottom: '4px', color: '#0389c9', fontSize: '14px' }}>{data?.firstname} {data?.lastname}</h5>
                                                    <h6 style={{ fontSize: '10px', }}>{moment(data?.created_at).format('lll')}</h6>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="post-details">
                                    <div className="detail-box">
                                        {clas[data.ID] ? <p style={{ fontSize: '14px' }}>{data?.content} </p> : <p className='blogDes' style={{ fontSize: '14px' }}>{data?.content} </p>}
                                        {data?.content.length > 150 ? <p onClick={() => contantToggle(data.ID)} style={{ fontSize: '10px', textAlign: 'right', marginTop: '0px', cursor: 'grab', color: '#0389c9' }}>{clas[data.ID] ? <span>Less...</span> : <span>More...</span>}</p> : null}
                                    </div>
                                </div>
                                <div className="img-wrapper">
                                    <div className="gallery-section">
                                        <div className="portfolio-section ratio_square">
                                            {data?.post_for_free === "free" ||  data?.post_pay_status === '1'  ? 
                                             <div className="container-fluid p-0">
                                                {data.media_data.length == 1 ? <div className="row">
                                                    {data.media_data.map((media, i) =>
                                                        <div className="col-12 m-0" key={i}>
                                                            <div className="overlay">
                                                                <div className="portfolio-image">
                                                                    {media?.media_type == 'image' ?
                                                                        <div className='imagecontainer1'>
                                                                            <img key={i + 1} src={media?.s3postmediaurl} style={{ width: '100%', }} className="img-fluid blur-up lazyload" alt='true'
                                                                                onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                onError={(event) => event.target.removeAttribute('src')} loading="lazy"
                                                                                onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                            />
                                                                        </div>
                                                                        : null}
                                                                    {media?.media_type == 'video' ?
                                                                        <div className='videocontainer2'>
                                                                            <ReactVideo
                                                                                poster='../assets/images/icon/logo.png'
                                                                                src={media?.s3postmediaurl}
                                                                                width='100%'
                                                                                height='250px !important'
                                                                                controls playsinline />
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                </div> : null}
                                                {data.media_data.length == 2 ? <div className="row">
                                                    {data.media_data.map((media, i) =>
                                                        <div className="col-6 m-0" key={i}>
                                                            <div className="overlay">
                                                                <div className="portfolio-image">
                                                                    {media?.media_type == 'image' ?
                                                                        <div className='imagecontainer2'>
                                                                            <img src={media?.s3postmediaurl} className="containerImage2" alt='true'
                                                                                onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                onError={(event) => event.target.removeAttribute('src')} loading="lazy"
                                                                                onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                            />
                                                                        </div>
                                                                        : null}

                                                                    {media?.media_type == 'video' ?
                                                                        <div className='videocontainer2'>
                                                                            <ReactVideo
                                                                                poster='../assets/images/icon/logo.png'
                                                                                src={media?.s3postmediaurl}
                                                                                width='100%'
                                                                                height='250px !important'
                                                                                controls playsinline />
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                </div> : null}
                                                {data.media_data.length == 3 ? <div className="row" >
                                                    {data.media_data.map((media, i) =>
                                                        i <= 1 ? <div className="col-6 m-0" key={i}>
                                                            <div className="overlay">
                                                                <div className="portfolio-image">
                                                                    {media?.media_type == 'image' ?
                                                                        <div className='imagecontainer3'>
                                                                            <img src={media?.s3postmediaurl} className="containerImage3" style={{ width: '100%', objectFit: 'cover' }}
                                                                                onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                onError={(event) => event.target.removeAttribute('src')} loading="lazy"
                                                                                onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                            />
                                                                        </div>
                                                                        : null}

                                                                    {media?.media_type == 'video' ?
                                                                        <div className='videocontainer2 mb-1'>
                                                                            <ReactVideo
                                                                                poster='../assets/images/icon/logo.png'
                                                                                src={media?.s3postmediaurl}
                                                                                width='100%'
                                                                                height='250px !important'
                                                                                controls playsinline />
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div> : <div className="col-12 m-0">
                                                            <div className="overlay">
                                                                <div className="portfolio-image">
                                                                    {media?.media_type == 'image' ?
                                                                        <div className='imagecontainer3'>
                                                                            <img src={media?.s3postmediaurl} style={{ width: '100%', objectFit: 'cover' }} className="containerImage3" alt='true'
                                                                                onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                onError={(event) => event.target.removeAttribute('src')} loading="lazy"
                                                                                onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                            />
                                                                        </div>
                                                                        : null}

                                                                    {media?.media_type == 'video' ?
                                                                        <div className='videocontainer2 mb-1'>
                                                                            <ReactVideo
                                                                                poster='../assets/images/icon/logo.png'
                                                                                src={media?.s3postmediaurl}
                                                                                width='100%'
                                                                                height='250px !important'
                                                                                controls playsinline />
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                </div> : null}
                                                {data.media_data.length == 4 ? <div className="row">
                                                    {data.media_data.map((media, i) =>
                                                        <div className="col-6 m-0" key={i}>
                                                            <div className="overlay">
                                                                <div className="portfolio-image">
                                                                    {media?.media_type == 'image' ?
                                                                        <div className='imagecontainer3'>
                                                                            <img src={media?.s3postmediaurl} style={{ width: '100%', objectFit: 'cover' }} className="containerImage3" alt='true'
                                                                                onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                onError={(event) => event.target.removeAttribute('src')} loading="lazy"
                                                                                onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                            />
                                                                        </div>
                                                                        : null}
                                                                    {media?.media_type == 'video' ?
                                                                        <div className='videocontainer2 mb-1'>
                                                                            <ReactVideo
                                                                                poster='../assets/images/icon/logo.png'
                                                                                src={media?.s3postmediaurl}
                                                                                width='100%'
                                                                                height='250px !important'
                                                                                controls playsinline />
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                </div> : null}
                                                {data.media_data.length > 4 ? <div className="row">
                                                    {data.media_data.map((media, i) =>
                                                        i <= 2 ? <div className="col-6 m-0" key={i}>
                                                            <div className="overlay">
                                                                <div className="portfolio-image">
                                                                    {media?.media_type == 'image' ?
                                                                        <div className='imagecontainer3'>
                                                                            <img src={media?.s3postmediaurl} style={{ width: '100%', objectFit: 'cover' }} className="containerImage3" alt='true'
                                                                                onClick={() => lightBoxHandlerBar(data.ID)}
                                                                                onError={(event) => event.target.removeAttribute('src')} loading="lazy"
                                                                                onError={(event) => event.target.src = '../assets/images/cover/1.jpg'}
                                                                            />
                                                                        </div>
                                                                        : null}

                                                                    {media?.media_type == 'video' ?
                                                                        <div className='videocontainer2 mb-1'>
                                                                            <ReactVideo
                                                                                poster='../assets/images/icon/logo.png'
                                                                                src={media?.s3postmediaurl}
                                                                                width='100%'
                                                                                height='250px !important'
                                                                                controls playsinline />
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                        </div> :
                                                            <div className="col-6 m-0">
                                                                {i == 3 ? <div>
                                                                    <div className='imagecontainer3'>
                                                                        <div className="portfolio-image">
                                                                            <img src={media?.s3postmediaurl} alt='true' className="containerImage3" />
                                                                        </div>
                                                                    </div>
                                                                    {i == 4 ?
                                                                        <div className="imagecontainer3" style={{ position: 'absolute', top: '0px', }}>
                                                                            <div className='portfolio-image'>
                                                                                <a data-bs-toggle="modal" data-bs-target="#imageModel" >
                                                                                    <img src={media?.s3postmediaurl} alt='true' className="containerImage3" />
                                                                                </a>
                                                                            </div>
                                                                        </div> : <div className="imagecontainer3" style={{ position: 'absolute', top: '0px', }}>
                                                                            <div className="portfolio-image">
                                                                                <a data-bs-toggle="modal" data-bs-target="#imageModel" >
                                                                                    <h1 className='imageCount'>{data.media_data.length - 4}+</h1>
                                                                                    <img src={media?.s3postmediaurl} alt='true' className="containerImage3" style={{ filter: 'brightness(0.5)' }} />
                                                                                </a>
                                                                            </div>
                                                                        </div>}
                                                                </div> : null}
                                                            </div>
                                                    )}
                                                    <div>
                                                    </div>
                                                </div> : null}

                                                <div className="post-react">
                                                    <ul style={{ justifyContent: 'unset' }}>
                                                        <li className="comment-click">
                                                            <PostLikes props={data} parentCallback={handleCallback} />
                                                        </li>
                                                        <li >
                                                            <a onClick={() => sentTpsData(data.ID)}> <Tooltip title="Sent Tip" ><MonetizationOnIcon style={{ fontSize: '18px' }} /></Tooltip></a>
                                                        </li>
                                                        <li style={{ position: 'absolute', right: '20px' }}>
                                                            <a onClick={() => commentsPost(data.ID)}><ChatBubbleOutlineIcon style={{ fontSize: '16px' }} /><span>{data?.total_comments}</span></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                {commentPanel[data.ID] ? <PostComment props={data} parentCallback={handleCallback} /> : null}
                                            </div> :
                                                <div><PaidPost prop={data} parentCallbacks={handleCallbacks} /></div>}
                                        </div>
                                    </div>
                                </div>
                                {tips[data.ID] ? <SendDialog props={data} /> : null}
                            </div>)}
                            {postData.length == 0 ?
                                <div className="row">
                                    <div className='col-md-12' style={{ textAlign: 'center' }}>
                                        <h3 className="mt-4 mb-4">No post available..</h3>
                                    </div>
                                </div> : null}

                        </div>

                    </div>
                </div>
                }
        </>)

}

export default Post
