import { createSlice } from '@reduxjs/toolkit'
import api from "../../Api";
import Swal from "sweetalert2";

// Slice
const slice = createSlice({
    name: 'msg',
    initialState: {
        msg: [],
    },
    reducers: {
      MsgSuccess:(data, action) => {
            data.msg = action.payload;
      },
    },
  });
  
  export default slice.reducer
  // Actions
  const { MsgSuccess } = slice.actions;
  
  export const getMsg = (id) => async dispatch => {
    try {
      const res = await api.get(`user/single/message/${id}`);
      if(res.data.Status == false){
        Swal.fire({  
          title: 'Warning',  
          text: res.data.Message,  
          icon: 'warning',   
          confirmButtonColor: '#3085d6',  
          confirmButtonText: 'Ok',
          timer: 1500  
        });
      }else{
        dispatch(MsgSuccess(res.data));
      }
  
    } catch (e) {
      return console.error(e.message);
    }
  }