import * as React from 'react';
import {useState } from "react";
import { useFormik } from 'formik';
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {useDispatch, useSelector} from 'react-redux';
import { PaasswordVerfy } from '../store/forgotPassword'

const validateUser = forgotPass => {
    const errors = {};
    const pattern = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');
    if (!forgotPass.password) {
        errors.password = '*Please Enter Password';
    } else if (!pattern.test(forgotPass.password)) {
        errors.password = `*Password Minimum eight characters, at
        least one uppercase letter, one lowercase letter, one number and one
        special character`;
    }

    if (!forgotPass.confirm_password) {
        errors.confirm_password = 'Please Enter Confirm Password';
    } else if (!pattern.test(forgotPass.confirm_password)) {
        errors.confirm_password = `*Password Minimum eight characters, at
        least one uppercase letter, one lowercase letter, one number and one
        special character`;
    }

    return errors;
};

function ForgotPassword() {
    const dispatch = useDispatch()
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isRevealPwd2, setIsRevealPwd2] = useState(false);
    const [loading, setLoading] = useState(false);
    const { forgotPassword } = useSelector(state => state)
    const history = useHistory();
    const token = useParams().id;
    const formik = useFormik({
        initialValues: {
            password: '',
            confirm_password: ''
        },
        validate: validateUser,
        onSubmit: values => {
            let body = {
                password:values.password,
                token:token  
            }
            dispatch(PaasswordVerfy(body));
            setLoading(true);
            setTimeout(() => {
             setLoading(false);
            }, 1000);
        }
    });
    
    const goToLogin = () => {
        history.push('/')
    }
    if (forgotPassword.UserPaassword) {
        if(forgotPassword.UserPaassword.Status === true){
            history.push('/')
        }
     }else{}

    return (
        <div>

            <section className="login-section">
                <div className="header-section">
                </div>
                <div className="container">
                <div className='row'>
                <div className='col-xl-4 col-lg-4 col-md-4'>
                    <img src="../assets/images/icon/logo.png" alt="logo" className="img-fluid blur-up lazyload" />
                </div>
                <div className='col-xl-8 col-lg-8 col-md-8'>

                </div>
            </div>
                    <div className="row">
                    <div className="col-xl-8 col-lg-5 d-none d-lg-block">
                    <img style={{ height:'92px'}} src="../assets/images/icon/logo.png" alt="logo" className="img-fluid blur-up lazyload" />
                            <div className="login-welcome">
                                <div style={{textAlign:'left'}}>
                                    <h1 style={{textAlign:'left', fontSize:'34px'}}>Connect with Creators & Fans</h1>
                                    <p className='mt-4' style={{textAlign:'left', color:'white', fontSize:'18px'}}>More than 2 million people in over countries use 'Match With Fans' to stay in touch with creators & fans.</p>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-7 col-md-10 col-12 m-auto">
                            <div className="login-form">
                                <div>
                                    <div className="login-title">
                                        <h3><b>Forgot Password</b></h3>
                                    </div>
                                    <div className="login-discription">
                                        <h4>Please enter new password to reset your account.</h4>
                                    </div>
                                    <div className="form-sec">

                                        <div>
                                            <form className="theme-form">

                                                <div className="row">
                                                    <div className="form-group col-md-12">
                                                        {/* <label htmlFor="exampleInputPassword1">Password</label> */}
                                                        <input type={isRevealPwd ? "text" : "password"} name="password" className="form-control" id="password" value={formik.values.password}
                                                            onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Password" />
                                                        <div className="passIcon">
                                                            {isRevealPwd ? <VisibilityOffIcon onClick={() => setIsRevealPwd(prevState => !prevState)} /> :
                                                                <VisibilityIcon onClick={() => setIsRevealPwd(prevState => !prevState)} />
                                                            }
                                                        </div>
                                                        {formik.touched.password && formik.errors.password ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.password}</span> : null}
                                                    </div>
                                                    <div className="form-group col-md-12">
                                                        {/* <label htmlFor="exampleInputPassword1">Confirm Password</label> */}
                                                        <input type={isRevealPwd2 ? "text" : "password"} name="confirm_password" className="form-control" value={formik.values.confirm_password}
                                                            onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Confirm Password" />
                                                        <span className="passIcon">
                                                            {isRevealPwd2 ? <VisibilityOffIcon onClick={() => setIsRevealPwd2(prevState => !prevState)} /> :
                                                                <VisibilityIcon onClick={() => setIsRevealPwd2(prevState => !prevState)} />
                                                            }
                                                        </span>
                                                        {formik.touched.confirm_password && formik.errors.confirm_password ? <span style={{ color: 'red', fontSize: '10px' }}>{formik.errors.confirm_password}</span> : null}
                                                    </div>
                                                </div>

                                                <div className="row pt-2 pb-3">
                                                    <div className="col-md-2" />
                                                    <div className="col-md-8">
                                                        <div style={{ textAlign: 'center' }}>
                                                            <Stack >
                                                                <Button variant="contained" disabled={!formik.isValid} className='br' onClick={formik.handleSubmit}>
                                                                    {loading ? <span><span className="spinner-border spinner-border-sm"></span> Please wait...</span> : 'Submit'}
                                                                </Button>
                                                            </Stack>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2" />
                                                </div>

                                            </form>

                                            <div style={{ textAlign: 'center' }} className="pb-2">
                                                <p onClick={()=>goToLogin()}><span>Already have an account? </span><span style={{ color: 'blue' }}>Sign In</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ForgotPassword;