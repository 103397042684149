import { createSlice } from '@reduxjs/toolkit'
import api from "../../Api";
import Swal from "sweetalert2";

// Slice
const slice = createSlice({
    name: 'postinsert',
    initialState: {
        postinsert: '',
    },
    reducers: {
        PostInsertSuccess:(state, action) => {
          state.postinsert = action.payload;
      },
    },
  });
  
  export default slice.reducer
  // Actions
  const { PostInsertSuccess } = slice.actions;
  
  export const UserPostInsert = (body) => async dispatch => {
    try {
      const res = await api.post('/user/create/post', body);
      if(res.data.Status == false){
        // Swal.fire({  
        //   title: 'Warning',  
        //   text: res.data.Message,  
        //   icon: 'warning',   
        //   confirmButtonColor: '#3085d6',  
        //   confirmButtonText: 'Ok',
        //   timer: 1500  
        // });
      }else{
        // Swal.fire({  
        //     title: 'Success',  
        //     text: res.data.Message,  
        //     icon: 'success',   
        //     confirmButtonColor: '#3085d6',  
        //     confirmButtonText: 'Ok',
        //     timer: 1500  
        //   });
        dispatch(PostInsertSuccess(res.data));
      }
  
    } catch (e) {
      return console.error(e.message);
    }
  }