import React, { useState, useEffect, useRef } from "react";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { useDispatch } from 'react-redux';
import { PostComm, PostReComm} from '../../../../store/post/postComment';
import moment from "moment";
import api from "../../../../Api";
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import socket from "../../../../utils/socketNotification";
import { useHistory } from "react-router-dom";

const PostComment = (props) => {
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const dispatch = useDispatch();
    const [commme, setCommme] = useState('');
    const [upcommme, setUpCommme] = useState('');
    const [recommme, setRecommme] = useState('');
    const [commmeUPdate, setCommmeUPdate] = useState({});
    const [uPdate, setUPdate] = useState(true);
    const [value, setValue] = useState('');
    const [placeholder, setPlaceholder] = useState('write a comment..');
    const [commmeDate, setCommmeDate] = useState([]);
    const [loading, setLoading] = useState(false);
    const [clas, setClas] = React.useState([false]);
    const history = useHistory();
    const componentMounted = useRef(true);

    useEffect(() => {
        if (componentMounted.current) {
            getComments(props.props.ID);
        }
        return () => {
            componentMounted.current = false;
        }
    }, []);

    const getComments = (id) => {
        api.get(`/post/comment/insert/${id}/${currentUser.id}`).then(data => {
           if(data.data.Status != false){
            setCommmeDate(data.data);
           }
        }).catch(err=>{
            console.log(err)
        });
    }

    const CommentDataGET = (e) =>{
        setCommme(e.target.value)
    }

  
    const submitcomment = () =>{
        if (!recommme) {
            let body = {
                user_id: currentUser.id,
                post_id: props.props.ID,
                comment: commme,
                comment_date: new Date
            }
            dispatch(PostComm(body));
            setTimeout(() => {
                props.parentCallback("data");
                let body = {
                    type: 'post',
                    media_id: props.props.ID,
                    user_id: currentUser.id,
                    data: 'comment on your post.',
                    to_user_id:props.props.user_id,
                    notification_type:'post_comment',
                    date_time:new Date
                }
                socket.emit('sendNotification', body)
                getComments(props.props.ID);
                setCommme('');
                setValue('');
                setPlaceholder('write a comment..')
            }, 1000);
        } else {
            let body = {
                post_id: props.props.ID,
                user_id: currentUser.id,
                comment_id: recommme,
                comment: commme,
                comment_date: new Date
            }
            dispatch(PostReComm(body));
            setTimeout(() => {
                props.parentCallback("data");
                getComments(props.props.ID);
                let body = {
                    type: 'post',
                    notification_type:'comment_reply',
                    media_id: props.props.ID,
                    user_id: currentUser.id,
                    data: 'reply on your comment.',
                    to_user_id:props.props.user_id,
                    date_time:new Date
                }
                socket.emit('sendNotification', body)
                setCommme('');
                setRecommme('');
                setValue('');
                setPlaceholder('write a comment..')
            }, 1000);
        }

 
    }

    const replyComments = (id, firstname) =>{
        setRecommme(id);
        setPlaceholder(firstname)
    }

    const commentLikes = (id, user_id) =>{
        let data = {
            "user_id" : currentUser.id,
            "comment_id" :id,
        }
        api.post(`/comment/like`, data).then(data => {
            getComments(props.props.ID);
            if(data.data.Message == 'Comment like successfully.'){
                let body = {
                    type: 'post',
                    media_id: props.props.ID,
                    user_id: currentUser.id,
                    notification_type:'comment_like',
                    data: 'likes your comments.',
                    to_user_id:user_id,
                    date_time:new Date
                }
                socket.emit('sendNotification', body)
                }
        })
    }

    const CommentDelete = (ID) => {
        console.log(ID)
        api.delete(`/comment-delete/${ID}`).then(res => {
            getComments(props.props.ID);
            props.parentCallback("data");

        }).catch(err => {
            console.log('err', err)
        })

    }
    const CommentEdit = (ID) => {
        api.get(`/get-single-comment/${ID}`).then(res => {
            setUPdate(false)
            res.data.forEach(element => {
                setCommmeUPdate(element) 
                setUpCommme(element.comment)   
            });
        }).catch(err => {
            console.log('err', err)
        })

    }

    const UpCommentDataGET = (e) =>{
        setUpCommme(e.target.value)
    }


    const commentsUpdates = () =>{
        let body = {
            id: commmeUPdate.ID,
            comment: upcommme,
            comment_date: new Date
        }
        api.post(`/update-comment`, body).then(res => {
            console.log(res)
            setTimeout(() => {
                props.parentCallback("data");
                getComments(props.props.ID);
                setUPdate(true)
                setPlaceholder('write a comment..')
            }, 1000);
        })
    }


    const contantToggle = (id) => {
        let ClassToggle = {}
        if (!clas[id]) {
            ClassToggle[id] = true
            setClas(ClassToggle);
        } else {
            ClassToggle[id] = false
            setClas(ClassToggle);
        }
    }

    const gotoProfile = (user_id) =>{
        if(user_id !== currentUser.id){
            history.push(`/subscriptions/${user_id}`)
           }else{
            history.push('/User/profile');
           }
    }

    return (
        <div>
            <div className="comment-section">
                <div className="comments d-block">
                    
                    {commmeDate.map((data, i) =>
                    <div className="main-comment" key={i}>
                        <div className="media">
                        <a className="user-img popover-cls" >
                                <img onClick={()=>gotoProfile(data.user_id)} src={`${data.s3profileurl}`}
                                 onError={(event) => event.target.removeAttribute('src')}
                                 onError={(event) => event.target.src = '../../assets/images/avtar-image.jpg'}
                                  style={{width:'40px', height:'40px'}} className="commentUserImg blur-up lazyload bg-img" alt="user" />
                            </a>
                            <div className="media-body">
                                <h5 onClick={()=>gotoProfile(data.user_id)}>{data?.firstname} {data?.lastname}</h5>
                                {/* {clas[data.ID] ? <p style={{ fontSize: '14px' }}>{data?.comment} </p> 
                                : <p className='blogDes' style={{ fontSize: '14px' }}>{data?.comment} </p>}
                                {data?.comment.match(/is/g) || [].length == 15 ? <p onClick={() => contantToggle(data.ID)} style={{ fontSize: '10px', textAlign: 'right', marginTop: '0px', cursor: 'grab', color: '#0389c9' }}>{clas[data.ID] ? <span>Less...</span> : <span>More...</span>}</p> : null} */}
                                <p>{data?.comment}  
                                {data.user_id == currentUser.id ?<span style={{fontSize:'12px', float:'right'}}> <a onClick={() => CommentEdit(data.ID)}>Edit</a> &nbsp; &nbsp; <a onClick={() => CommentDelete(data.ID)}>Delete</a>
                               
                            </span>:null}</p>
                                <ul className="comment-option">
                                <li><a onClick={()=>commentLikes(data.ID, data.user_id)}>{data.commentlikeCount.map((d, i)=><span key={i}>{d.count != 0?<b>{d?.count}</b>:null}</span>)} {data?.isLike == '0'?<span style={{color:'gray'}}>like</span>:<span >likes</span>} </a></li>
                                    <li><a onClick={()=>replyComments(data.ID, data?.firstname)}>reply</a></li>
                                   
                                </ul>
                            </div>
                            <div className="comment-time mb-4">
                            <h6>{moment(data?.comment_date).calendar()}</h6>
                            {/* {data.user_id == currentUser.id ?<span style={{fontSize:'12px', float:'right'}}> <a onClick={() => CommentEdit(data.ID)}>Edit</a> &nbsp; &nbsp; <a onClick={() => CommentDelete(data.ID)}>Delete</a></span>:null} */}
                            </div>
                        </div>
                        {data.replyComment.map((redata,i)  =><div className="sub-comment">
                            <div className="media" key={i}>
                            <a className="user-img popover-cls" data-bs-toggle="popover" data-placement="right" data-name="sufiya elija" data-img="../assets/images/user-sm/3.jpg">
                                    <img src={`${redata.s3profileurl}`} 
                                     onClick={()=>gotoProfile(redata.comment_parent_id)}
                                     onError={(event) => event.target.removeAttribute('src')}
                                     onError={(event) => event.target.src = '../../assets/images/avtar-image.jpg'}
                                     style={{width:'40px', height:'40px'}} className="commentUserImg  blur-up lazyload bg-img" alt="user" />
                                </a>
                                <div className="media-body">
                                    <a>
                                        <h5  onClick={()=>gotoProfile(redata.comment_parent_id)}>{redata?.firstname} {redata?.lastname}</h5>
                                    </a>
                                    <p>{redata?.comment} 
                                    {redata.comment_parent_id == currentUser.id ?<span style={{fontSize:'12px', float:'right'}}> <a onClick={() => CommentEdit(redata.ID)}>Edit</a> &nbsp; &nbsp; <a onClick={() => CommentDelete(redata.ID)}>Delete</a> </span>:null}</p>
                                    <ul className="comment-option">
                                   <li><a onClick={()=>commentLikes(redata.ID, redata.user_id)}>{redata.commentlikeCount.map((d, i)=><span key={i}>{d.count != 0?<b>{d?.count}</b>:null}</span>)} {redata?.isLike == '0'?<span style={{color:'gray'}}>like</span>:<span >likes</span>} </a></li>
                                       <li><a onClick={()=>replyComments(data.ID, redata?.firstname)}>reply</a></li>
                                   </ul>
                                </div>
                                <div className="comment-time">
                                    <h6>{moment(redata?.comment_date).calendar()}</h6>
                                </div>
                            </div>
                        </div>)}
                        </div>)}
                        <div className="row">
                        <div className="col-md-12" style={{ textAlign: 'center' }}>
                            {commmeDate.length == 0 ? <div className="noComments">
                                <ModeCommentIcon style={{color: 'rgba(100, 117, 137, 0.7)', fontSize:'30px'}} />
                                <p>No comments yet</p>
                                <p >Be the first to comment.</p>
                            </div> : null}
                        </div>
                        <div className="col-md-12" style={{ textAlign: 'center' }}>
                            {loading ? <span className="spinner-border spinner-border-sm text-center" ></span> : null}
                        </div>
                    </div>
                </div>
                {uPdate?<div className="reply">
                    <div className="search-input input-style input-lg icon-right">
                        <input type="text" className="form-control emojiPicker" value={commme} onChange={(e)=> CommentDataGET(e)} placeholder={placeholder} />
                        <div style={{position: 'absolute', top:'5px', right:'4px'}} className='pt-1'>
                            <SendOutlinedIcon  onClick={submitcomment} style={{color:'#3e89c9', fontSize:'16px'}} />
                        </div>
                    </div>
                </div>:
                <div className="reply">
                    <div className="search-input input-style input-lg icon-right">
                        <input type="text" className="form-control emojiPicker" value={upcommme} onChange={(e)=> UpCommentDataGET(e)} />
                        <div style={{position: 'absolute', top:'5px', right:'4px'}} className='pt-1'>
                            <SendOutlinedIcon onClick={commentsUpdates} style={{color:'#3e89c9', fontSize:'16px'}} />
                        </div>
                    </div>
                </div>}
            </div>

        </div>
    );

}

export default PostComment