import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import { useDispatch, useSelector } from 'react-redux';
import { UserSession } from '../../../store/Accounts/loginsession';

function LoginSession() {
    const dispatch = useDispatch();
    const currentUser = JSON.parse(sessionStorage.getItem('user' || null));
    const [loading, setLoading] = useState(false);
    const { loginsession } = useSelector(res => res)
    const history = useHistory();
  
    useEffect(() => {
        dispatch(UserSession(currentUser.id)) 
      },[]);

    const logOut = () =>{
        sessionStorage.removeItem('user')
        history.push('/')
    }
 
    return (
        <>
            <div className='padd'>
                <Box sx={{ minWidth: 275 }} className='mt-2 '>
                    <Card variant="outlined" className="padds">
                        <div>
                            <h2>LOGIN SESSIONS</h2>
                        </div><hr />
                        <div>
                       {loginsession.session.map(data=> <div className="card-titles">
                            <h3 style={{ alignItems: 'left' }}>{data?.browser}, {data?.os}
                            <span style={{float:'right', color:'#00aff0', fontSize:'14px'}}> Active now</span>
                            </h3>
                            <p>{data?.ip_address} • {data?.location}</p>
                        </div>)}
                        </div>
                        <div className="row pt-2 pb-3">
                                    <div className="col-md-3" />
                                    <div className="col-md-6">
                                       
                                    </div>
                                    <div className="col-md-3" >
                                    <div style={{ textAlign: 'right' }}>
                                                <Button variant="contained" className='br' onClick={logOut}>
                                                    {loading ? <span><span className="spinner-border spinner-border-sm"></span> Please wait...</span> : 'Close session'}</Button>

                                        </div>
                                    </div>
                                </div>

                    </Card>
                </Box>
            </div>
        </>
    );
}

export default LoginSession;